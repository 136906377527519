import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";

import { useDimension } from "@hooks/useDimension";
import globalState from "@utils/globalState";
import { showToast } from "@utils/toast";

import { TextFont } from "../TextFont";

import { IButton, TKeysButtons, variantsButton } from "./config";
import "./animated.css";

const sizeIcon = { width: 24, height: 24 };

interface IProps {
  configuration: Partial<Record<TKeysButtons, IButton>>;
  pos: { left: number; top: number };
  isForMeeting?: boolean;
}

export const TaskActions: FC<IProps> = ({ configuration, pos, isForMeeting }) => {
  const { width, height } = useDimension().windowDimensions;
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  useEffect(() => {
    const element = document.getElementById("wrapperActions");
    setSize({ width: element.offsetWidth, height: element.offsetHeight });
  }, []);

  const onPress = (configButton: IButton) => {
    if (configButton.disabled) {
      showToast(configButton.toast);
    } else {
      configButton.onClick();
    }
  };

  const left = useMemo(() => {
    if (pos?.left + size.width > width) {
      return pos?.left - size.width;
    }
    return pos?.left;
  }, [pos?.left, width, size.width]);

  const top = useMemo(() => {
    if (pos?.top + size.height > height) {
      return pos?.top - size.height;
    }
    return pos?.top;
  }, [pos?.top, height, size.height]);

  const propsButton = useCallback(
    (keyEl: TKeysButtons) => ({
      ...sizeIcon,
      ...(configuration[keyEl].color ? { color: configuration[keyEl].color } : {}),
      ...(configuration[keyEl].message ? { message: configuration[keyEl].message } : {}),
    }),
    [configuration],
  );

  return createPortal(
    <Container id="wrapperActions" top={top} left={left} isVisible={isVisible} isForMeeting={isForMeeting}>
      {Object.keys(configuration)?.map((keyEl: TKeysButtons) => (
        <Wrapper>
          <Button
            key={keyEl}
            onClick={(e) => {
              e.stopPropagation();
              onPress(configuration[keyEl]);
            }}
            isForMeeting={isForMeeting}
          >
            {variantsButton(propsButton(keyEl))[keyEl]?.img}
            <TextFont
              size={isForMeeting ? 13 : 16}
              color={
                configuration[keyEl].disabled
                  ? globalState.colorSchema.grey
                  : configuration[keyEl].color || globalState.colorSchema.text.main
              }
            >
              <FormattedMessage id={variantsButton(propsButton(keyEl))[keyEl]?.messageId} />
            </TextFont>
          </Button>
        </Wrapper>
      ))}
    </Container>,
    document.body,
  );
};

const Container = styled.aside<{ top: number; left: number; isVisible; isForMeeting?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 263px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  height: auto;
  background-color: white;
  z-index: 999;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  scale: ${(props) => (props.isVisible ? 1 : 0)};
  transition: all 300ms ease-in;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

  ${({ isForMeeting }) =>
    isForMeeting &&
    `
  width: 200px;
  padding: 4px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  `}
`;

const Button = styled.button<{ isForMeeting?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px;
  gap: 8px;
  width: 100%;

  ${({ isForMeeting }) =>
    isForMeeting &&
    `
    border-radius: 5px;
    padding: 5px 7px;
    gap: 10px;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  :hover {
    background-color: ${globalState.colorSchema.disabled};
    border-radius: 10px;
  }
  :active {
    background-color: ${globalState.colorSchema.lighGrey};
  }
`;
