/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";

export function isInnerMeetParticipant(user: Record<string, any>): user is IMeetParticipants {
  return "permissions" in user || "user" in user || "workPosition" in user;
}

export function isStateUser(user: Record<string, any>): user is IStateUser {
  if (typeof user === "string") return false;

  return "avatarFileId" in user && "workPosition" in user;
}

export function isExternalUser(user: Record<string, any>): user is IExternalUser {
  const isNestedUserProp = Object.prototype.hasOwnProperty.call(user, "user");

  if ("isExternalUser" in user) {
    return user.isExternalUser;
  }

  if (isNestedUserProp && "isExternalUser" in user) {
    return user.user.isExternalUser;
  }

  return !("avatarFileId" in (isNestedUserProp ? user.user : user) || "workPosition" in user);
}
