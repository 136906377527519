import React, { FC, ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { ISwitches } from "../../../types";

interface IProps {
  children: ReactNode;
  value: boolean;
  onChange: (name: keyof ISwitches, val: boolean) => void;
  switchKey: keyof ISwitches;
  title: string;
}

export const SwitchBlock: FC<IProps> = ({ children, value, onChange, switchKey, title }) => (
  <ContentWrap>
    <LineToggle>
      <TextFont size={18} weight="700">
        <FormattedMessage id={title} />
      </TextFont>
      <Toggle checked={value} onChange={(e) => onChange(switchKey, e.target.checked)} icons={false} />
    </LineToggle>
    <RenderWithCondition condition={Boolean(value)}>
      <ChildrenWrap isActive={value}>{children}</ChildrenWrap>
    </RenderWithCondition>
  </ContentWrap>
);

const ContentWrap = styled.aside`
  margin-bottom: 30px;
`;
const LineToggle = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  margin-bottom: 10px;
`;
const ChildrenWrap = styled.div<{ isActive: boolean }>`
  ${boxCenterCss};
  justify-content: space-between;
  transition: opacity 300ms;
  opacity: 0;
  ${({ isActive }) => isActive && "opacity: 1;"};
`;
