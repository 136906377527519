import { Dispatch } from "react";

import { NavigateFunction } from "react-router-dom";

import { TaskSubtypes } from "@store/businessTask";
import { setActiveTabProfile, setMeetingId, setTaskID } from "@store/notification/slice";

const openDeepLink = (link: any, dispatch: Dispatch<any>, navigate: NavigateFunction) => {
  const [type, id] = link
    .slice(link.indexOf("//") + 2)
    .split("/")
    .slice(-2);

  if (type === "meeting") {
    dispatch(setMeetingId(id));
    return navigate("/");
  }

  if (type === "personal") {
    dispatch(setTaskID({ id, type: TaskSubtypes.Personal }));
    return navigate("/tasks");
  }

  if (type === "task") {
    dispatch(setTaskID({ id, type: TaskSubtypes.Business }));
    return navigate("/tasks");
  }

  if (type === "profile") {
    dispatch(setActiveTabProfile(id));
    return navigate("/profile");
  }
};

export default openDeepLink;
