import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import styled from "styled-components";

import { TNotificationDurations, TNotificationTypes } from "@interfaces/meet.interface";
import { selectUserForAssistant } from "@store/user";
import { updatePersonalSettings } from "@store/userSettings/api";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import TabButton from "@ui/TabButton";

import { NotificationDurations, NotificationTypes } from "../../../../../../interfaces/userSettings.interface";

interface IRemindMeetings {
  durations: TNotificationDurations[];
  types: TNotificationTypes[];
  currentId?: string;
}

const defaultSelection = {
  notificationDurations: [NotificationDurations.FIFTEEN_MINUTES],
  notificationTypes: [NotificationTypes.PUSH],
};

const disabledReminders = {
  notificationDurations: [],
  notificationTypes: [],
};

export const RemindMeetings: FC<IRemindMeetings> = ({ durations, types, currentId }) => {
  const [activeRemind, setActiveRemind] = useState(!!durations?.length);
  const [durationsSelected, setDurationsSelected] = useState<TNotificationDurations[]>(durations ?? []);
  const [notificationTypesSelected, setNotificationTypesSelected] = useState<TNotificationTypes[]>(types ?? []);

  const currentUser = useSelector(selectUserForAssistant);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeRemind && (!durations?.length || !types?.length)) {
      dispatch(updatePersonalSettings({ id: currentId, body: defaultSelection }));
      setDurationsSelected(defaultSelection.notificationDurations);
      setNotificationTypesSelected(defaultSelection.notificationTypes);
      return;
    }

    if (!activeRemind && (durations?.length || types?.length)) {
      dispatch(updatePersonalSettings({ id: currentId, body: disabledReminders }));
    }
  }, [activeRemind]);

  function onHandleRemindDurations(type: string, value: TNotificationDurations) {
    if (durationsSelected?.includes(value) && durationsSelected.length === 1) return;

    const updation = durationsSelected?.includes(value)
      ? durationsSelected.filter((item) => item !== value)
      : [...durationsSelected, value];

    setDurationsSelected(updation);
    dispatch(updatePersonalSettings({ id: currentId, body: { [type]: updation } }));
  }

  function onHandleRemindTypes(type: string, value: TNotificationTypes) {
    if (notificationTypesSelected?.includes(value) && notificationTypesSelected.length === 1) return;

    let updation = notificationTypesSelected?.includes(value)
      ? notificationTypesSelected.filter((item) => item !== value)
      : [...notificationTypesSelected, value];
    setNotificationTypesSelected(updation);

    if (updation.includes("PUSH")) {
      updation = [...updation, "WEB_SOCKET"] as TNotificationTypes[];
    }

    dispatch(updatePersonalSettings({ id: currentId, body: { [type]: updation } }));
  }

  return (
    <Wrapper>
      <LineCheckBox>
        <TextFont weight="700" size={16}>
          <FormattedMessage id="remindAboutMeetings" defaultMessage="Напоминать о встречах" />
        </TextFont>
        <Toggle
          checked={activeRemind}
          onChange={() => setActiveRemind(!activeRemind)}
          icons={false}
          colorOn={Colors.LIGHT.greenHover}
          colorOff={Colors.LIGHT.lighGrey}
          textColorOff={Colors.LIGHT.white}
          style={{ span: { background: Colors.LIGHT.white } }}
        />
      </LineCheckBox>
      <AccordBlock open={activeRemind}>
        <GroupButtons>
          <TextFont weight="700" size={16}>
            <FormattedMessage id="remindIn" defaultMessage="Напомнить за:" />
          </TextFont>
          <LineButtons>
            <TabButton
              tabText="15 минут"
              clickEvent={() => onHandleRemindDurations("notificationDurations", NotificationDurations.FIFTEEN_MINUTES)}
              active={durationsSelected?.includes(NotificationDurations.FIFTEEN_MINUTES)}
            />
            <TabButton
              tabText="1 час"
              clickEvent={() => onHandleRemindDurations("notificationDurations", NotificationDurations.ONE_HOUR)}
              active={durationsSelected?.includes(NotificationDurations.ONE_HOUR)}
            />
            <TabButton
              tabText="1 сутки"
              clickEvent={() => onHandleRemindDurations("notificationDurations", NotificationDurations.ONE_DAY)}
              active={durationsSelected?.includes(NotificationDurations.ONE_DAY)}
            />
          </LineButtons>
        </GroupButtons>
        <GroupButtons>
          <TextFont weight="700" size={16}>
            <FormattedMessage id="remindWay" defaultMessage="Способ уведомления" />
          </TextFont>
          <LineButtons>
            <TabButton
              tabText={currentUser?.email ?? "email не заполнен"}
              clickEvent={() => onHandleRemindTypes("notificationTypes", NotificationTypes.EMAIL)}
              active={notificationTypesSelected?.includes(NotificationTypes.EMAIL)}
            />
            <WrapButton>
              <TabButton
                tabText={"Пуш"}
                clickEvent={() => onHandleRemindTypes("notificationTypes", NotificationTypes.PUSH)}
                active={notificationTypesSelected?.includes(NotificationTypes.PUSH)}
              />
            </WrapButton>
          </LineButtons>
        </GroupButtons>
      </AccordBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
`;
const LineCheckBox = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  background-color: ${() => Colors.LIGHT.white};
  padding: 12px;
  border-radius: 10px;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;

const GroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
const LineButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 9px;
`;
const WrapButton = styled.div`
  display: flex;
  min-width: 90px;
  padding-bottom: 40px;
`;

const AccordBlock = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease;
  ${({ open }) =>
    open &&
    `
    max-height: 200px;
    transition: max-height 0.5s ease;
   }
  `}
`;
