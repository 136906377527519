import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import { resetStore } from "@constants/logic";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";

export type TEventsOfMonth = Record<string, IEventsOfDay[]>;
export type TPeriodToLoadCalendar = { startDate: string; endDate: string };

export enum CalendarViewModes {
  Week = "Week",
  Month = "Month",
}

interface IInitState {
  isHidePersonalEvents: boolean;
  isHideAllTasks: boolean;
  isShowUnansweredMeets: boolean;
  startWeekDay: Dayjs;
  viewMode: CalendarViewModes;
  isVisibleAppCalendar: boolean;
  periodToLoadCalendar: TPeriodToLoadCalendar | null;
}

const isHidePersonal = localStorage.getItem("showPersonal") === "false" ?? false;
const isHideTasks = localStorage.getItem("showTasks") === "false" ?? false;

const initialState: IInitState = {
  isHidePersonalEvents: isHidePersonal,
  isHideAllTasks: isHideTasks,
  isShowUnansweredMeets: false,
  startWeekDay: dayjs().startOf("week"),
  viewMode: CalendarViewModes.Month,
  isVisibleAppCalendar: false,
  periodToLoadCalendar: null,
};

const calendarSlice = createSlice({
  name: "screenDaySlice",
  initialState,
  reducers: {
    setIsHidePersonalTasks: (state, { payload }) => {
      state.isHidePersonalEvents = payload;
    },
    setIsHideTasks: (state, { payload }) => {
      state.isHideAllTasks = payload;
    },
    setIsShowUnansweredMeets: (state, { payload }) => {
      state.isShowUnansweredMeets = payload;
    },
    goToNextWeek: (state) => {
      state.startWeekDay = state.startWeekDay.add(1, "week").startOf("week");
    },
    goToPreviousWeek: (state) => {
      state.startWeekDay = state.startWeekDay.subtract(1, "week").startOf("week");
    },
    setStartWeekDay: (state, { payload }) => {
      state.startWeekDay = payload;
    },
    setViewMode: (state, { payload }) => {
      state.viewMode = payload;
      state.isShowUnansweredMeets = false;
    },
    setIsVisibleAppCalendar: (state, { payload }) => {
      state.isVisibleAppCalendar = payload;
    },
    setPeriodToLoadCalendar: (state, { payload }) => {
      state.periodToLoadCalendar = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetStore, () => initialState);
  },
});

export const {
  setIsHidePersonalTasks,
  setIsHideTasks,
  setStartWeekDay,
  goToNextWeek,
  goToPreviousWeek,
  setViewMode,
  setIsShowUnansweredMeets,
  setIsVisibleAppCalendar,
  setPeriodToLoadCalendar,
} = calendarSlice.actions;
export default calendarSlice.reducer;
