import React, { ChangeEvent } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/close-bold.svg";
import { ReactComponent as SvgSuccess } from "@assets/success_task.svg";
import { TTypePriorityTask, TTypeStatusTask } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";

import { objXml, colors, ExtraIcon, TIcon } from "./config";

export interface IIcon {
  type: TTypePriorityTask;
  status: TIcon;
  extraIcon?: TTypeStatusTask;
  press: (e: ChangeEvent<HTMLButtonElement>) => void;
}

export const Icon = ({ type, status, extraIcon, press }: IIcon) => {
  const name = useLocation().pathname;

  const bgIcon = extraIcon === "CLOSED" || extraIcon === "PAUSED" ? Colors.LIGHT.white : colors()[status].text;

  return (
    <ButtonItem onClick={press} backColor={colors(extraIcon === "COMPLETED")[status].bg} isPointer={name !== "/tasks"}>
      <IconWrap>
        {objXml[type](bgIcon)}
        {extraIcon && <ExtraIcon type={extraIcon} />}
      </IconWrap>
    </ButtonItem>
  );
};

const ButtonItem = styled.button<{ backColor: string; isPointer: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  ${({ backColor }) =>
    backColor &&
    `
     background-color: ${backColor};
  `}
  ${({ isPointer }) =>
    isPointer &&
    `
     cursor: pointer;;
  `}
`;
const IconWrap = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`;
