import styled, { css } from "styled-components";

import { Colors } from "@theme/colors";
import globalState from "@utils/globalState";

type TKeys = "billet" | "blockDate" | "wrapperDate" | "oneOfLines" | "twoOfLines" | "buttonMain";

export const commonStyles: Record<TKeys, any> = {
  billet: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    backgroundColor: globalState.colorSchema.white,
    borderRadius: 11,
    marginTop: 8,
    width: "100%",
  },
  wrapperDate: {
    display: "flex",
    "flex-direction": "row",
  },
  blockDate: {
    backgroundColor: globalState.colorSchema.background.main,
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 9,
    paddingRight: 5,
  },
  oneOfLines: {
    lineHeight: "24px",
    height: 24,
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    "white-space": "nowrap",
  },
  twoOfLines: {
    "line-height": "24px",
    maxHeight: 48,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    webkitLineClamp: "2",
    LineClamp: "2",
    "-line-clamp": "2",
    WebkitBoxOrient: "vertical",
    "-webkit-box-orient": "vertical",
  },
  buttonMain: {
    display: "flex",
    backgroundColor: Colors.LIGHT.background.green,
    padding: "16px 24px",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
};

export const RoundButton = styled.button<{ top?: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  right: 12px;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${Colors.LIGHT.disabled};
  ${({ top }) =>
    top &&
    `
  top: ${top}px;
  `}
`;
export const ButtonBillet = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: 100%;
`;
export const ButtonMain = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: ${Colors.LIGHT.background.green};
  border-radius: 10px;
`;
export const TextareaComment = styled.textarea`
  font-size: 18px;
  border-radius: 10px;
  color: ${Colors.LIGHT.text.main};
  border: 1px solid ${Colors.LIGHT.white};
  background-color: ${Colors.LIGHT.white};
  margin-bottom: 20px;
  padding: 16px 20px;
  width: 90%;
  min-height: 130px;
`;

export const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 3;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.disabled};
  padding: 11px 8px;
`;
export const InputSearch = styled.input`
  margin-left: 6px;
  width: 100%;
  &::placeholder {
    color: ${Colors.LIGHT.placeholder.accent};
  }
`;
export const ButtonDrop = styled.button`
  flex: 1;
  margin-left: 14px;
`;
export const Spacer = styled.div<{ px: number; horizontal?: boolean }>`
  margin-top: ${(props) => (!props.horizontal ? `${props.px}px` : undefined)};
  margin-right: ${(props) => (props.horizontal ? `${props.px}px` : undefined)};
`;
export const boxCenterCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
