import { TypeTab } from "../../types";

import { ReactComponent as About } from "./assets/about.svg";
import { ReactComponent as BugReport } from "./assets/bugreport.svg";
import { ReactComponent as Device } from "./assets/device.svg";
import { ReactComponent as Groups } from "./assets/groups.svg";
import { ReactComponent as SvgNotification } from "./assets/notification.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as Wish } from "./assets/wish.svg";

interface ITabs {
  title: TypeTab;
  icon: JSX.Element;
}

export const tabs: ITabs[] = [
  { title: "notifications", icon: <SvgNotification /> },
  { title: "groupsParticipants", icon: <Groups /> },
  { title: "devices", icon: <Device /> },
  { title: "bugreport", icon: <BugReport /> },
  { title: "about", icon: <About /> },
  { title: "wish", icon: <Wish /> },
  { title: "settings", icon: <Settings /> },
];

export const tabsAssistant: ITabs[] = [
  { title: "notifications", icon: <SvgNotification /> },
  { title: "groupsParticipants", icon: <Groups /> },
  { title: "settings", icon: <Settings /> },
];
