import React, { FC, useMemo, useEffect, useRef, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { RenderWithCondition, LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { IMeet } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser } from "@interfaces/user.interface";
import { InviteExternalParticipantBottomSheet } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { AddNonExistingParticipantButton } from "@screens/day/components/timetable/main/modal/components/participants/components/AddNonExistingParticipantButton";
import { isArraysOfObjectsDiffer } from "@screens/day/components/timetable/main/modal/components/utils";
import { UserInformation } from "@screens/profile/components";
import { IExternalUser } from "@services/meet.service";
import { useGetExternalParticipantsListQuery, useGetParticipationListQuery } from "@services/userApi";
import { userSelector } from "@store/user";
import { HeaderModal, TextFont, ModalUI } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { isEmail } from "@utils/isEmail";

import { Item } from "./Item";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps {
  meet: IMeet;
  onSave: (user: (InterfaceUser.IStateUser | IExternalUser)[], isExternalUser?: boolean) => void;
  close: () => void;
  initialSelectedUsers?: (IStateUser | IExternalUser)[];
  unavailableUsersIds?: string[];
  showAttendanceStatus?: boolean;
  disableInnerUsers?: boolean;
  disableExternalUsers?: boolean;
  disableSearch?: boolean;
  uncheckAll?: boolean;
}

export const UserList: FC<IProps> = ({
  meet,
  onSave,
  close,
  initialSelectedUsers = [],
  disableInnerUsers = false,
  disableExternalUsers = false,
  disableSearch = false,
  showAttendanceStatus,
  unavailableUsersIds = [],
  uncheckAll = false,
}) => {
  const listInnerRef = useRef();

  const [search, setSearch] = useState("");
  const [userToSearch, setUserToSearch] = useState("");
  const [current, setCurrent] = useState(0);
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const [isInviteExternalParticipantModalVisible, setIsInviteExternalParticipantModalVisible] = useState(true);
  const [isInviteExternalParticipantBtnVisible, setIsInviteExternalParticipantBtnVisible] = useState(false);
  const [participantsUsers, setParticipantsUsers] = useState({ content: [] as InterfaceUser.IStateUser[], total: 0 });
  const [searchedExternalUsers, setSearchedExternalUsers] = useState<IExternalUser[]>([]);
  const [getUsersParams, setGetUsersParams] = useState<IGetUserParams>({
    id: meet.id,
    params: { startTime: meet.startTime, endTime: meet.endTime, current: 0, next },
  });

  const { data: searchedExternalUsersResponse } = useGetExternalParticipantsListQuery(
    {
      searchQuery: userToSearch,
    },
    {
      skip: disableExternalUsers,
    },
  );
  const { data: participantsUsersResponse, isLoading } = useGetParticipationListQuery(
    {
      ...getUsersParams,
      params: { ...getUsersParams.params },
    },
    {
      skip: disableInnerUsers,
    },
  );

  const initialParticipants = useMemo(() => {
    const participants = [];

    if (initialSelectedUsers) {
      for (const user of initialSelectedUsers) {
        Object.hasOwn(user, "user") ? participants.push(user.user) : participants.push(user);
      }
    }

    if (!disableExternalUsers) {
      participants.push(...(meet.externalUsers ? meet.externalUsers : []));
    }

    return participants;
  }, [initialSelectedUsers, meet]);

  const [selectedUsers, setSelectedUsers] = useState<(IStateUser | IExternalUser)[]>(initialParticipants);
  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const { currentUser } = userSelector();
  const phSearch = useIntl().formatMessage({ id: "nameOrEmail" });

  const handleUserPress = (user: IStateUser | IExternalUser) => {
    const addedUser = selectedUsers.find((item) => item.id === user.id);

    if (addedUser) {
      setSelectedUsers(selectedUsers.filter((item) => item.id !== addedUser.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleSave = () => {
    onSave(selectedUsers);
    close();
  };

  const listData = useMemo(() => {
    const extractedParticipants = (meet && meet?.participants?.map((item) => item?.user ?? item)) ?? [];

    const selectedUsersIds = selectedUsers.map((item) => item.id);
    const filteredInnerUsers = disableInnerUsers
      ? extractedParticipants?.filter((item) => !selectedUsersIds?.includes(item?.id) && item?.id !== currentUser?.id)
      : participantsUsers?.content?.filter((item) => !selectedUsersIds?.includes(item?.id) && item?.id !== currentUser?.id);
    const filteredExternalUsers = searchedExternalUsers?.filter((item) => !selectedUsersIds.includes(item.id));
    const filteredSelectedUsers = selectedUsers?.filter((user) => user?.id !== currentUser?.id);

    const parsedSelected = [];

    for (const item of filteredSelectedUsers) {
      if ("user" in item && typeof item?.user === "object") {
        parsedSelected.push({ ...item?.user });
      } else {
        parsedSelected.push(item);
      }
    }

    return [...parsedSelected, ...filteredExternalUsers, ...filteredInnerUsers];
  }, [selectedUsers, searchedExternalUsers, participantsUsers?.content, meet.externalUsers, currentUser, disableInnerUsers, meet]);

  const loadMoreData = async () => {
    if (participantsUsers && current >= participantsUsers.total) return;

    const params = {
      id: meet.id,
      params: { query: search, current, next, startTime: meet.startTime, endTime: meet.endTime },
    };

    setGetUsersParams(params);
    setCurrent(current + next);
  };

  const closeUserInfo = () => {
    setSelectUser(undefined);
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setUserToSearch(search);
      },
      search ? 1000 : 0,
    );

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    isInviteExternalParticipantModalVisible && setIsInviteExternalParticipantModalVisible(false);

    setParticipantsUsers({ content: [], total: 0 });

    const query = search ? { query: search } : {};
    const data: IGetUserParams = {
      id: meet.id,
      params: { ...query, current: 0, next, active: true, startTime: meet?.startTime ?? "", endTime: meet?.endTime },
    };

    eventHelperUtils.debounce(
      async () => {
        !!search && setSearchedExternalUsers([]);

        setGetUsersParams(data);
        setCurrent(next);
      },
      search ? 1000 : 0,
    );
  }, [search]);

  useEffect(() => {
    if (!searchedExternalUsers.length && !participantsUsers?.content?.length && isEmail(search)) {
      setIsInviteExternalParticipantBtnVisible(true);
    } else {
      setIsInviteExternalParticipantBtnVisible(false);
    }
  }, [search, searchedExternalUsers, participantsUsers.content]);

  useEffect(() => {
    if (!searchedExternalUsers?.length && !participantsUsers?.content?.length && isEmail(search)) {
      setIsInviteExternalParticipantBtnVisible(true);
    } else {
      setIsInviteExternalParticipantBtnVisible(false);
    }
  }, [search, searchedExternalUsers, participantsUsers?.content]);

  useEffect(() => {
    const update = {
      content: [...participantsUsers.content, ...(participantsUsersResponse?.content ?? [])],
      total: participantsUsersResponse?.total ?? 0,
    };

    setParticipantsUsers(update);
  }, [participantsUsersResponse]);

  useEffect(() => {
    setSearchedExternalUsers([...searchedExternalUsers, ...(searchedExternalUsersResponse ?? [])]);
  }, [searchedExternalUsersResponse]);

  useEffect(() => {
    setIsSaveBtnVisible(isArraysOfObjectsDiffer(initialParticipants, selectedUsers));
  }, [selectedUsers]);

  return (
    <ModalUI scrollEnable={false} isVisible={true} onClose={close}>
      <HeaderModal
        title="participants"
        leftSide={{ onPressClose: close, isHideCancel: true }}
        rightSide={{ onPress: handleSave }}
        isEdit={isSaveBtnVisible}
      />
      <div style={styles.main}>
        <RenderWithCondition condition={!disableSearch}>
          <div style={styles.filterContainer}>
            <div style={styles.searchInput}>
              <SvgSearch />
              <input value={search} onChange={(e) => setSearch(e.target.value)} style={styles.input} placeholder={phSearch} />
            </div>
            <RenderWithCondition condition={!!search}>
              <button style={{ width: 150 }} onClick={() => setSearch("")}>
                <TextFont size={16} style={styles.textDrop}>
                  <FormattedMessage id={"drop"} />
                </TextFont>
              </button>
            </RenderWithCondition>
          </div>
        </RenderWithCondition>

        <RenderWithCondition condition={isInviteExternalParticipantBtnVisible}>
          <AddNonExistingParticipantButton
            email={search}
            setIsInviteExternalParticipantModalVisible={setIsInviteExternalParticipantModalVisible}
          />
        </RenderWithCondition>

        <RenderWithCondition condition={!isInviteExternalParticipantBtnVisible}>
          <LoaderRenderWithCondition condition={isLoading}>
            <div ref={listInnerRef} style={{ height: "90%", overflowY: "auto" }} onScroll={handleScroll}>
              {listData?.map((item) => (
                <Item
                  key={item?.id}
                  isAdded={
                    (!uncheckAll &&
                      !(meet?.participants?.some((i) => i.userId === item?.id) || meet?.externalUsers?.some((i) => i.id === item?.id))) ||
                    selectedUsers?.some((i) => i.id === item?.id)
                  }
                  isDisabled={unavailableUsersIds?.some((i) => i === item.id)}
                  showAttendanceStatus={showAttendanceStatus}
                  isBusy
                  item={item}
                  setSelectUser={setSelectUser}
                  onSave={onSave}
                  onPress={handleUserPress}
                />
              ))}
            </div>
          </LoaderRenderWithCondition>
        </RenderWithCondition>

        <ModalRenderWithCondition condition={Boolean(selectUser)}>
          <ModalUI isVisible={Boolean(selectUser)} onClose={closeUserInfo}>
            <UserInformation user={selectUser} onClose={closeUserInfo} />
          </ModalUI>
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isInviteExternalParticipantModalVisible}>
          <InviteExternalParticipantBottomSheet
            participantEmail={search}
            isVisible={isInviteExternalParticipantModalVisible}
            setIsVisible={setIsInviteExternalParticipantModalVisible}
            setSearch={setSearch}
            closeParticipant={close}
          />
        </ModalRenderWithCondition>
      </div>
    </ModalUI>
  );
};

const styles = {
  main: {
    padding: "0 12px",
    marginTop: 10,
    height: "90%",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  searchInput: {
    display: "flex",
    alignItems: "center",
    height: 38,
    borderRadius: 10,
    backgroundColor: "#ECF0EF",
    padding: "0 8px",
    width: "100%",
  },
  input: {
    marginLeft: 6,
    width: "100%",
  },
  textDrop: {
    fontSize: 16,
    color: "#297952",
    marginLeft: 15,
  },
  addParticipantBtn: {
    marginBottom: 16,
    cursor: "pointer",
  },
};
