import React from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import img from "@assets/notFound.png";
import { RenderWithCondition } from "@hoc";
import { ButtonMain, Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  handleResetFilter: () => void;
  titleTextId?: string;
  descriptionTextId?: string;
  disableButton?: boolean;
}

export const NotFound = ({ titleTextId, descriptionTextId, disableButton, handleResetFilter }: IProps) => (
  <ContentWrap>
    <ImageItem src={img} />
    <Spacer px={24} />
    <TextFont size={24} weight="800" lineHeight={32}>
      <FormattedMessage id={titleTextId ? titleTextId : "taskNotFound"} />
    </TextFont>
    <TextDescription size={16} lineHeight={24}>
      <FormattedMessage id={descriptionTextId ? descriptionTextId : "taskNotFoundNotice"} />
    </TextDescription>
    <RenderWithCondition condition={!disableButton}>
      <ButtonReset onClick={handleResetFilter}>
        <TextFont size={18} weight="700" color={Colors.LIGHT.text.grey} lineHeight={24}>
          <FormattedMessage id="filterReset" />
        </TextFont>
      </ButtonReset>
    </RenderWithCondition>
  </ContentWrap>
);

const ContentWrap = styled.section`
  ${boxCenterCss};
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
`;
const ImageItem = styled.img`
  margin: 0 auto;
`;
const TextDescription = styled(TextFont)`
  text-align: center;
  color: ${Colors.LIGHT.black};
  margin-top: 8px;
  width: 400px;
`;
const ButtonReset = styled(ButtonMain)`
  width: 286px;
  background-color: ${Colors.LIGHT.background.green200};
  margin-top: 24px;
`;
