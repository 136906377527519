import { FC } from "react";

import { ReactComponent as SvgCheckbox } from "@assets/checkbox.svg";
import globalState from "@utils/globalState";

interface ICheckboxProps {
  isChecked: boolean;
  onChange: (...arg: unknown[]) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const Checkbox: FC<ICheckboxProps> = ({ isChecked, style, disabled, onChange, ...props }) => {
  const innerStyles = styles();

  return (
    <div onClick={onChange} style={{ ...style, ...innerStyles.container }} {...props}>
      <input
        type="checkbox"
        defaultChecked={isChecked}
        style={{ ...innerStyles.checkbox, ...(isChecked && { display: "none" }) }}
        disabled={disabled}
      />
      {isChecked && <SvgCheckbox />}
    </div>
  );
};

const styles = () => {
  const { colorSchema } = globalState;

  return {
    container: {
      height: "18px",
      width: "18px",
      border: `1px solid ${colorSchema.green}`,
      borderRadius: "2px",
      display: "flex",
      alignItems: "flex-start",
    },
    checkbox: {
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
      "-o-appearance": "none",
      position: "relative",
      display: "block",
    },
    checked: {
      "background-color": colorSchema.green,
    },
    icon: {
      display: "flex",
      marginLeft: "-3px",
      height: "18px",
      width: "18px",
      color: "white",
      fontSize: "16px",
      zIndex: 10,
      cursor: "default",
      "-moz-user-select": "none",
      "-khtml-user-select": "none",
      "-webkit-user-select": "none",
    },
  };
};
