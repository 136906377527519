import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgDone } from "@assets/done_thin.svg";
import { getList, selectBusinessGoals } from "@store/businessGoals";
import { selectYearMonth } from "@store/date";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI, HeaderModal } from "@ui";

interface IModalGoal {
  isVisible: boolean;
  close: () => void;
  goalId: string;
  setGoal: (name: string, value: any) => void;
}

export const ModalGoal = ({ isVisible, close, goalId, setGoal }: IModalGoal) => {
  const dispatch = useDispatch();

  const goals = useSelector(selectBusinessGoals);
  const yearMonth = useSelector(selectYearMonth);

  useEffect(() => {
    dispatch(getList(yearMonth));
  }, []);

  const handlePress = (value: string | null = null) => {
    setGoal("businessGoalId", value);
    close();
  };

  return (
    <ModalUI isVisible={isVisible} onClose={close} scrollEnable={false}>
      <HeaderModal title="goal" leftSide={{ onPressClose: close }} />
      <ContentWrap>
        {goals.length ? (
          <Content>
            {goals.map((goal) => (
              <ButtonItem key={goal.id} onClick={() => handlePress(goal.id)}>
                <TextFont color={goalId === goal.id ? Colors.LIGHT.text.accent : Colors.LIGHT.text.main} size={18}>
                  {goal.description}
                </TextFont>
                {goalId === goal.id ? <SvgDone /> : ""}
              </ButtonItem>
            ))}
            <ButtonEmpty onClick={() => handlePress()}>
              <TextFont size={18}>
                <FormattedMessage id="emptyField" />
              </TextFont>
            </ButtonEmpty>
          </Content>
        ) : (
          <TextFont size={18} align="center">
            <FormattedMessage id="businessGoalAdd" />
          </TextFont>
        )}
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  padding: 20px 12px 0 12px;
  overflow-y: auto;
  height: 92%;
`;
const Content = styled.div`
  padding: 6px 18px 20px 18px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 13px;
`;
const ButtonItem = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 14px 0 11px 0;
  border-bottom: 0.5px solid ${Colors.LIGHT.lighGrey};
  width: 100%;
`;
const ButtonEmpty = styled.button`
  padding-top: 14px;
`;
