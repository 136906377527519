import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as SvgCheck } from "@assets/check2.svg";
import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@components/hoc";
import { NotificationUI } from "@components/ui/notification/NotificationUI";
import { showToast } from "@utils/toast";

import { ICategory, ICategoryColor } from "../types";

import { COLORS } from "./config";

interface IProps {
  takenColors?: ICategory[];
  onClose: () => void;
  onSave: (newCategory: ICategory) => void;
}

export const AddCategoryModal: FC<IProps> = ({ takenColors = [], onClose, onSave }) => {
  const [description, setDescription] = useState("");
  const [selectedColor, setSelectedColor] = useState<ICategoryColor | null>(null);

  const handleSave = () => {
    if (!description) return showToast("setCategoryName");
    if (!selectedColor) return showToast("setCategoryColor");

    const id = uuidv4();
    return onSave({
      id,
      description: description,
      name: selectedColor.name,
      color: selectedColor.color,
    });
  };

  const renderColors = (colors: ICategoryColor[]) => (
    <StColorsWrapperDiv>
      {colors.map((color) => {
        const isTaken = takenColors.some((c) => c.color === color.color);
        const isSelected = color.color === selectedColor?.color;
        return (
          <StColorItemDiv
            key={color.color}
            onClick={() => {
              if (isTaken) return showToast("alreadyExistsCategory");
              setSelectedColor(color);
            }}
            style={{
              backgroundColor: color.color,
              border: isSelected ? "5px solid #297952" : "5px solid #f5f9f8",
            }}
          >
            <RenderWithCondition condition={isTaken && !isSelected}>
              <SvgClose width={13} fill={"#FFF"} />
            </RenderWithCondition>
            <RenderWithCondition condition={isSelected}>
              <SvgCheck fill={"#FFF"} />
            </RenderWithCondition>
          </StColorItemDiv>
        );
      })}
    </StColorsWrapperDiv>
  );

  return (
    <NotificationUI
      isOpen
      isVisible={true}
      style={{
        display: "flex",
        "align-items": "center",
      }}
      onClose={onClose}
    >
      <StModalContainerDiv>
        <StHeaderDiv>
          <StHeaderTextH2>{"Добавить новую категорию"}</StHeaderTextH2>
          <StCloseBtn onClick={onClose}>
            <SvgClose />
          </StCloseBtn>
        </StHeaderDiv>
        <StInput value={description} onChange={(e) => setDescription(e.target.value)} placeholder={"Название категории"} maxLength={25} />
        {renderColors(COLORS)}
        <StButtonContainerDiv>
          <StButton onClick={onClose}>
            <FormattedMessage id={"cancelClose"} />
          </StButton>
          <StButton primary onClick={handleSave}>
            <FormattedMessage id={"ok"} />
          </StButton>
        </StButtonContainerDiv>
      </StModalContainerDiv>
    </NotificationUI>
  );
};

const StModalContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f9f8;
  border-radius: 14px;
  padding: 24px;
  width: 350px;
`;

const StHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StHeaderTextH2 = styled.h2`
  font-weight: 700;
  font-size: 16px;
`;

const StCloseBtn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ecf0ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`;

const StInput = styled.input`
  font-size: 18px;
  border-radius: 10px;
  background-color: #ecf0ef;
  border: 1px solid #e3e9e7;
  padding: 16px 20px;
  /* width: 100%; */
`;

const StColorsWrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 350px;
`;

const StColorItemDiv = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #f5f9f8;
  cursor: pointer;
`;

const StButtonContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const StButton = styled.button<{ primary?: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background-color: ${(props) => (props.primary ? "#297952" : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  border: none;
  cursor: pointer;
`;

export default AddCategoryModal;
