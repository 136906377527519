import styled from "styled-components";

import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

export const BilletStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: 100%;
`;

export const ContentWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StartCenterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const InnerContainer = styled.div`
  position: relative;
  padding: 0 16px;
`;

export const TabText = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.LIGHT.disabled};
  }
`;
export const TopBlock = styled.div`
  margin: 20px 0;
`;
export const BottomBlock = styled.div`
  margin-top: 30px;
`;
export const BilletAttendance = styled(BilletStyle)`
  width: auto;
  cursor: pointer;
  margin-top: 12px;
`;
export const RoundButton = styled.button`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 12px;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${Colors.LIGHT.disabled};
`;
export const StatusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 54px;
  cursor: pointer;
`;

export const TextBottom = styled(TextFont)`
  margin-top: -10px;
  margin-bottom: 10px;
`;
export const TextComments = styled(TextFont)`
  margin-top: 12px;
`;

export const TextHeader = styled(TextFont)<{ marginBottom?: number }>`
  margin-top: 10px;
  margin-bottom: ${(props) => props.marginBottom ?? 10}px;
  align-items: center;
  width: 100%;
`;
