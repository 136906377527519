import React, { FC, memo, useCallback, useRef, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { IMeet } from "@interfaces/meet.interface";
import emitter from "@services/emitter";
import { IAttachEventMeta } from "@services/meet.service";
import { useGetMeetingByIdQuery } from "@services/meetApi";
import { useFileAttachments } from "@shared/fileAttachments";
import { attachEvents, AttachEventsEventType, unattachEvent } from "@store/attachedMeetingTasks/api";
import { saveModal } from "@store/configure";
import { selectCurrentDate, selectScreenDay, updateId } from "@store/screenDay";
import { setCurrentEvent } from "@store/screenDay/slice";
import { selectTheme } from "@store/theme";
import { ItemContentLoader } from "@ui";
import { ModalUI } from "@ui/modal/ModalUI";
import { arePropsEqual } from "@utils/arePropsEqual";
import { showToast } from "@utils/toast";
import { toFormatDate } from "@utils/toFormatTime";

import { ViewNotSave, ViewSave } from "./components";
import { generateEventsMeta } from "./components/meetingTasks/util";

export type TKindChangeMeet = "serie" | "event" | undefined;

interface IModal {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  meetId: string;
  data?: Partial<IMeet>;
  preventAttachedTasksReset?: boolean;
  onAfterClose?: () => void;
  isModal?: boolean;
  isRenderDotes?: boolean;
  isRepeating?: boolean;
  meetDate?: string;
}

export const Modal: FC<IModal> = memo(function Modal({
  isVisible,
  setIsVisible,
  meetId,
  data,
  preventAttachedTasksReset,
  onAfterClose,
  isModal = true,
  isRenderDotes = true,
  isRepeating,
  meetDate,
}) {
  const dispatch = useDispatch();

  const [isSave, setIsSave] = useState(!!meetId);
  const [kindChangeMeet, setKindChangeMeet] = useState<TKindChangeMeet>(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [attachedTasks, setAttachedTasks] = useState<ITask[]>([]);

  const currentDate = useSelector(selectCurrentDate);
  const { currentEvent } = useSelector(selectScreenDay);
  const { currentEventContext } = useSelector(selectScreenDay);

  const theme = selectTheme("extra");
  const idsToAttach = useRef<string[]>([]);
  const selectedTaskIds = useRef<string[]>([]);
  const files = useMemo(() => (currentEvent ? currentEvent?.files ?? [] : []), [currentEvent]);

  const {
    allFiles,
    uploadedFiles,
    fileUploadStatus,
    resetFileIdsToRemove,
    deleteFile,
    handleFileDownload,
    resetUploadedFiles,
    openFilePicker,
    FileInputField,
    restoreSoftDeletedFiles,
    removeUploadedFilesFromServer,
    handleFileChange,
  } = useFileAttachments({ initialFiles: files });

  const { data: fetchedMeet, isFetching } = useGetMeetingByIdQuery(
    currentEventContext
      ? {
          id: currentEventContext.id,
          repeat: Boolean(currentEventContext.repeat),
          currentDate: toFormatDate(dayjs(currentEventContext?.startTime)),
        }
      : {
          id: meetId,
          repeat: Boolean(isRepeating),
          currentDate: toFormatDate(meetDate ? dayjs(meetDate) : currentDate),
        },
  );

  const resetTaskIdsToAttach = useCallback(() => {
    idsToAttach.current = [];
  }, []);

  const attachTasksToMeeting = useCallback(
    async (meet: IMeet) => {
      if (idsToAttach.current.length && meet.startTime !== null) {
        const eventsMeta: IAttachEventMeta[] = generateEventsMeta(idsToAttach.current, meet.startTime);

        const response = dispatch(attachEvents({ eventId: meet.id, eventsMeta, eventType: AttachEventsEventType.TasksToMeeting }));

        if (!response?.error?.message) {
          showToast("taskIsAttached");
        }
      }

      idsToAttach.current = [];
    },
    [idsToAttach.current],
  );

  const handleAttachTasks = useCallback(
    async (tasks: ITask[], isHotUpdate?: boolean) => {
      if (!tasks.length) return;

      const newlyAddedIds = tasks.map((item) => item.id);
      idsToAttach.current = [...idsToAttach.current, ...newlyAddedIds];

      setAttachedTasks([...attachedTasks, ...tasks]);

      selectedTaskIds.current = [...selectedTaskIds.current, ...newlyAddedIds];
    },
    [selectedTaskIds, attachedTasks, idsToAttach.current, currentEvent],
  );

  const onTaskDelete = useCallback(
    async (taskId: string, onlyRemoveFromList: boolean) => {
      const attachmentIds = [];

      for (const item of attachedTasks) {
        if (item.id !== taskId) attachmentIds.push(item.id);
      }

      idsToAttach.current = idsToAttach.current.filter((id) => id !== taskId);
      if (!onlyRemoveFromList && meetId) {
        dispatch(unattachEvent([{ meetingId: meetId, taskId }]));
      }
      selectedTaskIds.current = attachmentIds;

      const filteredAttach = attachedTasks.filter((task) => task.id !== taskId);
      setAttachedTasks(filteredAttach);
    },
    [attachedTasks, idsToAttach.current, selectedTaskIds.current, meetId],
  );

  const handleClose = useCallback(() => {
    setIsVisible(false);
    onAfterClose?.();
  }, []);

  const onBack = () => {
    isSave ? handleClose() : setIsCancel(true);
    onAfterClose?.();
  };

  const isException = useMemo(
    () => !!currentEvent?.parentEvent && !currentEvent?.repeat && kindChangeMeet === "serie",
    [currentEvent?.parentEvent, currentEvent?.repeat, kindChangeMeet],
  );

  const exceptionEntity = useMemo(() => ({ value: isException, id: currentEvent?.id ?? "" }), [isException, currentEvent]);

  const meet = useMemo(() => currentEvent ?? fetchedMeet, [currentEvent, fetchedMeet]);

  useEffect(() => {
    if (!meetId) return;

    dispatch(setCurrentEvent(fetchedMeet ?? null));

    if (fetchedMeet?.attachedTasks) {
      setAttachedTasks(fetchedMeet.attachedTasks);
      selectedTaskIds.current = fetchedMeet?.attachedTasks.map((task) => task.id);
    }
  }, [fetchedMeet]);

  useEffect(() => {
    if (!isModal) {
      dispatch(saveModal(true));
    }

    return () => {
      if (!isModal) {
        dispatch(saveModal(false));
      }
      dispatch(updateId({ id: "", modalOpen: false }));
      setIsVisible(false);
    };
  }, []);

  useEffect(() => {
    setIsSave(!!meetId);
  }, [meetId]);

  useEffect(() => {
    emitter.emit("disableMeetingsReset", true);

    return () => {
      emitter.emit("disableMeetingsReset", false);
    };
  }, []);

  const renderView =
    isSave && meet ? (
      <LoaderRenderWithCondition condition={isFetching}>
        <ViewSave
          handleClose={handleClose}
          setKindChangeMeet={setKindChangeMeet}
          setIsSave={setIsSave}
          meet={meet as IMeet}
          resetTaskIdsToAttach={resetTaskIdsToAttach}
          attachTasksToMeeting={attachTasksToMeeting}
          handleAttachTasks={(tasks: ITask[], isHotUpdate?: boolean) => handleAttachTasks(tasks, isHotUpdate)}
          attachedTasks={attachedTasks}
          onTaskDelete={onTaskDelete}
          selectedTaskIds={selectedTaskIds.current}
          isEditMode={Boolean(idsToAttach.current.length)}
          allFiles={allFiles}
          uploadedFiles={uploadedFiles}
          deleteFile={deleteFile}
          handleFileDownload={handleFileDownload}
          openFilePicker={openFilePicker}
          fileUploadStatus={fileUploadStatus}
          resetUploadedFiles={resetUploadedFiles}
          resetFileIdsToRemove={resetFileIdsToRemove}
          removeUploadedFilesFromServer={removeUploadedFilesFromServer}
          isModal={isModal}
          isRenderDotes={isRenderDotes}
          handleFileChange={handleFileChange}
        />
      </LoaderRenderWithCondition>
    ) : (
      <ViewNotSave
        isEdit={isEdit}
        isCancel={isCancel}
        setIsCancel={setIsCancel}
        setIsEdit={setIsEdit}
        kindChangeMeet={kindChangeMeet}
        isException={exceptionEntity}
        meet={isException ? (currentEvent?.parentEvent as IMeet) : (currentEvent as IMeet)}
        setIsSave={setIsSave}
        handleClose={handleClose}
        joinData={data}
        handleAttachTasks={handleAttachTasks}
        attachedTasks={attachedTasks}
        onTaskDelete={onTaskDelete}
        taskIdsToAttach={idsToAttach.current}
        preventAttachedTasksReset={preventAttachedTasksReset}
        allFiles={allFiles}
        uploadedFiles={uploadedFiles}
        deleteFile={deleteFile}
        handleFileDownload={handleFileDownload}
        openFilePicker={openFilePicker}
        resetFileIdsToRemove={resetFileIdsToRemove}
        resetUploadedFiles={resetUploadedFiles}
        removeUploadedFilesFromServer={removeUploadedFilesFromServer}
        restoreSoftDeletedFiles={restoreSoftDeletedFiles}
        handleFileChange={handleFileChange}
      />
    );

  const containerRef = React.useRef(null);

  return isModal ? (
    <ModalUI id="modalMeet" bg={theme.background.main} isVisible={isVisible} onClose={onBack} scrollEnable={false}>
      <ContentModal ref={containerRef}>
        <RenderWithCondition condition={isFetching}>
          <ItemContentLoader width={"100%"} height={"100%"} />
        </RenderWithCondition>
        <RenderWithCondition condition={!isFetching}>{renderView}</RenderWithCondition>
      </ContentModal>
      <FileInputField />
    </ModalUI>
  ) : (
    <>
      <Content>{renderView}</Content>
      <FileInputField />
    </>
  );
},
arePropsEqual);

const ContentModal = styled.div`
  height: 100%;
`;
const Content = styled.section`
  height: 100%;
  background-color: ${(props) => props.theme.main.background.main};
`;
