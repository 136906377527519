import React, { FC, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { IMeet, TTypeParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { UserInformation } from "@screens/profile/components";
import { IExternalUser } from "@services/meet.service";
import { userSelector } from "@store/user/selectors";
import { Colors } from "@theme/colors";
import { Billet, ModalUI, TextFont } from "@ui";

import { UserList } from "../userList";

import { UserListItem } from "./UserListItem";

interface IProps {
  meet: IMeet;
  isSave: boolean;
  hideHeader?: boolean;
  disableStandardAddButtons?: boolean;
  currentUserAttendanceStatus?: TTypeParticipants | null;
  showAttendanceStatus?: boolean;
  innerUsers: IStateUser[];
  externalUsers?: IExternalUser[];
  handleDeleteItem: (id: string, isExternalUser: boolean) => void;
  handleAddUser: (users: (IStateUser | IExternalUser)[]) => void;
  isVisibleUserPicker?: boolean;
  onCloseUserList?: () => void;
  unavailableUsersIds?: string[];
}

export const SelectedUsersList: FC<IProps> = ({
  handleDeleteItem,
  handleAddUser,
  meet,
  isSave,
  currentUserAttendanceStatus,
  hideHeader = false,
  disableStandardAddButtons = false,
  showAttendanceStatus = false,
  isVisibleUserPicker = false,
  innerUsers = [],
  externalUsers = [],
  unavailableUsersIds = [],
  onCloseUserList,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const { currentUser } = userSelector();

  const isOnlyOwner = useMemo(
    () => innerUsers?.length === 1 && innerUsers[0].status === "OWNER" && !externalUsers?.length,
    [innerUsers, externalUsers],
  );

  const handleCloseWrapper = () => {
    setIsVisible(false);
    onCloseUserList && onCloseUserList();
  };

  return (
    <div>
      <RenderWithCondition condition={!hideHeader}>
        <>
          <HeaderLine>
            <TextFont size={18} weight="700" type="bold">
              <FormattedMessage id="meetingMembers" />
            </TextFont>
            <RenderWithCondition condition={!isSave}>
              <button onClick={() => setIsVisible(true)}>
                <SvgPlus width={16} height={16} fill={Colors.LIGHT.background.grey} />
              </button>
            </RenderWithCondition>
          </HeaderLine>
        </>
      </RenderWithCondition>

      {(innerUsers?.length || externalUsers?.length) && !isOnlyOwner ? (
        <>
          <ListItems>
            {innerUsers?.map((user) => {
              const status = currentUser?.id === user?.userId && currentUserAttendanceStatus ? currentUserAttendanceStatus : user?.status;

              return (
                <UserListItem
                  key={user.id}
                  user={{ ...user, status }}
                  handleDeleteItem={handleDeleteItem}
                  setSelectUser={setSelectUser}
                  isSave={isSave}
                  assistant={meet.assistant}
                  showAttendanceStatus={showAttendanceStatus}
                />
              );
            })}

            {[...(externalUsers ?? [])]?.map((user) => (
              <UserListItem
                key={user.id}
                user={user}
                handleDeleteItem={handleDeleteItem}
                setSelectUser={setSelectUser}
                isSave={isSave}
                isExternal
                showAttendanceStatus={showAttendanceStatus}
              />
            ))}
          </ListItems>
        </>
      ) : (
        <>
          <RenderWithCondition condition={!disableStandardAddButtons}>
            <Billet
              textId="addParticipants"
              additionalText="+ "
              onPress={() => setIsVisible(true)}
              styleText={{ color: Colors.LIGHT.text.accent, fontWeight: "400" }}
            />
          </RenderWithCondition>
        </>
      )}

      <ModalRenderWithCondition condition={isVisible || isVisibleUserPicker}>
        <UserList
          meet={meet}
          onSave={handleAddUser}
          close={handleCloseWrapper}
          initialSelectedUsers={[...innerUsers, ...externalUsers]}
          unavailableUsersIds={unavailableUsersIds}
          showAttendanceStatus={showAttendanceStatus}
          disableExternalUsers
          disableSearch
          disableInnerUsers
          uncheckAll
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={Boolean(selectUser)}>
        <ModalUI isVisible={Boolean(selectUser)} onClose={() => setSelectUser(undefined)}>
          <UserInformation user={selectUser} onClose={() => setSelectUser(undefined)} />
        </ModalUI>
      </ModalRenderWithCondition>
    </div>
  );
};

const HeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
`;
const ListItems = styled.div`
  margin-top: 13px;
  gap: 8px;
`;
