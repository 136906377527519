import React, { FC } from "react";

import styled from "styled-components";

import { TTypeDayOfWeek } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { CommonFields, daysOfWeek } from "../config";

interface IProps extends CommonFields {
  days: TTypeDayOfWeek[];
}

export const DayOfWeek: FC<IProps> = ({ handleData, days }) => {
  const handleCheckBox = (key: keyof typeof daysOfWeek, value: boolean) => {
    const tempDays = value ? [...days, key] : days.filter((i) => i !== key);
    handleData("dayOfWeeks", tempDays.length ? tempDays : []);
  };

  return (
    <section>
      <TextDayOfWeek size={18}>Выберите день недели</TextDayOfWeek>
      <ContentWrap>
        {Object.keys(daysOfWeek).map((i: any) => (
          <Block key={i}>
            <InputDay
              type="checkbox"
              onChange={(e) => handleCheckBox(i, e.target.checked)}
              checked={days.includes(i)}
              className="input"
              color={Colors.LIGHT.green}
            />

            <TextFont size={16} align="center">
              {daysOfWeek[i]}
            </TextFont>
          </Block>
        ))}
      </ContentWrap>
    </section>
  );
};

const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.LIGHT.white};
  padding: 10px 5px 10px 16px;
  border-radius: 9px;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;
const InputDay = styled.input`
  margin-right: 10px;
  width: auto;
  height: auto;
`;
const TextDayOfWeek = styled(TextFont)`
  margin-bottom: 6px;
`;
