import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { TypeKey } from "@components/shared/tightMode/components/ListTasksTablet/constants";
import { CreateTask } from "@components/shared/tightMode/components/ListTasksTablet/CreateTask";
import { useDimension } from "@hooks/useDimension";
import { TMainSections } from "@interfaces/businessTask.interface";
import { selectBusinessTasks } from "@store/businessTask";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { intl } from "@utils/translate";

interface IProps {
  section: TMainSections;
  view?: TMainSections;
  setView: (arg?: TMainSections) => void;
}

export const SectionHeader = ({ section, view, setView }: IProps) => {
  const screen = useLocation().pathname;
  const { isTablet } = useDimension();

  const [title, setTitle] = useState("");
  const [isShowInput, setIsShowInput] = useState(false);
  const {
    filter: { groupByType },
  } = useSelector(selectBusinessTasks);

  useEffect(() => {
    if (groupByType === "PRIORITY" || screen === "/") return setTitle(intl.getMessage(section.toLowerCase()));

    setTitle(section);
  }, [section, screen, groupByType]);

  return (
    <Container>
      {isTablet ? (
        <div
          onMouseLeave={() => setView(undefined)}
          onMouseMove={(e) => {
            e.stopPropagation();
            !isShowInput && setView(section);
          }}
        >
          {(!view || view !== section) && (
            <WrapperAddTask onClick={() => setIsShowInput(true)}>
              <Title>{title}</Title>
              <Plus>
                <SvgPlus fill={Colors.LIGHT.white} width={12} height={12} />
              </Plus>
            </WrapperAddTask>
          )}
          {view === section && (
            <BtnWrapperFastTask
              onClick={() => {
                setIsShowInput(true);
                setView(undefined);
              }}
            >
              <TextFont size={14} color={Colors.LIGHT.text.grey}>
                <FormattedMessage id="quickTask" />
              </TextFont>
            </BtnWrapperFastTask>
          )}

          {isShowInput && (
            <InputWrap>
              <CreateTask priority={section as TypeKey} setIsCreating={(arg) => setIsShowInput(!!arg)} />
            </InputWrap>
          )}
        </div>
      ) : (
        <Title>{title}</Title>
      )}
    </Container>
  );
};

const Container = styled.aside`
  padding: 0px 12px 6px 12px;
`;

const Title = styled(TextFont)`
  font-size: 16px;
  font-weight: 500;
`;

const WrapperAddTask = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;

const Plus = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.LIGHT.asphalt};
  padding: 4px;
  border-radius: 4px;
`;
const InputWrap = styled.div`
  padding: 0 8px;
`;

const BtnWrapperFastTask = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.LIGHT.disabled};
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`;
