import React, { useEffect } from "react";
import { Dispatch, useMemo } from "react";

import { useDispatch } from "react-redux";

import { getUserInfo, userSelector } from "@store/user";
import { myTracker } from "@utils/myTracker";

import { Groups, Messages, Session, ErrorSender, About, WishSender, UserInformation, Settings } from "./components";
import { TypeTab } from "./types";

interface IProps {
  activeTab: TypeTab;
  setTab: Dispatch<TypeTab>;
}

export const useConfigTabs = (props: IProps) => {
  const dispatch = useDispatch();

  const { directorId, currentUser } = userSelector();

  useEffect(() => {
    if (!currentUser) dispatch(getUserInfo());
  }, [currentUser]);

  const onClose = () => {
    props.setTab("");
  };

  const tabs: Partial<Record<TypeTab, JSX.Element>> = useMemo(
    () =>
      directorId
        ? {
            notifications: <Messages onClose={onClose} />,
            groupsParticipants: <Groups onClose={onClose} />,
            settings: <Settings onClose={onClose} user={currentUser} />,
          }
        : {
            notifications: <Messages onClose={onClose} />,
            groupsParticipants: <Groups onClose={onClose} />,
            devices: <Session onClose={onClose} />,
            bugreport: (
              <ErrorSender
                onClose={() => {
                  onClose();
                  myTracker("ErrorSendClick");
                }}
              />
            ),
            about: <About onClose={onClose} />,
            wish: (
              <WishSender
                onClose={() => {
                  myTracker("WishSendClick");
                  onClose();
                }}
              />
            ),
            details: <UserInformation isClose={false} onClose={onClose} setTab={props.setTab} user={currentUser} />,
            settings: <Settings onClose={onClose} user={currentUser} />,
          },
    [currentUser, directorId],
  );

  return useMemo(
    () => ({
      tab: tabs[props.activeTab],
    }),
    [props.activeTab, tabs],
  );
};
