import React, { FC, useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgCheck } from "@assets/check.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgEdit } from "@assets/edit_underline.svg";
import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { ReactComponent as SvgSettings } from "@assets/settings.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@components/hoc";
import { ArrowSvg, Loader, ModalUI, TextFont } from "@components/ui";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeet, TTypeOfAction } from "@interfaces/meet.interface";
import {
  useDeleteMeetCategoryListMutation,
  useGetMeetCategoryListQuery,
  useUpdateMeetCategoryListMutation,
  useUpdateMeetCategoryMutation,
} from "@services/meetApi";
import { Colors } from "@theme/colors";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { showToast } from "@utils/toast";
import { toFormatDate } from "@utils/toFormatTime";

import { ICategory } from "../types";

import { AddCategoryModal } from "./AddCategoryModal";
import EditCategoryModal from "./EditCategoryModal";
import { sortCategories } from "./utils";

interface IProps {
  meet: IMeet | IEventsOfDay;
  setIsVisible: (arg: boolean) => void;
  onSelect?: (category: ICategory) => void;
}

export const CategoryModal: FC<IProps> = ({ meet, setIsVisible, onSelect }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [localCategories, setLocalCategories] = useState<ICategory[]>([]);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditCategoryModalVisible, setIsEditCategoryModalVisible] = useState(false);
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] = useState(false);
  const [isNotificationVisible, setNotificationVisible] = useState(false);

  const [categoryToDelete, setCategoryToDelete] = useState<ICategory | null>(null);
  const [categoryToEdit, setCategoryToEdit] = useState<ICategory | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(meet.userCategory?.id);

  const { data: categories } = useGetMeetCategoryListQuery();
  const [updateMeetCategoryList] = useUpdateMeetCategoryListMutation();
  const [deleteMeetCategoryList] = useDeleteMeetCategoryListMutation();
  const [updateMeetCategory] = useUpdateMeetCategoryMutation();

  useEffect(() => {
    setLocalCategories(sortCategories(categories ?? []));
  }, [categories]);

  const openDeleteCategoryModal = (category: ICategory) => {
    setCategoryToDelete(category);
    setIsDeleteModalVisible(true);
  };

  const openEditCategoryModal = (category: ICategory) => {
    setIsEditCategoryModalVisible(true);
    setCategoryToEdit(category);
  };

  const updateCategory = (category: ICategory, isAllSeries = false) => {
    updateMeetCategory({
      id: meet.id,
      ...(isAllSeries ? null : { date: toFormatDate(dayjs(meet.startTime)) }),
      categoryId: category?.id,
    })
      .unwrap()
      .then(() => {
        onSelect?.(category);
        setIsVisible(false);
      })
      .catch((err) => {
        showToast(err.data.message || "somethingWentWrong", "error");
      });
  };

  const handleNotifyType = (type: TTypeOfAction) => {
    setNotificationVisible(false);
    const category = localCategories.find((c) => c?.id === selectedCategoryId);

    updateCategory(category, type === "ALL_SERIES");
  };

  const handleSelect = (category: ICategory) => {
    if (isEdit) return;

    setSelectedCategoryId(category.id);

    if (meet.repeat) {
      setNotificationVisible(true);
      return;
    }

    updateCategory(category);
  };

  const deleteCategory = useCallback(() => {
    if (!categoryToDelete) return;
    setLocalCategories((prev) => prev.filter((i) => i.color !== categoryToDelete.color));
    setCategoryToDelete(null);
  }, [categoryToDelete]);

  const addCategory = (category: ICategory) => {
    setLocalCategories((prev) => sortCategories([category, ...prev]));
  };

  const editCategory = useCallback(
    (newValue: string) => {
      if (!categoryToEdit) return;

      const updatedCategory = { ...categoryToEdit, description: newValue };
      setLocalCategories((prev) => prev.map((c) => (c.id === categoryToEdit.id ? updatedCategory : c)));
      setCategoryToEdit(null);
    },
    [categoryToEdit],
  );

  const cancelEdit = () => {
    setLocalCategories(categories ?? []);
    setIsEdit(false);
  };

  const save = () => {
    const requests: Promise<unknown>[] = [];

    const deleted = _.differenceBy(categories, localCategories, "color");
    if (deleted.length) {
      requests.push(deleteMeetCategoryList({ ids: deleted.map((c) => c.id) }));
    }

    const updated = _.differenceBy(localCategories, categories ?? []);
    if (updated.length) {
      requests.push(updateMeetCategoryList(updated));
    }

    if (!requests.length) return;

    setIsSaving(true);

    Promise.all(requests)
      .then(() => {
        setIsEdit(false);
      })
      .catch(() => {
        showToast("somethingWentWrong", "error");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const rendreCategoriesItem = (category: ICategory, isLast: boolean) => {
    const isSelected = category.id === selectedCategoryId;
    return (
      <>
        <StCategoryItemBtn
          onClick={() => {
            handleSelect(category);
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
            <StCategoryItemColorDiv color={category.color} />
            <TextFont>{category.description} </TextFont>
          </div>

          <RenderWithCondition condition={isSelected && !isEdit}>
            <SvgCheck />
          </RenderWithCondition>
          <RenderWithCondition condition={isEdit}>
            <div>
              <button onClick={() => openEditCategoryModal(category)}>
                <SvgEdit />
              </button>

              <button onClick={() => openDeleteCategoryModal(category)}>
                <SvgDelete />
              </button>
            </div>
          </RenderWithCondition>
        </StCategoryItemBtn>
        {!isLast && <StSeparatorDiv />}
      </>
    );
  };

  return (
    <ModalUI isVisible={true} onClose={() => setIsVisible(false)}>
      <StContainerDiv>
        <StHeaderDiv>
          <div />
          <TextFont size={16} weight="700">
            <FormattedMessage id="categoryAdmin" />
          </TextFont>
          <StRoundContainerDiv onClick={() => setIsVisible(false)}>
            <SvgClose />
          </StRoundContainerDiv>
        </StHeaderDiv>

        <StCategoryListDiv>
          {localCategories?.map((category, index, arr) => rendreCategoriesItem(category, index === arr.length - 1))}
        </StCategoryListDiv>

        {isEdit ? (
          <>
            <StCategoryListDiv>
              <StCategoryItemBtn onClick={() => setIsAddCategoryModalVisible(true)}>
                <StCategoryItemTextContainerDiv>
                  <div style={{ flex: 1 }}>
                    <TextFont color={Colors.LIGHT.text.accent}>
                      <FormattedMessage id="newCategory" />
                    </TextFont>
                  </div>
                </StCategoryItemTextContainerDiv>

                <ArrowSvg type="right" fill={Colors.LIGHT.text.accent} />
              </StCategoryItemBtn>
            </StCategoryListDiv>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
              <StBtn disabled={isSaving} onClick={cancelEdit}>
                <FormattedMessage id={"cancelClose"} />
              </StBtn>
              <StBtn disabled={isSaving} primary onClick={save} color="#FFF">
                {isSaving ? <Loader /> : <FormattedMessage id={"ok"} />}
              </StBtn>
            </div>
          </>
        ) : (
          <StCategoryListDiv>
            <StCategoryItemBtn disabled={!selectedCategoryId} onClick={() => handleSelect({ id: "" } as ICategory)}>
              <StCategoryItemTextContainerDiv>
                <SvgClose width={20} height={13} fill={selectedCategoryId ? Colors.LIGHT.text.accent : "#B3BEB8"} />
                <TextFont color={selectedCategoryId ? Colors.LIGHT.text.accent : "#B3BEB8"}>
                  <FormattedMessage id="categoryWithout" />{" "}
                </TextFont>
              </StCategoryItemTextContainerDiv>
              <div />
            </StCategoryItemBtn>

            <StSeparatorDiv />

            <StCategoryItemBtn onClick={() => setIsEdit(true)}>
              <StCategoryItemTextContainerDiv>
                <SvgSettings width={20} fill={Colors.LIGHT.text.accent} />
                <TextFont color={Colors.LIGHT.text.accent}>
                  <FormattedMessage id="categoryAdmin" />
                </TextFont>
              </StCategoryItemTextContainerDiv>
              <div />
            </StCategoryItemBtn>
          </StCategoryListDiv>
        )}
      </StContainerDiv>

      <RenderWithCondition condition={isAddCategoryModalVisible}>
        <AddCategoryModal
          takenColors={localCategories}
          onClose={() => setIsAddCategoryModalVisible(false)}
          onSave={(category) => {
            addCategory(category);
            setIsAddCategoryModalVisible(false);
          }}
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isEditCategoryModalVisible}>
        <EditCategoryModal
          defaultValue={categoryToEdit?.description || ""}
          onClose={() => {
            setCategoryToEdit(null);
            setIsEditCategoryModalVisible(false);
          }}
          onSave={(newDescription) => {
            editCategory(newDescription);
            setIsEditCategoryModalVisible(false);
          }}
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isDeleteModalVisible}>
        <NotificationConfirm
          isOpen={true}
          phraseId="deleteCategory"
          phraseOkId="yes"
          onOk={() => {
            deleteCategory();
            setIsDeleteModalVisible(false);
          }}
          onCancel={() => {
            setCategoryToDelete(null);
            setIsDeleteModalVisible(false);
          }}
        />
      </RenderWithCondition>
      <ModalRenderWithCondition condition={isNotificationVisible}>
        <NotificationConfirm
          phraseId={"oneMeetOrAllCategory"}
          phraseOkId="intoAllMeetingInSeries"
          phraseCancelId="onlyIntoThisMeeting"
          onOk={() => handleNotifyType("ALL_SERIES")}
          onCancel={() => handleNotifyType("SINGLE_MEETING")}
          isOpen={isNotificationVisible}
          onClose={() => setNotificationVisible(false)}
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const StContainerDiv = styled.div`
  padding: 20px 14px;
`;

const StHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const StBtn = styled.button<{ primary?: boolean }>`
  flex: 1;
  justify-content: center;
  padding: 16px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  ${(props) => (props.primary ? "background-color: #297952; color: #FFF;" : "background-color: #FFF; ")}
`;

const StCategoryListDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e9e7;
  border-radius: 10px;
  background-color: #fff;
  max-height: 282px;
  overflow-y: auto;
  margin-bottom: 8px;
`;

const StCategoryItemBtn = styled.button`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  max-height: 48px;
  cursor: pointer;
`;
const StCategoryItemTextContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StCategoryItemColorDiv = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 5px;
  width: 16px;
  height: 16px;
`;

const StRoundContainerDiv = styled.div`
  width: 30px;
  height: 30px;
  background-color: #ecf0ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StSeparatorDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid #e3e9e7;
`;
