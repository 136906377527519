import React, { memo, FC, useRef, useCallback, useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { RenderWithCondition, LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { InterfaceUser } from "@interfaces/index";
import { TSubListInfo } from "@interfaces/user.interface";
import { ModalControlDelegate } from "@screens/day/components/ListTasks/components/modalControlDelegate";
import { UserInformation } from "@screens/profile/components";
import { IParamsDelegationList } from "@services/user.service";
import { useGetUsersAvailableToDelegationListQuery } from "@services/userApi";
import { getSublistParams } from "@services/utils";
import { delegate } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { ModalUI, TextFont, Loader } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { showToast } from "@utils/toast";

import { Item } from "./Item";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps {
  task?: ITask;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  isModal: boolean;
  close: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const DelegatedList: FC<IProps> = memo(({ task, onSave, isModal, close, isNeedCloseAfterSave = true }) => {
  const listInnerRef = useRef();

  const userIdToDelegate = useRef("");
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(0);
  const [search, setSearch] = useState("");
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const [isModalControlDelegateVisible, setIsModalControlDelegateVisible] = useState(false);
  const [availableUsers, setAvailableUsers] = useState({ content: [], total: 0 });
  const [skip, setSkip] = useState(true);
  const [params, setParams] = useState({
    id: task?.id,
    params: { current: 0, next, showFromDelegationChain: true, showFromDepartment: false, showOthers: false, active: true },
  });
  const [currentSublistType, setCurrentSublistType] = useState<TSubListInfo>("Delegation");
  const [isWriting, setIsWriting] = useState(false);

  const dateNow = useSelector(selectCurrentDate);
  const { directorId } = userSelector();

  const { data, isLoading, isFetching, isError } = useGetUsersAvailableToDelegationListQuery(
    {
      ...params,
      params: { ...params.params, userId: directorId },
    },
    {
      skip,
    },
  );

  const closeUserInfo = () => {
    setSelectUser(undefined);
  };

  const proceedDelegation = (userId: string) => {
    userIdToDelegate.current = userId;
    setIsModalControlDelegateVisible(true);
  };

  const delegateTask = useCallback(() => {
    if (task) {
      dispatch(delegate({ id: task?.id, userId: userIdToDelegate.current, date: dateNow, isModal }));
    }
  }, [userIdToDelegate, dateNow, task, isModal]);

  const loadMoreData = async () => {
    if (availableUsers && (currentSublistType === "Others" || search) && current >= availableUsers.total) return;

    let sublistUpdation = currentSublistType;

    if (current > availableUsers.total) {
      sublistUpdation = currentSublistType === "Delegation" ? "Department" : "Others";
    }

    const sublistParams = search ? {} : getSublistParams(sublistUpdation);

    setParams({
      id: task?.id ?? "",
      params: { query: search, current, next, ...sublistParams } as IParamsDelegationList,
    });
    setCurrent(current + next);
    setCurrentSublistType(sublistUpdation);
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (Math.ceil(scrollTop + clientHeight) - 10 >= scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  useEffect(() => {
    setCurrentSublistType("Delegation");
    setAvailableUsers({ content: [], total: 0 });

    if (search) {
      setIsWriting(true);
    }

    const query = search ? { query: search } : {};
    const additionalParams = search ? {} : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false };
    const data = { id: task?.id, params: { ...query, current: 0, next, active: true, ...additionalParams } };

    eventHelperUtils.debounce(
      async () => {
        setParams(data);
        setCurrent(next);
        setSkip(false);
        setIsWriting(false);
      },
      search ? 1000 : 0,
    );
  }, [search]);

  useEffect(() => {
    const update = {
      content: [...availableUsers.content, ...(data?.content ?? [])],
      total: data?.total ?? availableUsers.total,
    };

    setAvailableUsers(update);

    if (update.content.length < next && !search && currentSublistType === "Delegation") {
      loadMoreData();
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      showToast("dataDidNotLoaded", "error");
    }
  }, [isError]);

  const phSearch = useIntl().formatMessage({ id: "search" });

  return (
    <section style={styles.main}>
      <div style={styles.filterContainer}>
        <div style={styles.searchInput}>
          <SvgSearch />
          <input value={search} onChange={(e) => setSearch(e.target.value)} style={styles.input} placeholder={phSearch} maxLength={255} />
        </div>
        <RenderWithCondition condition={!!search}>
          <button onClick={() => setSearch("")}>
            <TextFont style={styles.textDrop}>
              <FormattedMessage id={"drop"} />
            </TextFont>
          </button>
        </RenderWithCondition>
      </div>

      <LoaderRenderWithCondition condition={isLoading}>
        <div ref={listInnerRef} style={{ height: "90%", overflowY: "auto" }} onScroll={handleScroll}>
          <RenderWithCondition condition={!availableUsers?.content.length && !isFetching && !isLoading && !isWriting}>
            <TextFont align="center">Не найдено</TextFont>
          </RenderWithCondition>
          {availableUsers?.content.map((item) => (
            <Item
              key={item.id}
              item={item}
              setSelectUser={setSelectUser}
              close={close}
              taskId={task?.id}
              onSave={onSave}
              isNeedCloseAfterSave={isNeedCloseAfterSave}
              proceedDelegation={(uid: string) => {
                proceedDelegation(uid);
              }}
            />
          ))}

          <RenderWithCondition condition={isFetching}>
            <Loader posY="95.5%" />
          </RenderWithCondition>
        </div>
      </LoaderRenderWithCondition>

      <ModalRenderWithCondition condition={isModalControlDelegateVisible && Boolean(task)}>
        <ModalControlDelegate
          closeParent={close}
          close={() => setIsModalControlDelegateVisible(false)}
          onSave={null}
          deadline={task?.plannedEndDate}
          delegatedTaskData={task}
          isDelegatedTaskSaved={true}
          delegateTask={delegateTask}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={Boolean(selectUser)}>
        <ModalUI isVisible={Boolean(selectUser)} onClose={closeUserInfo}>
          <UserInformation user={selectUser} onClose={closeUserInfo} />
        </ModalUI>
      </ModalRenderWithCondition>
    </section>
  );
});

const styles = {
  main: {
    paddingLeft: "12px",
    paddingRight: "12px",
    height: "90%",
  },
  filterContainer: {
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  searchInput: {
    flex: 1,
    display: "flex",
    "flex-direction": "row",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#ECF0EF",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "11px",
    paddingBottom: "11px",
  },
  input: {
    marginLeft: "6px",
    width: "100%",
  },
  textDrop: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#297952",
    marginLeft: "15px",
  },
};
