import React, { FC, useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import { IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";

import { TStringUnknownTupleArray, restoreAccessRightsPickerState } from "../../utils";

import { OptionsSection } from "./OptionsSection";

interface IProps {
  meet: IMeet;
  isEditMode: boolean;
  handleData: (name: string | TStringUnknownTupleArray, value: unknown) => void;
}

export const AccessOptions: FC<IProps> = ({ meet, isEditMode, handleData }) => {
  const initialState = restoreAccessRightsPickerState(meet?.participants ?? [], isEditMode);

  const [isCanEditAllEditable] = useState(true);
  const [isCanEditSomeEditable] = useState(true);
  const [isCanInviteAllEditable, setIsCanInviteAllEditable] = useState(true);
  const [isCanInviteSomeEditable, setIsCanInviteSomeEditable] = useState(true);

  const [isCanEditAllChecked, setIsCanEditAllChecked] = useState(initialState.isCanEditAllChecked);
  const [isCanEditSomeChecked, setIsCanEditSomeChecked] = useState(initialState.isCanEditSomeChecked);
  const [isCanInviteAllChecked, setIsCanInviteAllChecked] = useState(initialState.isCanInviteAllChecked);
  const [isCanInviteSomeChecked, setIsCanInviteSomeChecked] = useState(initialState.isCanInviteSomeChecked);

  const [unavailableUsersIdsToEdit, setUnavailableUsersIdsToEdit] = useState<string[]>(
    initialState.usersWithInvitePermission.map((item) => item.id),
  );
  const [unavailableUsersIdsToInvite, setUnavailableUsersIdsToInvite] = useState<string[]>(
    initialState.usersWithEditPermission.map((item) => item.id),
  );

  const [usersWithEditRights, setUsersWithEditRights] = useState<IStateUser[]>(initialState.usersWithEditPermission);
  const [usersWithInviteRights, setUsersWithInviteRights] = useState<IStateUser[]>(initialState.usersWithInvitePermission);

  // STATE 10-XX
  const handleCanEditAllCheckbox = useCallback(() => {
    if (isCanEditSomeChecked) setIsCanEditSomeChecked(false);

    setIsCanEditAllChecked(!isCanEditAllChecked);

    if (isCanInviteSomeChecked) {
      setIsCanInviteSomeChecked(false);
      setIsCanInviteAllChecked(true);
      setIsCanInviteAllEditable(false);
    }
  }, [isCanEditAllChecked, isCanEditSomeChecked, isCanInviteSomeChecked]);

  // STATE 01-XX
  const handleCanEditSomeCheckbox = useCallback(() => {
    if (isCanEditAllChecked) setIsCanEditAllChecked(false);
    setIsCanEditSomeChecked(!isCanEditSomeChecked);

    // STATE 01-10
    if (isCanInviteAllChecked) setIsCanInviteAllEditable(false);

    setIsCanInviteAllEditable(true);
    setIsCanInviteSomeEditable(true);
  }, [isCanEditAllChecked, isCanEditSomeChecked]);

  const handleCanInviteAllCheckbox = useCallback(() => {
    if (isCanInviteSomeChecked) setIsCanInviteSomeChecked(false);

    setIsCanInviteAllChecked(!isCanInviteAllChecked);
  }, [isCanInviteSomeChecked, isCanInviteAllChecked]);

  const handleCanInviteSomeCheckbox = useCallback(() => {
    if (isCanInviteAllChecked) setIsCanInviteAllChecked(false);

    setIsCanInviteSomeChecked(!isCanInviteSomeChecked);
  }, [isCanInviteAllChecked, isCanInviteSomeChecked]);

  const handleAddUsersWithEditRights = useCallback(
    (users: (IStateUser | IExternalUser)[]) => {
      setUsersWithEditRights(users as IStateUser[]);
      setUnavailableUsersIdsToInvite(users.map((item) => item.id));

      const usersIds = users.map((item) => item.id);

      setUsersWithInviteRights(usersWithInviteRights.filter((item) => !usersIds.includes(item.id)));
    },
    [usersWithInviteRights],
  );

  const handleRemoveUserWithEditRights = useCallback(
    (id: string) => {
      const updatedList = usersWithEditRights.filter((user) => user.id !== id);

      setUsersWithEditRights(updatedList);
      setUnavailableUsersIdsToInvite(updatedList.map((item) => item.id));
    },
    [usersWithEditRights],
  );

  const handleAddUsersWithInviteRights = (users: (IStateUser | IExternalUser)[]) => {
    setUsersWithInviteRights(users as IStateUser[]);
    setUnavailableUsersIdsToEdit(users.map((item) => item.id));
  };

  const handleRemoveUserWithInviteRights = (id: string) => {
    setUsersWithInviteRights(usersWithInviteRights.filter((user) => user.id !== id));
    setUnavailableUsersIdsToEdit(unavailableUsersIdsToEdit.filter((itemId) => itemId !== id));
  };

  const addSelectedOptionsToMeet = () => {
    const participants = meet?.participants ?? [];
    const result = [];

    // state XX-01
    if (isCanInviteSomeChecked && !isCanEditAllChecked && !isCanEditSomeChecked) {
      for (const user of usersWithInviteRights) {
        result.push({
          userId: user?.id,
          permissions: ["ADD_PARTICIPANTS"],
        });
      }
    }

    // state XX-10
    if (isCanInviteAllChecked && !isCanEditAllChecked && !isCanEditSomeChecked) {
      for (const user of participants) {
        result.push({
          userId: user?.user?.id,
          permissions: ["ADD_PARTICIPANTS"],
        });
      }
    }

    // state 01-XX
    if (isCanEditSomeChecked && !isCanInviteAllChecked && !isCanInviteSomeChecked) {
      for (const user of usersWithEditRights) {
        result.push({
          userId: user?.id,
          permissions: ["ADD_PARTICIPANTS", "EDIT_MEETING"],
        });
      }
    }

    // state 10-10
    if (isCanEditAllChecked && isCanInviteAllChecked) {
      for (const user of participants) {
        result.push({
          userId: user?.user?.id,
          permissions: ["ADD_PARTICIPANTS", "EDIT_MEETING"],
        });
      }
    }

    // state 01-10
    if (isCanEditSomeChecked && isCanInviteAllChecked) {
      const usersWithEditRightsIds = usersWithEditRights.map((item) => item.id);
      const filteredCanInviteAll = participants?.filter((item) => !usersWithEditRightsIds.includes(item.user?.id));

      for (const user of usersWithEditRights) {
        result.push({
          userId: user?.id,
          permissions: ["ADD_PARTICIPANTS", "EDIT_MEETING"],
        });
      }

      for (const user of filteredCanInviteAll) {
        result.push({
          userId: user?.user?.id,
          permissions: ["ADD_PARTICIPANTS"],
        });
      }
    }

    // state 01-01
    if (isCanEditSomeChecked && isCanInviteSomeChecked) {
      for (const user of usersWithEditRights) {
        result.push({
          userId: user?.id,
          permissions: ["ADD_PARTICIPANTS", "EDIT_MEETING"],
        });
      }

      for (const user of usersWithInviteRights) {
        result.push({
          userId: user?.id,
          permissions: ["ADD_PARTICIPANTS"],
        });
      }
    }

    if (meet?.participants?.length && meet.createdByCurrentUser) {
      handleData("participantPermissions", result);
    }
  };

  useEffect(() => {
    if (isCanEditAllChecked) {
      setIsCanInviteAllEditable(false);
      setIsCanInviteSomeEditable(false);
      setIsCanInviteAllChecked(true);
    } else {
      setIsCanInviteAllEditable(true);
      setIsCanInviteSomeEditable(true);
    }
  }, [isCanEditAllChecked]);

  useEffect(() => {
    addSelectedOptionsToMeet();
  }, [
    meet.participants,
    isCanEditAllChecked,
    isCanEditSomeChecked,
    isCanInviteAllChecked,
    isCanInviteSomeChecked,
    usersWithEditRights,
    usersWithInviteRights,
  ]);

  return (
    <ContentWrap>
      <OptionsSection
        meet={meet}
        titleTextId="editRules"
        isAllParticipantsEditable={isCanEditAllEditable}
        isSomeParticipantsEditable={isCanEditSomeEditable}
        isAllParticipantsChecked={isCanEditAllChecked}
        isSomeParticipantsChecked={isCanEditSomeChecked}
        handleAllParticipantsCheckbox={handleCanEditAllCheckbox}
        handleSomeParticipantsCheckbox={handleCanEditSomeCheckbox}
        handleAddUser={handleAddUsersWithEditRights}
        handleRemoveUser={handleRemoveUserWithEditRights}
        selectedUsers={usersWithEditRights}
        unavailableUsersIds={unavailableUsersIdsToEdit}
      />

      <OptionsSection
        meet={meet}
        titleTextId="inviteRules"
        isAllParticipantsEditable={isCanInviteAllEditable}
        isSomeParticipantsEditable={isCanInviteSomeEditable}
        isAllParticipantsChecked={isCanInviteAllChecked}
        isSomeParticipantsChecked={isCanInviteSomeChecked}
        handleAllParticipantsCheckbox={handleCanInviteAllCheckbox}
        handleSomeParticipantsCheckbox={handleCanInviteSomeCheckbox}
        handleAddUser={handleAddUsersWithInviteRights}
        handleRemoveUser={handleRemoveUserWithInviteRights}
        selectedUsers={usersWithInviteRights}
        unavailableUsersIds={unavailableUsersIdsToInvite}
      />
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
