import React, { FC, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { TOneSTaskResolutions } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { OneSResolutions } from "./resolutionOneSActionButtons/constants";

interface IProps {
  isFromOnes: boolean;
  oneSTaskStatus: TOneSTaskResolutions | null;
}

/*
ToDo: Будущий спринт. Убрать хардкод и сделать маппинг статусов как только
с бэка будет приходить поле со статусом.
*/

export const OneSTaskStatusHeader: FC<IProps> = ({ isFromOnes = false, oneSTaskStatus }) => {
  /* Для пропса oneSTaskStatus убрать смешение oneSTaskType и oneSTaskAvailableResolutions  */
  const textStatus = useMemo(() => {
    switch (oneSTaskStatus) {
      case "AGREEMENT":
        return "oneSApproveStatus";
      case "FAMILIARIZATION":
        return "oneSFamiliarizeStatus";
      case "EXECUTION":
      case "COMPLETE":
        return "oneSExecuteStatus";

      default:
        return "oneSExecuteStatus";
    }
  }, [oneSTaskStatus]);

  return (
    <>
      <RenderWithCondition condition={isFromOnes && oneSTaskStatus && textStatus}>
        <ContentWrap>
          <TextFont color={Colors.LIGHT.orange}>
            <FormattedMessage id={textStatus} />
          </TextFont>
        </ContentWrap>
      </RenderWithCondition>
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: ${Colors.LIGHT.lightYellow};
`;
