import React, { memo, useRef, useEffect, useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEmpty, isEqual, omit } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { IFile } from "@interfaces/files.interfaces";
import { IMeet } from "@interfaces/meet.interface";
import { Tag } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components";
import { MeetingTasks, Participants, Placement } from "@screens/day/components/timetable/main/modal/components";
import { api, StoreTagTypes } from "@services/api";
import {
  useCreateMeetingMutation,
  useGetMeetingAttendanceQuery,
  useUpdateParticipantsPermissionsMutation,
  useUpdateSingleOrSerieMeetingMutation,
} from "@services/meetApi";
import { FileAttachments } from "@shared";
import { IRemoveFilesFromServer } from "@shared/fileAttachments/useFileAttachments";
import { LineHorizontal } from "@shared/lineHorizontal/LineHorizontal";
import { resetAttachedTasks } from "@store/attachedMeetingTasks/slice";
import { selectExternalUsers } from "@store/externalUsers/selectors";
import { resetExternalUsers } from "@store/externalUsers/slice";
import { selectTheme } from "@store/theme";
import { selectUserPermissoins, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont, Billet, HeaderModal } from "@ui";
import { Notification } from "@ui/notification/Notification";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { EditTimerOptions } from "@ui/TimePicker";
import { diff, showToast, toFormatDate, toFormatISO } from "@utils";
import { arePropsEqual } from "@utils/arePropsEqual";
import { TTypeObject } from "@utils/diffObjects";
import { myTracker } from "@utils/myTracker";

import { NotificationName } from "../../../../../../../../../interfaces/userSettings.interface";
import { TKindChangeMeet } from "../../Modal";
import { AccessRightsPicker } from "../accessRights/AccessRightsPicker";
import { useRoleModel } from "../accessRights/useRoleModel";
import { ActivityParticipants } from "../activityParticipants/ActivityParticipants";
import { AllowPush } from "../allowPush/AllowPush";
import { notifyParticipantsTextIdOptions, confirmNotifyParticpantsTextIdOptions } from "../constants";

import { Date, Inputs, Repeat, Time } from "./components";
import { renderResult } from "./components/repeat/utils";
import { titleType } from "./constants";
import { CategoryButton } from "./menu/CategoryButton";
import { Menu } from "./menu/Menu";
import { useData } from "./useData";

interface IViewNotSave {
  meet: IMeet | undefined | null;
  handleClose: () => void;
  setIsSave: (arg: boolean) => void;
  attachedTasks: ITask[];
  kindChangeMeet: TKindChangeMeet;
  joinData?: Partial<IMeet>;
  isEdit: boolean;
  setIsEdit: (arg: boolean) => void;
  isCancel: boolean;
  setIsCancel: (arg: boolean) => void;
  isException: { value: boolean; id: string };
  taskIdsToAttach: string[];
  handleAttachTasks: (tasks: ITask[]) => void;
  onTaskDelete: (id: string, onlyRemoveFromList?: boolean) => void;
  preventAttachedTasksReset?: boolean;
  openFilePicker: () => void;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  resetUploadedFiles: () => void;
  resetFileIdsToRemove: () => void;
  removeUploadedFilesFromServer: (params?: IRemoveFilesFromServer) => void;
  restoreSoftDeletedFiles: () => void;
  handleFileChange: (files: FileList) => void;
}

export const ViewNotSave = memo(
  ({
    meet,
    handleClose,
    setIsSave,
    joinData,
    kindChangeMeet,
    isEdit,
    setIsEdit,
    isCancel,
    setIsCancel,
    taskIdsToAttach,
    handleAttachTasks,
    attachedTasks,
    onTaskDelete,
    preventAttachedTasksReset,
    openFilePicker,
    uploadedFiles,
    allFiles,
    deleteFile,
    handleFileDownload,
    resetUploadedFiles,
    resetFileIdsToRemove,
    removeUploadedFilesFromServer,
    restoreSoftDeletedFiles,
    handleFileChange,
  }: IViewNotSave) => {
    const dispatch = useDispatch();

    const {
      isVisibleStart,
      setIsVisibleStart,
      isVisibleEnd,
      setIsVisibleEnd,
      isVisibleRepeat,
      setIsVisibleRepeat,
      data,
      setData,
      formatDataSave,
      formatDataEdit,
      formatData,
      startTime,
      endTime,
      handleTime,
      pressCalendar,
      state,
      handleData,
      dateNow,
    } = useData({ meet, joinData, setIsEdit });

    const { isUserHasEditPermissions } = useRoleModel(meet ?? data);

    const isGroupAllSaved = data?.presetGroups?.length && data?.presetGroups[0].type === "ALL_IN_SPACE";

    const [notificationPopupTextSetting, setNotificationPopupTextSetting] = useState<{
      titleTextId: string;
      okTextId: string;
      cancelTextId: string;
    }>(notifyParticipantsTextIdOptions);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisibleActivity, setIsVisibleActivity] = useState(false);
    const [isVisibleActions, setIsVisibleActions] = useState(false);
    const [isVisibleNotificationOptions, setIsVisibleNotificationOptions] = useState(false);
    const [skipAttendanceFetch, setSkipAttendanceFetch] = useState(true);
    const [refethOnClose, setRefetchOnClose] = useState(false);
    const [shouldResetMeet, setShouldResetMeet] = useState(false);

    const permissions = useSelector(selectUserPermissoins);
    const { externalUsers } = useSelector(selectExternalUsers);
    const calledClose = useRef(false);
    const theme = selectTheme("extra");
    const { directorId } = userSelector();

    const meetUpdation = useRef({});

    const repeat = useMemo(() => (data.parentEvent ? data.parentEvent?.repeat : data.repeat), [data.parentEvent, data.repeat]);
    const [createMeeting, { isLoading: isProcessingCreation }] = useCreateMeetingMutation();
    const [updateSingleMeetOrSerie, { isLoading: isProccessingUpdate }] = useUpdateSingleOrSerieMeetingMutation();
    const [updateParticipantsPermissions] = useUpdateParticipantsPermissionsMutation();

    const { data: participantsAttendance } = useGetMeetingAttendanceQuery(
      {
        startTime: toFormatISO(dayjs(startTime)),
        endTime: toFormatISO(dayjs(endTime)),
        userIds: data.participants?.map((i) => i.userId) ?? [],
        excludedMeetingId: meet?.id ?? "",
      },
      {
        skip: skipAttendanceFetch,
      },
    );

    const isAccessRightsPickerVisible = useMemo(
      () => (data?.participants ?? []).length > 1 && (!meet || meet?.createdByCurrentUser),
      [meet, data],
    );

    const onPressAddParticipants = useCallback(() => {
      setIsVisibleActivity(true);
    }, []);

    const updateParticipantsPermissionsWrapper = (id: string) => {
      updateParticipantsPermissions({
        id,
        data: data.participantPermissions,
        userId: directorId,
      });
    };

    const handleSave = async () => {
      myTracker("SaveNewBusinessGoalClick");

      formatDataSave.attachedTaskIds = taskIdsToAttach;

      if (isEmpty(formatDataSave.repeat)) {
        formatDataSave.repeat = null;
      }

      if (externalUsers?.length || formatDataSave?.externalUsers?.length) {
        const allGroupFlag = formatDataSave?.externalUsers?.filter((item) => item?.type === "ALL_IN_SPACE")?.[0]?.id;

        if (allGroupFlag || isEmpty(formatDataSave.repeat)) {
          formatDataSave.presetGroupIds = [allGroupFlag];
          delete formatDataSave.presetGroups;
          formatDataSave.repeat = null;
        }

        formatDataSave.externalUserIds = [
          ...(formatDataSave.externalUsers ?? []).filter((item) => item?.id !== allGroupFlag).map((user) => user?.id),
          ...(externalUsers ?? []).map((user) => user?.id),
        ];
        formatDataSave.externalUsers = [...(formatDataSave.externalUsers ?? []).filter((item) => item?.id !== allGroupFlag)];
      }

      if (formatDataSave?.participants && formatDataSave?.participants.length > 1 && !formatDataSave?.participantPermissions) {
        const userPermissions = formatDataSave?.participants.map((item) => ({
          permissions: ["ADD_PARTICIPANTS"],
          userId: item.userId,
        }));

        formatDataSave.participantPermissions = userPermissions;
      }

      const response = await createMeeting({ data: formatDataSave, date: data.date }).unwrap();

      if (response) {
        !preventAttachedTasksReset && dispatch(resetAttachedTasks());

        removeUploadedFilesFromServer();
        setIsEdit(false);
        handleClose();
      }
    };

    const proceedHandleEdit = async (body: TTypeObject) => {
      if (!meet) return;

      let response;

      if (!(Object.keys(body).length === 1 && Object.keys(body)[0] === "participantPermissions")) {
        response = await updateSingleMeetOrSerie({
          id: meet.id,
          repeat: !!meet.repeat,
          changeSerie: kindChangeMeet === "serie",
          date: toFormatDate(dateNow),
          data: omit(body, ["participantPermissions"]),
          parentEvent: meet.parentEvent,
        }).unwrap();

        if (response) {
          if (kindChangeMeet === "event") {
            resetFileIdsToRemove();
            resetUploadedFiles();
            close();
            return;
          }
          removeUploadedFilesFromServer();
          close();
        }
      }

      if (data.participants?.length && data.createdByCurrentUser && data.participantPermissions) {
        let meetId = response?.id ?? meet.id;

        // Подмена meetID, т.к. BE отдает id исключения (APP-5511)
        if (kindChangeMeet === "serie" && Boolean(data?.repeat?.dayOfWeeks?.length)) {
          meetId = response?.parentEvent?.id ?? data?.id;
        }

        updateParticipantsPermissionsWrapper(meetId);
      }
      close();
    };

    const handleEdit = () => {
      if (!meet) return;

      delete formatDataEdit["fileIds"];

      if (
        !isEqual(
          attachedTasks?.map((item) => item.id),
          meet?.attachedTasks?.map((item) => item.id),
        )
      ) {
        formatDataEdit.attachedTaskIds = attachedTasks.map((i) => i.id);
      }

      if (
        !isEqual(
          allFiles?.map((file) => file.id),
          meet?.files?.map((file) => file.id),
        )
      ) {
        formatDataEdit.fileIds = allFiles.map((file) => file.id);
      }

      if (
        !isEqual(
          formatDataEdit?.externalUsers?.map((user) => user.id),
          meet?.externalUsers?.map((user) => user.id),
        )
      ) {
        formatDataEdit.externalUserIds = [...formatDataEdit.externalUsers.map((user) => user.id)];
      }

      const body = diff(formatDataEdit, meet);
      const updatedFields = Object.keys(body);

      const isOnlyParticipants = updatedFields.length === 1 && updatedFields.includes("participants");
      const isOnlyExternal =
        updatedFields.length === 2 && updatedFields.includes("externalUsers") && updatedFields.includes("externalUserIds");
      const isExternalAndInner =
        updatedFields.length === 3 &&
        updatedFields.includes("externalUsers") &&
        updatedFields.includes("externalUserIds") &&
        updatedFields.includes("participants");

      const isOnlyName = updatedFields.length === 1 && updatedFields.includes("name");
      const isOnlyDescription = updatedFields.length === 1 && updatedFields.includes("description");
      const isNameAndDescription = updatedFields.length === 2 && updatedFields.includes("name") && updatedFields.includes("description");

      if (isOnlyParticipants || isOnlyExternal || isExternalAndInner) {
        const participants = formatDataEdit.participants?.map((i) => ({ id: i.id, userId: i.userId, status: i.status }));
        body.participants = participants;
        meetUpdation.current = body;
        setIsVisibleNotificationOptions(true);
        setNotificationPopupTextSetting(notifyParticipantsTextIdOptions);

        return;
      }

      if (
        (isOnlyName || isOnlyDescription || isNameAndDescription) &&
        data?.participants &&
        (data?.participants.length > 1 || data?.externalUsers?.length > 0)
      ) {
        setIsVisibleNotificationOptions(true);
        setNotificationPopupTextSetting(confirmNotifyParticpantsTextIdOptions);
        meetUpdation.current = body;

        return;
      }

      if (body.startTime || body.endTime) {
        body.startTime = startTime;
        body.endTime = endTime;
      }

      proceedHandleEdit(body);
    };

    const handleNotifyAllParticipants = useCallback(() => {
      proceedHandleEdit({ ...meetUpdation.current, notifyAllParticipants: true });
      setIsVisibleNotificationOptions(false);
    }, [meetUpdation.current]);

    const handleNotifyOnlyNewParticipants = useCallback(() => {
      proceedHandleEdit({ ...meetUpdation.current, notifyAllParticipants: false });
      setIsVisibleNotificationOptions(false);
    }, [meetUpdation.current]);

    const handleCancel = () => {
      resetFileIdsToRemove();
      restoreSoftDeletedFiles();
      removeUploadedFilesFromServer({ removeUploaded: true, removeMarkedToDelete: false });

      setData(state);
      setIsCancel(false);

      if (meet) {
        setIsSave(true);
      }

      setIsVisibleStart(false);
      setIsVisibleEnd(false);
      close();
    };

    const handlePressRepeat = useCallback(() => {
      if (kindChangeMeet === "event") return showToast("replayEditingIsNotAvailable", "error");
      setIsVisibleRepeat(true);
    }, [kindChangeMeet]);

    const mainModalCancelHandler = () => {
      setIsCancel(true);
    };

    const close = () => {
      calledClose.current = true;

      if (meet) {
        setIsSave(true);
      } else {
        removeUploadedFilesFromServer({ removeUploaded: true, removeMarkedToDelete: true });
        handleClose();
      }
    };

    const currentActivePush = useMemo(
      () =>
        isGroupAllSaved
          ? false
          : !meet?.notificationDisableSettings?.filter(
              (item) => item?.notificationName === NotificationName.CONFIRM_MEETING_PARTICIPANT_STATUS,
            )[0]?.notificationTypes?.length,
      [meet, isGroupAllSaved],
    );

    const startDays = useMemo(() => [{ ...meet, date: toFormatDate(dayjs(startTime)) }], [meet, startTime]);

    useEffect(
      () => () => {
        dispatch(resetExternalUsers());
      },
      [],
    );

    useEffect(() => {
      externalUsers?.length && setIsEdit(true);
    }, [externalUsers]);

    useEffect(() => {
      if (meet && meet?.participants && meet?.participants.length > 1) {
        setSkipAttendanceFetch(false);
      }
    }, [meet?.participants]);

    useEffect(() => {
      setIsLoading(isProcessingCreation || isProccessingUpdate);
    }, [isProcessingCreation, isProccessingUpdate]);

    useEffect(
      () => () => {
        if (refethOnClose) {
          dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
        }
      },
      [refethOnClose],
    );

    useEffect(
      () => () => {
        if (shouldResetMeet && meet?.id) {
          dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: meet.id }]));
        }
      },
      [],
    );

    const isShowBillet = useMemo(() => {
      const amountParticipants = (data.participants?.length ?? 0) + (data.externalUsers?.length ?? 0) > 1;
      return !isGroupAllSaved && amountParticipants;
    }, [data]);

    return (
      <>
        <HeaderModal
          isEdit={isEdit}
          title={meet ? "meeting" : "newMeeting"}
          leftSide={{ onPressClose: close, onPressCancel: mainModalCancelHandler }}
          rightSide={{ onPress: () => (meet ? handleEdit() : handleSave()), isLoading }}
          themeTextColor={theme.text.main}
          styleContainer={{ backgroundColor: theme.background.main }}
        />
        <StContentWrapDiv>
          <StContentDiv>
            <div>
              <StButtonLineDiv>
                <button onClick={() => setIsVisibleActions(true)}>
                  <SvgDotes />
                </button>
              </StButtonLineDiv>
              <Inputs name={data.name} description={data.description} autoFocus={!meet} handleData={handleData} />
              <Tag id={data?.tagId ?? data?.tag?.id} handleData={handleData} />
            </div>
            <div>
              <Date
                startTime={startTime}
                pressCalendar={pressCalendar}
                isVisibleStart={isVisibleStart}
                disabled={kindChangeMeet === "serie"}
                repeat={repeat}
                startDays={startDays}
              />

              <Time
                value={startTime}
                type={EditTimerOptions.StartTime}
                title="beginDay"
                isVisible={isVisibleStart}
                handleTime={handleTime}
              />

              <Time value={endTime} type={EditTimerOptions.EndTime} title="endingDay" isVisible={isVisibleEnd} handleTime={handleTime} />
            </div>

            <RenderWithCondition condition={!isGroupAllSaved}>
              <div>
                <TextFont size={18} weight="700" type="bold">
                  <FormattedMessage id="repeat" />
                </TextFont>
                <Billet
                  textId={titleType[repeat?.type ?? "NOT_REPEAT"]}
                  styleButton={{ ...(kindChangeMeet === "event" && { backgroundColor: Colors.LIGHT.disabled }) }}
                  styleText={kindChangeMeet === "event" && { color: Colors.LIGHT.grey }}
                  onPress={handlePressRepeat}
                />
                <RenderWithCondition condition={repeat?.type}>
                  <StTextRepeatTFont size={18} color={kindChangeMeet === "event" ? Colors.LIGHT.grey : Colors.LIGHT.text.main}>
                    {renderResult({
                      type: repeat?.type,
                      endTime: repeat?.endTime ?? "",
                      days: repeat?.dayOfWeeks,
                      daysRepeat: repeat?.days ?? [],
                      period: repeat?.repetitionPeriod ?? 1,
                    })}
                  </StTextRepeatTFont>
                </RenderWithCondition>
              </div>
            </RenderWithCondition>
            <Placement
              places={data.places}
              meet={data}
              allowEdit
              handleData={handleData}
              isUserHasEditPermissions={isUserHasEditPermissions}
              startData={{
                data,
                startTime,
                endTime,
                handleData: setData,
                handleTime,
              }}
            />

            <RenderWithCondition condition={permissions.CAN_ATTEND_MEETING}>
              <div>
                <Participants
                  data={formatData}
                  isSave={false}
                  handleData={handleData}
                  currentUserAttendanceStatus={meet?.currentParticipantStatus ?? null}
                  onPressAdd={onPressAddParticipants}
                  participantsAttendance={participantsAttendance}
                  setRefetchOnClose={setRefetchOnClose}
                />
                <RenderWithCondition condition={isShowBillet}>
                  <Billet
                    textId="membersEmployment"
                    onPress={onPressAddParticipants}
                    styleText={{ color: Colors.LIGHT.text.accent, fontWeight: "400" }}
                  />
                </RenderWithCondition>
              </div>
            </RenderWithCondition>

            <RenderWithCondition condition={isAccessRightsPickerVisible && kindChangeMeet !== "event"}>
              <AccessRightsPicker meet={formatData} handleData={handleData} isEditMode={!!meet} />
            </RenderWithCondition>

            <MeetingTasks
              allowTaskAddition={isUserHasEditPermissions}
              allowTaskDeletion={isUserHasEditPermissions}
              attachedTasks={attachedTasks}
              handleAttachTasks={handleAttachTasks}
              onTaskDelete={(id) => onTaskDelete(id)}
            />

            <FileAttachments
              handleData={handleData}
              allowFilePick={isUserHasEditPermissions}
              allowFileDelete={isUserHasEditPermissions}
              openFilePicker={openFilePicker}
              allFiles={allFiles}
              uploadedFiles={uploadedFiles}
              handleFileDownload={handleFileDownload}
              deleteFile={deleteFile}
              handleFileChange={handleFileChange}
            />

            <LineHorizontal />
            <RenderWithCondition condition={meet?.createdByCurrentUser || meet?.currentParticipantStatus === "OWNER" || !meet}>
              <>
                {/* //TODO: =========== Temporary Hidden ========== */}
                {/* <RemindMeetings handleData={handleData} durations={meet?.notificationDurations}
              types={meet?.notificationTypes} isCreating /> */}
                <AllowPush handleData={handleData} isCreating currentActivePush={currentActivePush} />
              </>
            </RenderWithCondition>
          </StContentDiv>
        </StContentWrapDiv>

        <ModalRenderWithCondition condition={isCancel}>
          <Notification type="confirm" phraseId={"cancelException"} onOk={handleCancel} onCancel={() => setIsCancel(false)} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleRepeat}>
          <Repeat close={() => setIsVisibleRepeat(false)} meet={data} changeMeet={handleData} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleActivity}>
          <ActivityParticipants
            close={() => setIsVisibleActivity(false)}
            isUserHasEditPermissions={isUserHasEditPermissions}
            setRefetchOnClose={setRefetchOnClose}
            rootHandleData={handleData}
            setShouldResetMeet={setShouldResetMeet}
            startData={{
              data,
              startTime,
              endTime,
              handleData: setData,
              handleTime,
            }}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleActions}>
          <Menu
            componentMeetingTasks={
              <MeetingTasks
                onlyButton
                allowTaskAddition={data.createdByCurrentUser}
                allowTaskDeletion={data.createdByCurrentUser}
                attachedTasks={attachedTasks}
                handleAttachTasks={handleAttachTasks}
                onTaskDelete={(id) => onTaskDelete(id)}
              />
            }
            componentMeetingCategory={meet ? <CategoryButton meet={meet} /> : undefined}
            close={() => setIsVisibleActions(false)}
            openFilePicker={openFilePicker}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isVisibleNotificationOptions}>
          <NotificationConfirm
            phraseId={notificationPopupTextSetting.titleTextId}
            phraseOkId={notificationPopupTextSetting.okTextId}
            phraseCancelId={notificationPopupTextSetting.cancelTextId}
            onOk={handleNotifyAllParticipants}
            onCancel={handleNotifyOnlyNewParticipants}
            isOpen={isVisibleNotificationOptions}
          />
        </ModalRenderWithCondition>
      </>
    );
  },
  arePropsEqual,
);

const StContentWrapDiv = styled.div`
  overflow: auto;
  height: 95%;
`;
const StContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 12px 12px;
`;

const StButtonLineDiv = styled.div`
  display: flex;
  justify-content: end;
`;
const StTextRepeatTFont = styled(TextFont)`
  line-height: 24px;
  margin-top: 6px;
`;
