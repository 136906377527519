import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgEyeClosed } from "@assets/eye-close.svg";
import { ReactComponent as SvgEye } from "@assets/eye.svg";
import { setIsHideTasks } from "@store/calendar/slice";
import { selectTheme } from "@store/theme";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const ToggleDisplayTasks = () => {
  const dispatch = useDispatch();
  const theme = selectTheme("extra");

  const [displayTasks, setIsDisplayTasks] = useState(false);

  const handleSwitchChange = () => {
    setIsDisplayTasks(!displayTasks);
    dispatch(setIsHideTasks(!displayTasks));
  };

  return (
    <ButtonItem onClick={handleSwitchChange}>
      {displayTasks ? <SvgEye fill={theme.text.accent} /> : <SvgEyeClosed fill={theme.text.accent} />}
      <TextFont size={16} color={theme.text.accent}>
        <FormattedMessage id="tasks" defaultMessage="Задачи" />
      </TextFont>
    </ButtonItem>
  );
};

const ButtonItem = styled.button`
  ${boxCenterCss}
  background-color: ${Colors.DARK.lighGrey};
  border-radius: 5px;
  height: 22px;
  padding: 0 5px 0 3px;
`;
