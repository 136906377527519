import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TextFont } from "@ui";

interface IProps {
  email: string;
  setIsInviteExternalParticipantModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddNonExistingParticipantButton: FC<IProps> = ({ email, setIsInviteExternalParticipantModalVisible }) => (
  <ContentWrap
    onClick={() => {
      setIsInviteExternalParticipantModalVisible(true);
    }}
  >
    <TextFont size={16}>
      <p>
        {email} - <FormattedMessage id="addParticipant" defaultMessage={"Добавить участника"} />
      </p>
    </TextFont>
  </ContentWrap>
);

const ContentWrap = styled.div`
  margin: 16px 0;
  cursor: pointer;
`;
