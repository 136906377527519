import { TSubListInfo } from "@interfaces/user.interface";

export const getSublistParams = (currentSublistType: TSubListInfo) => {
  switch (currentSublistType) {
    case "Delegation":
      return {
        showFromDelegationChain: true,
        showFromDepartment: false,
        showOthers: false,
        active: true,
      };
    case "Department":
      return {
        showFromDelegationChain: false,
        showFromDepartment: true,
        showOthers: false,
        active: true,
      };
    case "Others":
      return {
        showFromDelegationChain: false,
        showFromDepartment: false,
        showOthers: true,
        active: true,
      };
    default:
      return {
        showFromDelegationChain: true,
        showFromDepartment: false,
        showOthers: false,
        active: true,
      };
  }
};
