import React, { FC, useCallback, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { TextFont } from "@components/ui";
import { NotificationUI } from "@components/ui/notification/NotificationUI";
import { showToast } from "@utils/toast";

interface IProps {
  defaultValue: string;
  onClose: () => void;
  onSave: (newValue: string) => void;
}

const EditCategoryModal: FC<IProps> = ({ defaultValue, onClose, onSave }) => {
  const [value, setValue] = useState(defaultValue);

  const handleSave = useCallback(() => {
    onSave(value);
  }, [value, onSave]);

  useEffect(() => {
    if (!value) showToast("setCategoryName");
  }, [value]);

  return (
    <NotificationUI
      isOpen
      isVisible={true}
      style={{
        display: "flex",
        "align-items": "center",
      }}
      onClose={onClose}
    >
      <StModalWrapperDiv>
        <StModalDiv>
          <StHeaderDiv>
            <TextFont style={{ fontWeight: "700", fontSize: "16px" }}>
              <FormattedMessage id="categoryRename" />
            </TextFont>
            <StCloseButtonDiv onClick={onClose}>
              <SvgClose />
            </StCloseButtonDiv>
          </StHeaderDiv>
          <StInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ width: "90%" }}
            placeholder={"Название категории"}
            maxLength={25}
          />
          <StButtonWrapperDiv>
            <StBtn onClick={onClose} backgroundColor="#FFF">
              <TextFont style={{ color: "black" }}>
                <FormattedMessage id={"cancelClose"} />
              </TextFont>
            </StBtn>
            <StBtn onClick={handleSave} backgroundColor="#297952" disabled={!value} value={value}>
              <TextFont style={{ color: "#FFF" }}>
                <FormattedMessage id={"ok"} />
              </TextFont>
            </StBtn>
          </StButtonWrapperDiv>
        </StModalDiv>
      </StModalWrapperDiv>
    </NotificationUI>
  );
};

// Styled-components
const StModalWrapperDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f9f8;
  border-radius: 14px;
  padding: 24px;
  width: 350px;
`;

const StHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StInput = styled.input`
  font-size: 18px;
  border-radius: 10px;
  background-color: #ecf0ef;
  border: 1px solid #e3e9e7;
  padding: 16px 20px;
  width: 100%;
`;

const StButtonWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const StBtn = styled.button<{ backgroundColor: string; value?: string }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ value }) => (value ? 1 : 0.7)};
`;

const StCloseButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #ecf0ef;
  cursor: pointer;
`;

export default EditCategoryModal;
