import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { AsyncStorageKeys } from "@constants/asyncStorageKeys";
import { selectCalendar } from "@store/calendar";
import { setViewMode } from "@store/calendar/slice";
import { CalendarViewModes } from "@store/calendar/slice";
import { selectTheme } from "@store/theme/selectors";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const WeekMonthSwitcher: FC = () => {
  const { viewMode } = useSelector(selectCalendar);
  const dispatch = useDispatch();
  const theme = selectTheme("extra");
  const isMonthSelected = viewMode === CalendarViewModes.Month;
  const isWeekSelected = viewMode === CalendarViewModes.Week;

  const setViewModeWrapper = (selectedMode: CalendarViewModes) => {
    dispatch(setViewMode(selectedMode));
    localStorage.setItem(AsyncStorageKeys.CalendarViewMode, selectedMode);
  };

  return (
    <ContentWrap>
      <ButtonTabLeft
        backColor={isMonthSelected ? theme.background.green : theme.white}
        onClick={() => setViewModeWrapper(CalendarViewModes.Month)}
      >
        <TextFont size={10} color={isMonthSelected ? theme.white : theme.background.green} align="center">
          <FormattedMessage id={"month"} defaultMessage={"Месяц"} />
        </TextFont>
      </ButtonTabLeft>

      <ButtonTabRight
        backColor={isWeekSelected ? theme.background.green : theme.white}
        onClick={() => setViewModeWrapper(CalendarViewModes.Week)}
      >
        <TextFont size={10} color={isWeekSelected ? theme.white : theme.background.green} align="center">
          <FormattedMessage id={"week"} defaultMessage={"Неделя"} />
        </TextFont>
      </ButtonTabRight>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  height: 22px;
`;
const ButtonTab = styled.button`
  border: 1px ${Colors.LIGHT.background.green};
  border-radius: 5px;
  padding-top: 3px;
  width: 55px;
`;
const ButtonTabLeft = styled(ButtonTab)<{ backColor: string }>`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  ${({ backColor }) =>
    backColor &&
    `
  background-color: ${backColor}
`}
`;
const ButtonTabRight = styled(ButtonTab)<{ backColor: string }>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  ${({ backColor }) =>
    backColor &&
    `
  background-color: ${backColor}
`}
`;
