import { useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { useTime } from "@hooks/useTime";
import { useGetCommonMeetingSlotQuery } from "@services/meetApi";
import { selectExternalUsers } from "@store/externalUsers";
import { toFormatISO } from "@utils/toFormatTime";

import { TStringUnknownTupleArray, isStringUnknownTupleArray } from "../utils";

import { IStartData } from "./ActivityParticipants";

export const useData = (startData: IStartData) => {
  const { startTime, endTime, handleTime } = useTime({
    startValue: startData?.startTime,
    endValue: startData?.endTime,
  });

  const [data, setData] = useState(startData?.data);
  const [isEdit, setIsEdit] = useState(false);
  const [isVisbleCalendar, setIsVisbleCalendar] = useState(false);
  const [isVisbleSetCommonSlot, setIsVisbleSetCommonSlot] = useState(false);
  const [participantLight, setParticipantLight] = useState("");
  const [meetingDurationMins, setMeetingDurationMins] = useState(0);

  const { externalUsers } = useSelector(selectExternalUsers);

  const { data: commonSlot } = useGetCommonMeetingSlotQuery({
    data: {
      startTime: toFormatISO(dayjs(startTime)),
      endTime: toFormatISO(dayjs(endTime).add(1, "month")),
      userIds: data.participants?.map((i) => i.userId) ?? [],
      duration: dayjs(endTime).diff(startTime, "minutes"),
    },
  });

  useEffect(() => {
    const diffMinutes = dayjs(endTime).diff(dayjs(startTime), "minutes");

    setMeetingDurationMins(diffMinutes);
  }, [startTime, endTime]);

  useEffect(() => {
    setIsEdit(
      startTime !== startData?.startTime ||
        endTime !== startData.endTime ||
        !isEqual(startData?.data?.participants, data?.participants) ||
        !isEqual(startData?.data?.externalUsers, data?.externalUsers) ||
        Boolean(externalUsers?.length),
    );
  }, [data?.participants, data?.externalUsers, externalUsers, startTime, endTime, startData]);

  const handleData = (name: string | TStringUnknownTupleArray, value: unknown) => {
    if (typeof name === "string") {
      setData({
        ...data,
        [name]: value,
      });

      return;
    }

    if (isStringUnknownTupleArray(name)) {
      const updation = { ...data };

      for (const item of name) {
        updation[item[0]] = item[1];
      }

      setData(updation);
    }
  };

  const pressCommonSlot = () => {
    if (!dayjs(startTime).isSame(commonSlot?.startTime) || !dayjs(endTime).isSame(commonSlot?.endTime)) {
      setIsVisbleSetCommonSlot(true);
    }
  };

  const handleSetTimeFromCommonSlot = () => {
    const calculatedEndTime = dayjs(commonSlot?.startTime).add(meetingDurationMins, "minutes").format("YYYY-MM-DDTHH:mm:ss");

    handleTime("startTime", commonSlot?.startTime);
    handleTime("endTime", calculatedEndTime);
    setIsVisbleSetCommonSlot(false);
  };

  const formatData = useMemo(() => ({ ...data, startTime, endTime }), [startTime, endTime, data]);

  return useMemo(
    () => ({
      data,
      handleData,
      startTime,
      endTime,
      handleTime,
      isEdit,
      pressCommonSlot,
      handleSetTimeFromCommonSlot,
      isVisbleSetCommonSlot,
      setIsVisbleSetCommonSlot,
      isVisbleCalendar,
      setIsVisbleCalendar,
      participantLight,
      setParticipantLight,
      formatData,
      meetingDurationMins,
      commonSlot,
    }),
    [
      data,
      startTime,
      formatData,
      endTime,
      isVisbleSetCommonSlot,
      isEdit,
      isVisbleCalendar,
      participantLight,
      meetingDurationMins,
      commonSlot,
    ],
  );
};
