import React, { FC, HTMLAttributes } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ButtonBillet } from "@styles/styles";
import { Colors } from "@theme/colors";

import { ArrowSvg } from "./ArrowSvg";
import { TextFont } from "./TextFont";

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  textId: string;
  additionalText?: string;
  styleButton?: any;
  styleText?: any;
  onPress?: (...arg: any) => void;
  before?: JSX.Element;
}

export const Billet: FC<IProps> = ({ textId, styleButton, styleText, additionalText, onPress, before, ...props }) => (
  <ButtonBillet style={{ ...styleButton }} onClick={onPress} {...props}>
    <Content>
      {before}
      <TextFont size={16} weight="700" style={{ ...styleText }}>
        {additionalText}
        <FormattedMessage id={textId} />
      </TextFont>
    </Content>
    <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
  </ButtonBillet>
);

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
