import { ICategory } from "../types";

export const sortCategories = (categories: ICategory[]) => {
  if (!categories) return categories;

  const defaultCategories: ICategory[] = [];
  const customCategories: ICategory[] = [];

  categories.forEach((item) => (item?.isDefault ? defaultCategories : customCategories).push(item));

  const sortedCustom = customCategories.sort((a, b) => {
    if (!a.description) return 1;
    if (!b.description) return -1;

    return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
  });

  return [...sortedCustom, ...defaultCategories];
};
