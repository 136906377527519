import React, { FC, useCallback, useEffect, memo, useRef, useState, useLayoutEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { LoaderRenderWithCondition } from "@components/hoc";
import { DEVIATION, arrHours, heightBlockEvent } from "@components/screens/day/components/timetable/main/constants";
import { Event } from "@components/screens/day/components/timetable/main/row/Event";
import { Row } from "@components/screens/day/components/timetable/main/row/Row";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { selectScreenDay } from "@store/screenDay";
import { getList, selectStoreTag } from "@store/tag";
import { arePropsEqual } from "@utils/arePropsEqual";

interface ITimetableViewMeet {
  eventsOfDay: IEventsOfDay[];
  handlePress: (arg: string, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  lightId?: string;
}

const TimetableViewMeet: FC<ITimetableViewMeet> = ({ eventsOfDay, handlePress, lightId }: ITimetableViewMeet) => {
  const refView = useRef(null);
  const dispatch = useDispatch();

  const [width, setWidth] = useState(0);

  const { isLoading } = useSelector(selectScreenDay);
  const { tags } = useSelector(selectStoreTag);

  useEffect(() => {
    if (!tags.length) {
      dispatch(getList(""));
    }
  }, []);

  useEffect(() => {
    const elem = document?.getElementById("event_lighting");

    refView?.current?.scrollTo({
      left: 0,
      top: elem?.getBoundingClientRect().top,
      behavior: "smooth",
    });
  }, [refView, lightId, eventsOfDay]);

  useEffect(() => {
    const block = document.getElementById("timetableUnanswered");
    setWidth(block.clientWidth - DEVIATION);
  }, [window.innerWidth]);

  const renderEvents = (hour: number) => {
    const filteredEvents = eventsOfDay;

    if (!filteredEvents) return null;
    return filteredEvents.map((event, index) => {
      if (Array.isArray(event)) {
        return (
          <>
            {
              /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
              event.map((i: any) => (
                <Event
                  key={i.id}
                  hour={hour}
                  press={handlePress}
                  heightBlock={heightBlockEvent}
                  index={index}
                  startHour={arrHours[0]}
                  columns={i.columns}
                  width={width}
                  isLight={i.id === lightId}
                  {...i}
                />
              ))
            }
          </>
        );
      }
      return (
        <Event
          key={event.id}
          hour={hour}
          heightBlock={heightBlockEvent}
          press={handlePress}
          index={index}
          startHour={arrHours[0]}
          width={width}
          isLight={event.id === lightId}
          {...event}
        />
      );
    });
  };

  return (
    <section ref={refView} style={{ ...styles.container, height: "95%" }}>
      <LoaderRenderWithCondition condition={isLoading}>
        <>
          <div style={{ paddingTop: 20, width: "100%" }}>
            {arrHours.map((hour, index) => (
              <div key={index} style={{ position: "relative" }}>
                <Row key={index} hour={hour} isHide width={width} />
                {renderEvents(hour)}
              </div>
            ))}
          </div>
        </>
      </LoaderRenderWithCondition>
    </section>
  );
};

export default memo(TimetableViewMeet, arePropsEqual);

const styles = {
  container: {
    width: "100%",
    display: "flex",
    "overflow-y": "auto",
  },
};
