import React, { useRef } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { IFile } from "@interfaces/files.interfaces";
import { FileAttachments } from "@shared";
import { RootState } from "@store";
import { TextFont } from "@ui";
import { LoaderButton } from "@ui/LoaderButton";

interface IProps {
  message: string;
  isLoading: boolean;
  setMessage: (value: string) => void;
  sendEvent: () => void;
  placeholderId: string;
  allowFileAttach?: boolean;
  openFilePicker: () => void;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  handleFileChange: (files: FileList) => void;
}

export const Sender = ({
  message,
  setMessage,
  sendEvent,
  isLoading,
  placeholderId,
  allowFileAttach,
  uploadedFiles,
  allFiles,
  deleteFile,
  handleFileDownload,
  openFilePicker,
  handleFileChange,
}: IProps) => {
  const refMessage = useRef(null);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const placeholder = useIntl().formatMessage({ id: placeholderId });

  return (
    <section style={{ ...styles.formContainer, pointerEvents: isLoading ? "none" : "auto" }}>
      <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between", gap: 20 }}>
        <input
          style={{ ...styles.input, marginBottom: 0, width: "48%", color: "#C7C7C7" }}
          value={currentUser?.firstName}
          readOnly={true}
          maxLength={255}
        />
        <input
          style={{ ...styles.input, marginBottom: 0, width: "48%", color: "#C7C7C7" }}
          value={String(currentUser?.login)}
          readOnly={true}
          maxLength={255}
        />
      </div>

      <textarea
        ref={refMessage}
        style={{ ...styles.input, height: 130, width: "96%", backgroundColor: "#fff", borderColor: "white", marginBottom: 20 }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={placeholder}
        readOnly={isLoading}
        maxLength={255}
      />
      <RenderWithCondition condition={allowFileAttach}>
        <FileAttachments
          allowFilePick
          allowFileDelete
          openFilePicker={openFilePicker}
          allFiles={allFiles}
          uploadedFiles={uploadedFiles}
          handleFileDownload={handleFileDownload}
          deleteFile={deleteFile}
          handleFileChange={handleFileChange}
        />
      </RenderWithCondition>

      <button
        onClick={sendEvent}
        disabled={!message}
        style={{ ...styles.button, ...(isLoading && styles.buttonLoading), ...(!message && styles.buttonDisabled) }}
      >
        <LoaderButtonRenderWithCondition condition={isLoading}>
          <TextFont style={styles.buttonText}>
            <FormattedMessage id="send" />
          </TextFont>
        </LoaderButtonRenderWithCondition>
      </button>
    </section>
  );
};

const styles = {
  formContainer: {
    padding: "38px 12px 0 12px",
  },
  input: {
    fontSize: 18,
    borderRadius: 10,
    border: "1px solid #ECF0EF",
    backgroundColor: "#ECF0EF",
    marginBottom: 15,
    padding: "16px 8px",
  },
  button: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 0",
    backgroundColor: "#297952",
    borderRadius: 10,
    marginTop: 20,
    width: "100%",
    height: 50,
  },
  buttonLoading: {
    padding: "10px 0",
  },
  buttonDisabled: {
    backgroundColor: "#E3E9E7",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "700",
  },
};
