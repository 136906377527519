import { Mutex } from "async-mutex";
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig, HttpStatusCode } from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { ACCESS_TOKEN, DIRECTOR_ID, REFRESH_TOKEN } from "@constants/settings";
import { authService } from "@services/auth.service";
import { secureStore } from "@utils/secureStore";
import { showToast } from "@utils/toast";

dayjs.extend(utc);
dayjs.extend(timezone);

const headers: Readonly<Record<string, string | boolean>> = {
  "Time-Zone": dayjs.tz.guess(),
};

export const BASE_URL = `https://${window.location.host}`;

export const http = axios.create({
  headers,
  withCredentials: true,
  baseURL: BASE_URL,
});

const globalRequests = ["api/api-gateway/v1/users/managers", "api/api-gateway/v1/app/version", "api/uaa/v1/users/info"];

const needAssistantRequests = [
  "api/settings/v1/participant-groups",
  "api/api-gateway/v1/participant-groups",
  /api\/api-gateway\/v1\/meetings(\/[0-9A-z\\-]*)?\/participants/g,
  "api/calendar/v1/external-users",
  "api/settings/v1/user-settings",
  "api/notifications/v2/messages",
  "api/notifications/v1/messages",
  "api/outlook-ews-integration/v1/authorize",
  "api/outlook-ews-integration/v1/unauthorize",
  "api/outlook-ews-integration/v1/authorize/status",
];

export const injectToken = async (config: AxiosRequestConfig): Promise<any> => {
  try {
    const isBasicAuth = (config.headers?.Authorization as string)?.startsWith("Basic");
    const token = await secureStore.getValue(ACCESS_TOKEN);

    if (!config.headers) {
      throw new Error("method injectToken: config.headers имеет значение undefined");
    }
    if (token !== null && !isBasicAuth) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const injectParams = async (config: InternalAxiosRequestConfig<any>) => {
  const id = getDirectorId(config.url);

  if (id) {
    if (!config.params) {
      config.params = {};
    }
    config.params.userId = id;
  }

  return config;
};

const getDirectorId = (url: string) => {
  if (globalRequests.some((i) => new RegExp(i, "gim").test(url))) {
    return undefined;
  }

  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/tasks" ||
    needAssistantRequests.some((i) => new RegExp(i, "gim").test(url))
  ) {
    return localStorage.getItem(DIRECTOR_ID);
  }
  return undefined;
};

export const updateTokens = async () => {
  const result = await authService.updateTokensApi();
  const accessToken = result?.data?.access_token;
  const refreshToken = result?.data?.refresh_token;

  if (accessToken && refreshToken) {
    secureStore.saveValue(ACCESS_TOKEN, accessToken);
    secureStore.saveValue(REFRESH_TOKEN, refreshToken);
  }
  return accessToken;
};

const mutex = new Mutex();
export const injectHandleErrors = async (error: any) => {
  if (!navigator.onLine) {
    showToast("connectInternetAlert", "error");
    return Promise.reject(error);
  }

  const { status } = error.response;
  const { config } = error;

  switch (status) {
    case HttpStatusCode.InternalServerError: {
      break;
    }
    case HttpStatusCode.Unauthorized: {
      let newAccessToken: string;

      if (!mutex.isLocked()) {
        await mutex.acquire();
        try {
          newAccessToken = await updateTokens();
        } finally {
          mutex.release();
        }
      } else {
        await mutex.waitForUnlock();
        newAccessToken = secureStore.getValue(ACCESS_TOKEN);
      }

      if (!newAccessToken) {
        return Promise.reject(error);
      }

      config._retry = true;
      config.headers["Authorization"] = `Bearer ${newAccessToken}`;

      try {
        const res = await http(config);
        return res;
      } catch (error) {
        return Promise.reject(error);
      }
    }
    case HttpStatusCode.TooManyRequests: {
      break;
    }
    case HttpStatusCode.GatewayTimeout:
    case HttpStatusCode.BadGateway: {
      showToast("connectServerAlert", "error");
      break;
    }
    case HttpStatusCode.Forbidden: {
      showToast("Недостаточно прав", "error");
      break;
    }
  }

  return Promise.reject(error);
};

http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
http.interceptors.request.use(injectParams, (error) => Promise.reject(error));
http.interceptors.response.use((response) => response, injectHandleErrors);
