import React, { FC, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgSubTract } from "@assets/subtract.svg";
import { LetterAvatar } from "@components/screens/day/components/timetable/main/modal/components/participants/components";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { ITask } from "@interfaces/businessGoals.interface";
import { IStateUser } from "@interfaces/user.interface";
import { UserInformation } from "@screens/profile/components";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectUserPermissoins } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI, AssistantToast } from "@ui";
import { toFormatDayMonth } from "@utils/toFormatTime";

import { ModalDelegate } from "../../../components/modalDelegate";

interface IProps {
  task: ITask;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  onTakeMe?: () => void;
  disable?: { value: boolean; press: () => void };
}

export const Delegate: FC<IProps> = ({ task, onSave, onTakeMe, disable }) => {
  const [visible, setVisible] = useState<"to" | "from" | undefined>(undefined);
  const [isVisibleDelegate, setIsVisibleDelegate] = useState(false);

  const permissions = useSelector(selectUserPermissoins);
  const isNotFiredFromUser = useMemo(
    () => task?.delegatedFromUser?.active === true || task?.delegatedFromUser?.active === undefined,
    [task?.delegatedFromUser?.active],
  );
  const isNotFiredToUser = useMemo(
    () => task?.delegatedToUser?.active === true || task?.delegatedToUser?.active === undefined,
    [task?.delegatedToUser?.active],
  );

  const handlePressAvatar = (e, type: "from" | "to") => {
    e.stopPropagation();
    setVisible(type);
  };

  return (
    <>
      <RenderWithCondition condition={task.delegatedFromUser}>
        <StButtonItemBtn onClick={(e) => handlePressAvatar(e, "from")}>
          <RenderWithCondition condition={!isNotFiredFromUser}>
            <LetterAvatar
              style={{ backgroundColor: Colors.DARK.asphalt }}
              userName={task?.delegatedFromUser?.lastName ?? ""}
              lastName={task?.delegatedFromUser?.firstName ?? ""}
              colorText={Colors.LIGHT.white}
            />
          </RenderWithCondition>
          <RenderWithCondition condition={isNotFiredFromUser}>
            <StAvatarWrapDiv>
              <UserAvatar avatarFileId={task.delegatedFromUser?.avatarFileId ?? null} style={userAvatarStyle} />
            </StAvatarWrapDiv>
          </RenderWithCondition>
          <StBlockInfoDiv>
            <TextFont style={{ color: Colors.LIGHT.purple, marginBottom: 8 }}>Делегировано от</TextFont>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{ ...(!isNotFiredFromUser && { textDecoration: "line-through" }) }}
            >{`${task.delegatedFromUser?.firstName} ${task.delegatedFromUser?.lastName}`}</TextFont>
          </StBlockInfoDiv>

          <StBlockDateDiv>
            {task.delegatedAssistantTask && task.assistant && <AssistantToast assistant={task.assistant as IStateUser} />}
            <RenderWithCondition condition={task.delegatedFromDateTime}>
              <TextFont size={14} color={Colors.LIGHT.text.grey400}>
                {toFormatDayMonth(dayjs(task.delegatedFromDateTime ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </StBlockDateDiv>
        </StButtonItemBtn>
      </RenderWithCondition>

      <RenderWithCondition condition={permissions.CAN_DELEGATE && task.delegatedToUser}>
        <StButtonItemBtn onClick={() => (disable?.value ? disable.press() : setIsVisibleDelegate(true))}>
          <RenderWithCondition condition={!isNotFiredToUser}>
            <LetterAvatar
              style={{ backgroundColor: Colors.DARK.asphalt }}
              userName={task?.delegatedToUser?.lastName ?? ""}
              lastName={task?.delegatedToUser?.firstName ?? ""}
              colorText={Colors.LIGHT.white}
            />
          </RenderWithCondition>
          <RenderWithCondition condition={isNotFiredToUser}>
            <StAvatarWrapDiv onClick={(e) => handlePressAvatar(e, "to")}>
              <UserAvatar avatarFileId={task.delegatedToUser?.avatarFileId ?? null} style={userAvatarStyle} />
            </StAvatarWrapDiv>
          </RenderWithCondition>

          <StBlockInfoDiv>
            <TextFont color={Colors.LIGHT.yellow}>Делегировано на</TextFont>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{ ...(!isNotFiredToUser && { textDecoration: "line-through" }) }}
            >{`${task.delegatedToUser?.firstName} ${task.delegatedToUser?.lastName}`}</TextFont>
          </StBlockInfoDiv>

          <StBlockDateDiv>
            <SvgSubTract />
            <RenderWithCondition condition={task.delegatedToDateTime}>
              <TextFont size={14} color={Colors.LIGHT.text.grey400}>
                {toFormatDayMonth(dayjs(task.delegatedToDateTime ?? ""))}
              </TextFont>
            </RenderWithCondition>
          </StBlockDateDiv>
        </StButtonItemBtn>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={Boolean(visible)}>
        <ModalUI isVisible={Boolean(visible)} onClose={() => setVisible(undefined)}>
          <UserInformation
            user={visible === "from" ? task.delegatedFromUser : task.delegatedToUser}
            onClose={() => setVisible(undefined)}
          />
        </ModalUI>
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDelegate}>
        <ModalDelegate
          onSave={onSave}
          task={task}
          delegatedToUser={task.delegatedToUser}
          close={() => setIsVisibleDelegate(false)}
          onTakeMe={onTakeMe}
        />
      </ModalRenderWithCondition>
    </>
  );
};

const StButtonItemBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: ${Colors.LIGHT.white};
  border-radius: 10px;
  padding: 8px;
  width: 100%;
`;
const StAvatarWrapDiv = styled.div`
  width: 50px;
`;
const StBlockInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  margin-left: 8px;
  gap: 8px;
`;
const StBlockDateDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  gap: 5px;
`;
const userAvatarStyle = {
  width: "45px",
  height: "45px",
  borderRadius: "45px",
};
