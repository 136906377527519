import React, { FC, useState } from "react";

import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { workDayComment } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { TextareaComment } from "@styles/styles";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { toFormatDate } from "@utils";

interface Props {
  close: () => void;
  id?: string;
  isPersonalTask: boolean;
}

export const Comment: FC<Props> = ({ close, id, isPersonalTask = false }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [isCancel, setIsCancel] = useState(false);

  const date = toFormatDate(useSelector(selectCurrentDate));

  const handleSave = () => {
    dispatch(workDayComment({ id, body: { date, comment }, isPersonalTask }));
    close();
  };

  const phComment = useIntl().formatMessage({ id: "comment" });

  return (
    <ModalUI isVisible={true} onClose={comment ? () => setIsCancel(true) : () => close()}>
      <HeaderModal
        title="comment"
        isEdit={!!comment}
        rightSide={{ onPress: handleSave }}
        leftSide={{ onPressClose: close, onPressCancel: () => setIsCancel(true) }}
        styleContainer={{ borderBottomWidth: 0 }}
      />

      <ContentWrap>
        <TextareaComment value={comment} onChange={(e) => setComment(e.target.value)} placeholder={phComment} maxLength={255} />
      </ContentWrap>

      <ModalRenderWithCondition condition={isCancel}>
        <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  padding: 30px 20px;
`;
