import React, { CSSProperties, FC, HTMLAttributes } from "react";

import styled from "styled-components";

import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

type TProps = HTMLAttributes<HTMLSpanElement> & {
  userName: string;
  lastName?: string;
  translucent?: boolean;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  colorText?: string;
};

export const LetterAvatar = ({ userName, translucent, lastName, colorText = Colors.LIGHT.text.grey, style }: TProps) => {
  const avatarLetters = `${userName?.[0]?.toUpperCase() || ""}${lastName?.[0]?.toUpperCase() || ""}`;
  return (
    <div style={{ ...styles.letterAvatar, ...(translucent && { opacity: 0.5 }), ...style }}>
      <TextFont size={16} weight="700" color={colorText}>
        {avatarLetters}
      </TextFont>
    </div>
  );
};

const styles = {
  letterAvatar: {
    width: 45,
    height: 45,
    borderRadius: 45,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: Colors.LIGHT.lighGrey,
    backgroundColor: Colors.LIGHT.background.main,
  },
};

const ContentWrap = styled.div<{ translucent: boolean; backColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  margin-right: 8px;
  border: 1px solid ${Colors.LIGHT.lighGrey};
  background-color: ${Colors.LIGHT.background.main};
  ${({ translucent }) =>
    translucent &&
    `
    opacity: 0.5;
  `}
  ${({ backColor }) =>
    backColor &&
    `
    background-color: backColor;
  `}
`;
