import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface ISettingsOptionItem {
  isToggleEnabled: boolean;
  disableToggle: boolean;
  onToggleChange: React.Dispatch<React.SetStateAction<boolean>>;
  textId: string;
  defaultText?: string;
  style?: React.CSSProperties;
}

export const SettingsOptionItem: FC<ISettingsOptionItem> = ({
  textId,
  isToggleEnabled,
  disableToggle,
  defaultText,
  onToggleChange,
  ...props
}) => (
  <ContentWrap {...props}>
    <Content>
      <TextFont size={16} type="bold" weight="700" color={Colors.LIGHT.text.darkGreen}>
        <FormattedMessage id={textId} defaultMessage={defaultText} />
      </TextFont>
    </Content>
    <Spacer px={10} horizontal />
    <Toggle checked={isToggleEnabled} onChange={onToggleChange} icons={false} disabled={disableToggle} />
  </ContentWrap>
);

const ContentWrap = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  height: 72px;
  padding: 0 12px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: ${Colors.LIGHT.white};
`;
const Content = styled.div`
  width: 80%;
`;
