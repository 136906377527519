import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { LIMIT_LETTERS_TAG } from "@constants/logic";
import { ITag } from "@interfaces/businessGoals.interface";
import { create, selectSpecialTagByName } from "@store/tag";
import { statusOfDispatchEvent } from "@utils/statusDispatch";

interface IProps {
  initialText?: string;
}

type UseTask = {
  handleSaveTitle: () => Promise<string | null>;
  isErrorTitle: boolean;
  setIsErrorTitle: Dispatch<SetStateAction<boolean>>;
  onChangeTitle: (arg: string) => void;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  tag: string;
  setTag: Dispatch<SetStateAction<string>>;
  currentTag: ITag;
};

export const useTask = ({ initialText = "" }: IProps): UseTask => {
  const dispatch = useDispatch();

  const [isErrorTitle, setIsErrorTitle] = useState<boolean>(false);
  const [text, setText] = useState(initialText);
  const [name, setName] = useState(initialText);
  const [tag, setTag] = useState("");

  const currentTag = useSelector(selectSpecialTagByName(tag));

  useEffect(() => {
    if (!text) {
      setName("");
      setTag("");
    }
  }, [text]);

  const handleSaveTitle = async () => {
    let tagId = "";

    if (tag.length > LIMIT_LETTERS_TAG) {
      setIsErrorTitle(true);
      return null;
    } else if (currentTag) {
      tagId = currentTag.id;
    } else if (tag.length > 0 && tag.length < LIMIT_LETTERS_TAG + 1) {
      const response = await dispatch(create(tag));
      if (statusOfDispatchEvent(response) === "fulfilled") {
        tagId = response?.payload?.response?.data?.id;
      }
    }

    return tagId;
  };

  const onChangeTitle = (value: string) => {
    const index = value.indexOf("//");
    if (index === -1) {
      setName(value.trim());
      setTag("");
    } else {
      setTag(value.slice(0, index).trim());
      setName(value.slice(index + 2, value.length).trim());
    }
    setText(value);
  };

  return useMemo(
    () => ({
      handleSaveTitle,
      isErrorTitle,
      setIsErrorTitle,
      onChangeTitle,
      text,
      setText,
      name,
      setName,
      tag,
      setTag,
      currentTag,
    }),
    [isErrorTitle, text, name, tag, currentTag],
  );
};
