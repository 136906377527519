import React, { FC } from "react";

import { CSSProperties } from "styled-components";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { TextFont, Checkbox } from "@ui";

interface IProps {
  handleCheck: (value: boolean) => void;
  isChecked: boolean;
  style?: CSSProperties;
}

export const SelectAllButton: FC<IProps> = ({ isChecked, handleCheck, style }) => (
  <ButtonWrapper>
    <CheckboxButton styles={style}>
      <TextFont size={14}>Выбрать всех</TextFont>
      <Checkbox onChange={() => handleCheck(!isChecked)} isChecked={isChecked} style={{ marginLeft: 5, marginRight: -5 }} />
    </CheckboxButton>
  </ButtonWrapper>
);

const CheckboxButton = styled.button<{ styles?: CSSProperties }>`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  margin-bottom: 10;

  ${(props) => props.styles && { ...props.styles }},
`;

const ButtonWrapper = styled.div`
  background-color: ${Colors.LIGHT.background.main};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
`;
