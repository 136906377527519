import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { RootState } from "@store";
import { setGrouping } from "@store/businessTask";
import { selectUserPermissoins } from "@store/user";
import { ButtonMain, Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI, HeaderModal } from "@ui";

import { GroupingTabs, TypeGroupTab } from "./assets/constants";

interface IProps {
  isVisible: boolean;
  closeEvent: () => void;
  applyEvent: (callback?: () => void, proporties?: Partial<IFilter>) => void;
}

export const GroupingModal = ({ isVisible, closeEvent, applyEvent }: IProps) => {
  const dispatch = useDispatch();
  const { groupByType } = useSelector((state: RootState) => state.businessTasks.filter);

  const [localGrouped, setLocalGrouped] = useState(groupByType);

  const permissions = useSelector(selectUserPermissoins);

  const handleSave = () => {
    dispatch(setGrouping(localGrouped));
    applyEvent(closeEvent, { groupByType: localGrouped });
  };

  const isValidGroup = (group: TypeGroupTab) => {
    switch (group) {
      case "PROJECT": {
        return Boolean(permissions.WORK_WITH_SPACE_PROJECTS);
      }
      default: {
        return true;
      }
    }
  };

  return (
    <ModalUI isVisible={isVisible} onClose={closeEvent}>
      <ContentWrap>
        <div>
          <HeaderModal
            title="order"
            leftSide={{ onPressClose: closeEvent, title: "close", isHideCancel: true }}
            isEdit
            rightSide={{ title: "apply", onPress: handleSave }}
          />
          <BlockButtons>
            <TextFont type="bold" size={18} lineHeight={24}>
              <FormattedMessage id={"Сгруппировать"} />
            </TextFont>
            <Spacer px={10} />
            <LineButtons>
              {Object.keys(GroupingTabs).map((element, index) => (
                <RenderWithCondition key={element} condition={isValidGroup(GroupingTabs[element])}>
                  <ButtonGrouped
                    key={element + index}
                    onClick={() => setLocalGrouped(GroupingTabs[element])}
                    backColor={localGrouped === GroupingTabs[element]}
                  >
                    <TextFont>
                      <FormattedMessage id={element} />
                    </TextFont>
                  </ButtonGrouped>
                </RenderWithCondition>
              ))}
            </LineButtons>
          </BlockButtons>
        </div>
        <ButtonMainCustom onClick={handleSave}>
          <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
            <FormattedMessage id={"apply"} />
          </TextFont>
        </ButtonMainCustom>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const LineButtons = styled.div`
  ${boxCenterCss};
  justify-content: space-between;
  flex-wrap: wrap;
`;
const BlockButtons = styled.div`
  padding: 0 12px;
  margin-top: 40px;
`;
const ButtonGrouped = styled.button<{ backColor: boolean }>`
  ${boxCenterCss};
  height: 42px;
  width: 48.5%;
  padding: 0 10px;
  margin-bottom: 10px;
  background-color: ${Colors.LIGHT.lighGrey};
  border-radius: 5px;
  border: 1px solid ${Colors.LIGHT.lighGrey};
  border-color: ${({ backColor }) => (backColor ? `${Colors.LIGHT.text.accent}` : `${Colors.LIGHT.lighGrey}`)};
`;
const ButtonMainCustom = styled(ButtonMain)`
  margin: 10px 12px 20px 12px;
`;
