import React, { FC, useEffect, useCallback, useMemo, useState } from "react";

import FlatList from "flatlist-react/lib";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectExternalUsers } from "@store/externalUsers";
import { removeExternalUser, resetExternalUsers } from "@store/externalUsers/slice";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { isTablet } from "@utils/index";
import { isInnerMeetParticipant, isExternalUser as isExternalUserType } from "@utils/index";

import { ParticipantsList } from "../../../participants/participantsList/ParticipantsList";
import { ICommonFields } from "../../../viewNotSave/types";

import {
  AvatarWrap,
  BlockAvatar,
  BlockHeader,
  BlockText,
  ButtonDelete,
  ButtonItem,
  ContentItem,
  ListWrap,
  SvgOwnerStyled,
  TextOrdinal,
  TextUserName,
  TextWorkPosition,
} from "./styled";

const sizeIcon = isTablet ? 32 : 45;

interface IProps extends ICommonFields {
  data: IMeet;
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  search?: JSX.Element;
  isUserHasEditPermissions: boolean;
  participantsAttendance: IParticipantAttendance[] | null;
  deletableUserIds?: Set<string>;
  deletedExternalUsersIds?: string[];
}

export const Participants: FC<IProps> = ({
  handleData,
  data,
  participantLight,
  setParticipantLight,
  search,
  isUserHasEditPermissions = false,
  participantsAttendance,
  deletedExternalUsersIds,
}) => {
  let index = 0;
  const [isVisible, setIsVisible] = useState(false);
  const { currentUser, directorId } = userSelector();
  const { externalUsers } = useSelector(selectExternalUsers);
  const dispatch = useDispatch();

  const handleDeleteItem = (id: string, isExternalUser: boolean) => {
    if (!data.participants || !handleData) return;

    if (isExternalUser) {
      dispatch(removeExternalUser(id));

      data.externalUsers &&
        handleData(
          "externalUsers",
          [...data.externalUsers].filter((i) => i.id !== id),
        );

      return;
    }

    handleData(
      "participants",
      [...data.participants].filter((i) => i.userId !== id),
    );
  };

  const handleAddUser = (user: IStateUser, isExternalUser = false) => {
    if (isExternalUser) {
      handleData("externalUsers", [...(data?.externalUsers ?? []), { ...user, isExternalUser: true }]);
    } else {
      handleData("participants", [...(data?.participants ?? []), { ...user, status: null, userId: user.id, isExternalUser: false }]);
    }

    setIsVisible(false);
  };

  const getUser = useCallback(
    (userId: string) => {
      const participant = data.participants?.find((i) => i.userId === userId);
      return participant?.user ? participant?.user : (participant as unknown as IStateUser);
    },
    [data.participants],
  );

  const renderItem = (item: IMeetParticipants, i: number) => {
    const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
    const isInnerUser = isInnerMeetParticipant(item);
    const isExternalUser = isExternalUserType(item);

    const itemId = item?.user?.id ?? item.id;
    const participant = getUser(itemId) as IStateUser;
    const isMatchedIds = directorId ? true : currentUser?.id !== itemId;
    const isItemDeleteAllowed = isUserHasEditPermissions && item.status !== "OWNER" && isMatchedIds;
    const isDeleted = isExternalUser && deletedExternalUsersIds.some((id) => id === itemId);
    const isInnerBusy = isInnerUser ? item?.busy : false;
    const isBusy = search ? attendance?.meetings?.length : isInnerBusy;
    const letterAvatarStyle = { backgroundColor: Colors.LIGHT.lighGrey, width: sizeIcon, height: sizeIcon };
    const userAvatarStyle = {
      width: sizeIcon,
      height: sizeIcon,
      borderRadius: sizeIcon,
      marginRight: 8,
      border: `2px solid ${isBusy ? Colors.LIGHT.red : Colors.LIGHT.green}`,
    };

    return (
      <ButtonItem
        key={item.id ?? item.userId}
        isNotPointer={isExternalUser}
        isActive={participantLight === item.userId && !isExternalUser}
        onClick={() => !isExternalUser && setParticipantLight(item.userId)}
      >
        <ContentItem>
          <BlockAvatar>
            <TextOrdinal size={10} color={Colors.LIGHT.placeholder.main}>
              {item.status !== "OWNER" ? `${i}.` : " "}
            </TextOrdinal>
            <AvatarWrap>
              <RenderWithCondition condition={isExternalUser}>
                <LetterAvatar
                  userName={isExternalUser ? item?.firstName && item?.firstName[0]?.toUpperCase() : ""}
                  lastName={isExternalUser ? item?.lastName && item?.lastName[0]?.toUpperCase() : ""}
                  style={letterAvatarStyle}
                  colorText={Colors.LIGHT.white}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={!isExternalUser}>
                <UserAvatar avatarFileId={(participant && participant["avatarFileId"]) ?? null} style={userAvatarStyle} />
              </RenderWithCondition>
              <RenderWithCondition condition={item.status === "OWNER"}>
                <SvgOwnerStyled />
              </RenderWithCondition>
            </AvatarWrap>
          </BlockAvatar>
          <BlockText>
            <TextUserName type="bold" size={16} weight="700" isLineThrough={item?.deleted || isDeleted}>
              {isExternalUser ? `${item?.firstName} ${item?.lastName}` : `${participant?.lastName ?? ""} ${participant?.firstName ?? ""}`}
            </TextUserName>
            <TextWorkPosition size={14} color={Colors.LIGHT.placeholder.main}>
              {isExternalUser ? item?.email : participant?.workPosition ?? ""}
            </TextWorkPosition>
          </BlockText>
        </ContentItem>
        <RenderWithCondition condition={isItemDeleteAllowed}>
          <ButtonDelete
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteItem(isExternalUser ? item.id : item.userId, isExternalUser);
            }}
          >
            <SvgRemove width={12} height={12} fill={Colors.LIGHT.background.grey} />
          </ButtonDelete>
        </RenderWithCondition>
      </ButtonItem>
    );
  };

  const listData = useMemo(
    () => [...(data.participants ?? []), ...(data.externalUsers?.map((i) => ({ ...i, isExternalUser: true })) ?? [])],
    [data],
  );

  useEffect(() => {
    if (externalUsers.length === 0) {
      return;
    }

    if (data?.externalUsers && data?.externalUsers?.length !== 0) {
      for (const user of externalUsers) {
        for (const participant of data.externalUsers) {
          if (user.id === participant.id) {
            return;
          }

          handleAddUser(user, true);
        }
      }
    } else {
      for (const user of externalUsers) {
        handleAddUser(user, true);
      }
    }

    dispatch(resetExternalUsers());
  }, [data, externalUsers]);

  return (
    <>
      <BlockHeader>
        <TextFont size={18} weight="700" type="bold">
          <FormattedMessage id="meetingMembers" />
        </TextFont>
        <RenderWithCondition condition={!search}>
          <button onClick={() => setIsVisible(true)}>
            <SvgPlus width={16} height={16} fill={Colors.LIGHT.background.grey} />
          </button>
        </RenderWithCondition>
      </BlockHeader>

      {search}

      <RenderWithCondition condition={listData?.length}>
        <ListWrap>
          <FlatList list={listData} renderItem={(item: IMeetParticipants) => renderItem(item, index++)} />
        </ListWrap>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <ParticipantsList meet={data} onSave={handleAddUser} onClose={() => setIsVisible(false)} isMeet handleData={handleData} />
      </ModalRenderWithCondition>
    </>
  );
};
