import React, { memo } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import { RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces";
import { ITask } from "@interfaces/businessGoals.interface";
import { DelegatedList } from "@shared";
import { takeMe, getGroupedList } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { userSelector } from "@store/user";
import { Spacer } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { toFormatDate } from "@utils";

interface IModalDelegate {
  close: () => void;
  task: ITask;
  isModal?: boolean;
  onSave?: (arg: InterfaceUser.IStateUser) => void;
  delegatedToUser?: InterfaceUser.IStateUser | null;
  onTakeMe?: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const ModalDelegate = memo(
  ({ close, task, delegatedToUser, onSave, onTakeMe, isModal = false, isNeedCloseAfterSave = false }: IModalDelegate) => {
    const dispatch = useDispatch();
    const date = useSelector(selectCurrentDate);
    const { isLoading } = userSelector();

    const handleTakeMe = () => {
      const takeMeDispatchWrapper = async () => {
        await dispatch(takeMe(task.id));
        dispatch(getGroupedList({ date: toFormatDate(date), next: 5 }));
      };

      onTakeMe ? onTakeMe() : takeMeDispatchWrapper();
      close && close();
    };

    const renderName = (user: InterfaceUser.IStateUser | null | undefined) => `${user?.lastName} ${user?.firstName}`;

    return (
      <ModalUI isVisible={true} onClose={close}>
        <HeaderModal title="delegate" leftSide={{ isHideCancel: true, onPressClose: close }} />

        <RenderWithCondition condition={delegatedToUser && !isLoading}>
          <ContentWrap>
            <TextFont type="bold" size={16} weight="700" color={Colors.LIGHT.white}>
              Делегировано на:
            </TextFont>
            <Spacer px={4} />
            <BlockButtons>
              <DelegateWrap>
                <TextFont size={14}>{renderName(delegatedToUser)}</TextFont>
              </DelegateWrap>
              <DelegateWrap as="button" onClick={handleTakeMe}>
                <SvgTakeBack width={22} height={22} />
                <Spacer px={8} horizontal />
                <TextFont size={14} color={Colors.LIGHT.purple}>
                  <FormattedMessage id="takeBack" />
                </TextFont>
              </DelegateWrap>
            </BlockButtons>
          </ContentWrap>
        </RenderWithCondition>

        <DelegatedList task={task} close={close} onSave={onSave} isModal={isModal} isNeedCloseAfterSave={isNeedCloseAfterSave} />
      </ModalUI>
    );
  },
);

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 12px 12px 12px;
  background-color: ${Colors.LIGHT.background.darkGreen};
`;
const BlockButtons = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 10px;
`;
const DelegateWrap = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-radius: 45px;
  background-color: ${Colors.LIGHT.white};
  padding: 3px 9px;
  height: 34px;
  box-sizing: border-box;
`;
