import React, { FC, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { selectCalendar } from "@store/calendar";
import { CalendarViewModes, goToNextWeek, goToPreviousWeek } from "@store/calendar/slice";
import { ArrowSvg, TextFont } from "@ui";

interface IProps {
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
}

export const Header: FC<IProps> = ({ currentDate, setCurrentDate }) => {
  const { startWeekDay, viewMode } = useSelector(selectCalendar);
  const dispatch = useDispatch();

  const leftArrowPressHandler = () => {
    if (viewMode === CalendarViewModes.Month) {
      setCurrentDate(currentDate.subtract(1, "month"));
    }

    if (viewMode === CalendarViewModes.Week) {
      dispatch(goToPreviousWeek());
    }
  };

  const rightArrowPressHandler = () => {
    if (viewMode === CalendarViewModes.Month) {
      setCurrentDate(currentDate.add(1, "month"));
    }

    if (viewMode === CalendarViewModes.Week) {
      dispatch(goToNextWeek());
    }
  };

  const title = useMemo(() => {
    if (viewMode === CalendarViewModes.Month) {
      const monthTitle = dayjs.months()[currentDate.month()];
      return monthTitle.charAt(0).toUpperCase() + monthTitle.slice(1);
    }

    if (viewMode === CalendarViewModes.Week) {
      const formatOption = startWeekDay.add(1, "week").year() === dayjs().year() ? "DD MMM" : "DD MMM YYYY";

      const startDate = startWeekDay.locale("ru").format(formatOption);
      const endDate = startWeekDay.endOf("week").locale("ru").format(formatOption);

      return `${startDate} — ${endDate}`;
    }
  }, [viewMode, currentDate, startWeekDay]);

  return (
    <StContainerDiv>
      <StBtn onClick={leftArrowPressHandler}>
        <ArrowSvg type="left" fill="#5F6E67" width={24} height={24} />
      </StBtn>

      <TextFont type="bold" size={16} weight="700" style={{ textTransform: "capitalize" }}>
        {title}
      </TextFont>

      <StBtn onClick={rightArrowPressHandler}>
        <ArrowSvg type="right" fill="#5F6E67" width={24} height={24} />
      </StBtn>
    </StContainerDiv>
  );
};

const StContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 10px;
`;

const StBtn = styled.button`
  display: flex;
  align-items: center;
`;
