import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { isObject } from "lodash";

import { TabIds } from "@components/screens/day/components/tabs/types";
import { resetStore } from "@constants/logic";
import { IEventOfDaySimplified, IEventsOfDay, IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IUnansweredMeetList, TTypeParticipants } from "@interfaces/meet.interface";
import { deleteSearchParams } from "@utils";

interface IEventToReopen {
  id: string;
  type: "MEET" | "TASK";
  repeat: boolean;
  date?: string;
}

export interface IMeetingsEvents {
  startTime: string;
  endTime: string;
  status: "OCCUPIED" | "FREE";
}

interface IInitialState {
  isLoadingEvent: boolean;
  isLoading: boolean;
  isOpenModalMeet: boolean;
  errorMsg: null | string;
  events: IEventsOfDay[];
  eventFromLink: IEventsOfDay;
  date: null | Dayjs;
  startDate: Dayjs;
  currentId: string;
  currentEvent: IMeet | null;
  joinData: Partial<IMeet> | null;
  attendance: IParticipantAttendance[] | null;
  participantsAttendance: IParticipantAttendance[] | null;
  commonSlot: { startTime: string; endTime: string } | null;
  eventToReopen: IEventToReopen | null;
  availablePlaces: string[] | null;
  activeTabId: TabIds;
  openContextData: IEventOfDaySimplified | null;
  unansweredCount: number;
  unansweredMeets: IUnansweredMeetList[];
  currentEventContext: IMeet | null;
  categoryModalEventId: string | null;
}

const initialState: IInitialState = {
  isLoadingEvent: false,
  isLoading: false,
  isOpenModalMeet: false,
  errorMsg: null,
  events: [],
  eventFromLink: null,
  date: dayjs(), // выбранный день
  startDate: dayjs(), // сегодняшний день
  currentId: "",
  attendance: null,
  currentEvent: null,
  joinData: null,
  participantsAttendance: null,
  commonSlot: null,
  eventToReopen: null,
  availablePlaces: null,
  activeTabId: "tasks",
  openContextData: null,
  unansweredCount: 0,
  unansweredMeets: [],
  currentEventContext: null,
  categoryModalEventId: null,
};

const ScreenDaySlice = createSlice({
  name: "screenDay",
  initialState,
  reducers: {
    editMonth: (state, action: PayloadAction<"plus" | "minus">) => {
      state.date = state.date.add(action.payload === "plus" ? +1 : -1, "month");
    },
    saveDate: (state, action: PayloadAction<Dayjs>) => {
      state.date = action.payload;
    },
    updateId: (state, action: PayloadAction<{ id: string; modalOpen: boolean }>) => {
      state.currentId = action.payload.id;
      state.isOpenModalMeet = action.payload.modalOpen;

      if (!action.payload.id) {
        state.currentEvent = null;

        deleteSearchParams(["meeting"]);
        if (!action.payload.modalOpen) {
          state.joinData = null;
        }
      }
    },
    setJoinData: (state, action: PayloadAction<Partial<IMeet>>) => {
      state.joinData = action.payload;
    },
    setEventToReopen: (state, action: PayloadAction<IEventToReopen | null>) => {
      state.eventToReopen = action.payload;
    },
    updateStartDate: (state) => {
      state.startDate = dayjs();
    },
    clearCurrentEvent: (state) => {
      state.currentEvent = null;
    },
    attachTasks: (state, action: PayloadAction<{ id: string; attachmentIds: string[] }>) => {
      for (const event of state.events) {
        if (event.id === action.payload.id && event.type === "MEETING") {
          event.attachedTaskIds = action.payload.attachmentIds;
        }
      }
    },
    filterEventsById: (state, action: PayloadAction<string>) => {
      state.events = state.events.filter((event) => event.id !== action.payload);
    },
    updateMeetAttendanceStatus: (state, action: PayloadAction<{ meetId: string; statusToSet: TTypeParticipants }>) => {
      const { meetId, statusToSet } = action.payload;
      const eventToUpdate = state.events.find((event) => event.id === meetId);

      if (eventToUpdate) {
        eventToUpdate.currentParticipantStatus = statusToSet;
      }
    },
    setActiveTabId: (state, action: PayloadAction<TabIds>) => {
      state.activeTabId = action.payload;
    },
    setOpenContextData: (state, action: PayloadAction<any>) => {
      state.openContextData = action.payload;
    },
    setCurrentEvent: (state, action: PayloadAction<IMeet | null>) => {
      state.currentEvent = action.payload;
    },
    setCurrentEventContext: (state, action: PayloadAction<IMeet | null>) => {
      state.currentEventContext = action.payload;
    },
    setCategoryModalEventId: (state, action: PayloadAction<string | null>) => {
      state.categoryModalEventId = action.payload;
    },

    /* Костыль. В текущей реализации невозможно патчить кэш встречи напрямую */
    updateCurrentEvent: (
      state,
      action: PayloadAction<{ updation: Partial<IMeet>; meta?: Partial<{ participantId: string; comment: string }> }>,
    ) => {
      const { updation, meta } = action.payload;
      const metaParams = meta && isObject(meta) ? Object.keys(meta) : [];

      let participants = state.currentEvent?.participants;

      if (metaParams?.includes("comment") && metaParams?.includes("participantId")) {
        participants = state.currentEvent?.participants?.map((item) => {
          if (item.userId === meta?.participantId) {
            return Object.assign({ ...item }, { status: updation?.currentParticipantStatus, statusComment: meta?.comment });
          }

          return item;
        });
      }

      state.currentEvent = Object.assign({ ...state.currentEvent }, { ...action.payload.updation, participants }) as IMeet;
    },

    /* Костыль. Метод для патча статусов участников, т.к. в ртк апи нельзя(?) получить
     * доступ к стейту для чтений текущего значения currentEvent.
     */
    markParticipantStatusAsViewed: (state, action: PayloadAction<{ userId: string }>) => {
      if (state?.currentEvent && state?.currentEvent?.participants) {
        state.currentEvent.participants = state.currentEvent.participants.map((i) => {
          if (i.userId === action.payload.userId) return { ...i, statusCommentIsViewed: true };
          return i;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => initialState);
  },
});

export const {
  editMonth,
  saveDate,
  updateId,
  updateStartDate,
  attachTasks,
  filterEventsById,
  updateMeetAttendanceStatus,
  setEventToReopen,
  setActiveTabId,
  setJoinData,
  setOpenContextData,
  clearCurrentEvent,
  setCurrentEvent,
  updateCurrentEvent,
  markParticipantStatusAsViewed,
  setCurrentEventContext,
  setCategoryModalEventId,
} = ScreenDaySlice.actions;
export default ScreenDaySlice.reducer;
