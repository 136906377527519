import React from "react";

import styled from "styled-components";

import { ReactComponent as SvgMeet } from "@assets/meet.svg";
import { ReactComponent as SvgPeople } from "@assets/people.svg";
import { TEventType } from "@interfaces/eventsOfDay.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const HEIGHT_ICON = 13;

type TConfigEvent = {
  backgroundColor: string;
  textColor: string;
  icon: JSX.Element;
};

export const configEvent = (height?: number, iconFillColor?: string, hasParticipants?: boolean): Record<TEventType, TConfigEvent> => ({
  TASK: {
    backgroundColor: Colors.LIGHT.background.green100,
    textColor: Colors.LIGHT.text.accent,
    icon: <TextFont type="bold" color={iconFillColor ?? Colors.LIGHT.text.accent} size={12} weight="700"></TextFont>,
  },
  MEETING: {
    backgroundColor: Colors.LIGHT.background.blue100,
    textColor: Colors.LIGHT.blue,
    icon: (
      <ContentWrap>
        {hasParticipants ? (
          <SvgPeople height={height} width={12} fill={iconFillColor ?? Colors.DARK.blue} />
        ) : (
          <SvgMeet height={height} width={12} fill={iconFillColor ?? Colors.DARK.blue} />
        )}
      </ContentWrap>
    ),
  },
});

const ContentWrap = styled.div`
  display: flex;
  align-items: flex-end;
`;
