import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { IEventOfDaySimplified } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants, TTypeParticipants } from "@interfaces/meet.interface";
import emitter from "@services/emitter";
import {
  useDeleteMeetingSerieMutation,
  useDeleteSingleOrExceptionMeetingMutation,
  useRemoveFromSheduleMutation,
  useRemoveMeetingStatusMutation,
  useSetParticipantStatusMutation,
  useUpdateMeetingStatusMutation,
  useUpdateSingleOrSerieMeetingMutation,
} from "@services/meetApi";
import { selectAttachedEvents } from "@store/attachedMeetingTasks/selectors";
import { clone, complete, deleteTask, reopen } from "@store/businessTask";
import { selectCalendar } from "@store/calendar";
import { selectScreenDay } from "@store/screenDay";
import {
  clearCurrentEvent,
  setCurrentEventContext,
  setJoinData,
  setOpenContextData,
  setCategoryModalEventId,
} from "@store/screenDay/slice";
import { userSelector } from "@store/user";
import { IButton, KeysButtons } from "@ui/taskActions";
import { toFormatDate } from "@utils/toFormatTime";

export type TKeyButtonsContextMenu =
  | "handleViewEvent"
  | "removeStatus_not_take_place"
  | "updateMeetingsList"
  | "removeFromSchedule"
  | "newMeetThisParticipants"
  | "did_not_take_place"
  | "will_not_take_place"
  | "set_category"
  | "handleDelete"
  | "copyTask"
  | "deleteTask"
  | "reopenTask"
  | "clearCurrentEvent"
  | "clearCurrentEventContext"
  | "handleCancel"
  | "completeTask";

const useConfigContext = (event: IEventOfDaySimplified, handlePress: () => void, setVisibleComment: (value: string | null) => void) => {
  const [statusToSet, setStatusToSet] = useState<TTypeParticipants>("OWNER");

  const [deleteSingleOrExceptionMeeting] = useDeleteSingleOrExceptionMeetingMutation();
  const [deleteMeetingSerie] = useDeleteMeetingSerieMutation();
  const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();
  const [updateMeetingStatus] = useUpdateMeetingStatusMutation();
  const [removeMeetingStatus] = useRemoveMeetingStatusMutation();
  const [removeFromShedule] = useRemoveFromSheduleMutation();
  const [setParticipantStatus] = useSetParticipantStatusMutation();

  const dispatch = useDispatch();
  const { currentEventContext: currentEvent } = useSelector(selectScreenDay);
  const { contextTask } = useSelector(selectAttachedEvents);
  const { periodToLoadCalendar } = useSelector(selectCalendar);
  const { currentUser } = userSelector();

  const usersWithOutDeleted = useMemo(() => {
    if (!currentEvent?.externalUsers) {
      return [];
    }
    return [...currentEvent.externalUsers].filter((user) => !user.deleted);
  }, [currentEvent]);

  const contextConfig = useMemo((): Partial<Record<KeysButtons, IButton>> => {
    function handleNewThisParticipants() {
      let reorderList = false;

      const initialParticipantsWithInviteRights =
        currentEvent?.participants?.map((item) => {
          let fixedStatus: TTypeParticipants | null = item.status === "OWNER" ? item.status : null;

          if (!event?.createdByCurrentUser && currentUser?.id) {
            fixedStatus = item.userId === currentUser.id ? "OWNER" : null;
            reorderList = true;
          }

          return {
            ...item,
            permissions: ["ADD_PARTICIPANTS"],
            status: fixedStatus,
            statusComment: null,
          } as unknown as IMeetParticipants;
        }) ?? [];

      let participantsList = initialParticipantsWithInviteRights;

      if (reorderList) {
        participantsList = [];
        initialParticipantsWithInviteRights.forEach((item) =>
          item.status === "OWNER" ? participantsList.unshift(item) : participantsList.push(item),
        );
      }
      const usersWithOutFired = [...participantsList].filter((item) => item.user.active);

      setTimeout(() => dispatch(setJoinData({ participants: usersWithOutFired, externalUsers: usersWithOutDeleted })), 500);
    }

    function handleDelete() {
      if (event.repeat) {
        deleteMeetingSerie({
          id: event?.id,
          date: toFormatDate(dayjs(event.startTime)),
          calendarPeriodStart: periodToLoadCalendar?.startDate,
          calendarPeriodEnd: periodToLoadCalendar?.endDate,
        });
      } else {
        deleteSingleOrExceptionMeeting({
          id: event?.id,
          date: toFormatDate(dayjs(event.startTime)),
          isException: false,
          calendarPeriodStart: periodToLoadCalendar?.startDate,
          calendarPeriodEnd: periodToLoadCalendar?.endDate,
        });
      }
    }

    function handleCancel() {
      updateSingleMeetOrSerie({
        id: currentEvent?.parentEvent ? currentEvent?.parentEvent?.id : currentEvent?.id,
        data: { active: false },
        date: toFormatDate(dayjs(currentEvent?.startTime)),
        repeat: Boolean(event?.repeat),
        changeSerie: false,
        parentEvent: currentEvent?.parentEvent,
        isMouseRightClick: true,
        period: periodToLoadCalendar,
        startTime: currentEvent?.startTime,
      });
    }

    function pressContextMenuActions(value?: TKeyButtonsContextMenu, notClose?: boolean) {
      const handleActions: Record<TKeyButtonsContextMenu, () => void> = {
        handleViewEvent: () => handlePress(),
        removeStatus_not_take_place: () =>
          removeMeetingStatus({ id: event?.id, date: toFormatDate(dayjs(event.startTime)), period: periodToLoadCalendar }),
        updateMeetingsList: () => emitter.emit("updateMeetings"),
        newMeetThisParticipants: () => handleNewThisParticipants(),
        removeFromSchedule: () =>
          removeFromShedule({
            id: event.id,
            date: toFormatDate(dayjs(event.startTime)),
            repeat: !!event.repeat,
            period: periodToLoadCalendar,
            startTime: event.startTime,
          }),
        did_not_take_place: () =>
          updateMeetingStatus({
            id: event?.id,
            date: toFormatDate(dayjs(event.startTime)),
            type: "DID_NOT_TAKE_PLACE",
            period: periodToLoadCalendar,
          }),
        will_not_take_place: () =>
          updateMeetingStatus({
            id: event?.id,
            date: toFormatDate(dayjs(event.startTime)),
            type: "WILL_NOT_TAKE_PLACE",
            period: periodToLoadCalendar,
          }),
        set_category: () => dispatch(setCategoryModalEventId(event.id)),
        handleDelete: () => handleDelete(),
        handleCancel: () => handleCancel(),
        clearCurrentEvent: () => dispatch(clearCurrentEvent()),
        clearCurrentEventContext: () => dispatch(setCurrentEventContext(null)),
        copyTask: () => dispatch(clone({ id: contextTask?.id, isPersonalTask: false })),
        deleteTask: () => dispatch(deleteTask({ id: contextTask?.id, isPersonalTask: false })),
        reopenTask: () => dispatch(reopen({ id: contextTask?.id, isPersonalTask: false })),
        completeTask: () => dispatch(complete({ id: contextTask?.id, isPersonalTask: false })),
      };
      !notClose && dispatch(setOpenContextData(null));
      !notClose && dispatch(clearCurrentEvent());
      !notClose && emitter.emit("clearToggle");

      return handleActions[value as TKeyButtonsContextMenu];
    }

    if (event.type === "MEETING") {
      const totalParticipants = event.numberOfParticipants;
      const isMeetPastWithoutParticipants = dayjs(event?.endTime).isBefore(dayjs()) && totalParticipants === 1;
      const isMeetFutureWithoutParticipants = dayjs(event?.endTime).isAfter(dayjs()) && totalParticipants === 1;
      const isMeetPastWithParticipants = dayjs(event?.endTime).isBefore(dayjs()) && totalParticipants > 1;
      const isMeetFutureWithParticipants = dayjs(event?.endTime).isAfter(dayjs()) && totalParticipants > 1;
      const isNotTookPlace = event.currentParticipantMeetingStatus === "DID_NOT_TAKE_PLACE";
      const isWillNotTakePlace = event.currentParticipantMeetingStatus === "WILL_NOT_TAKE_PLACE";
      const isOwner = event?.currentParticipantStatus === "OWNER";
      const isAttender = event?.currentParticipantStatus === "ATTENDER";
      const isNonAttender = event?.currentParticipantStatus === "NON_ATTENDER";
      const isMaybeAttender = event?.currentParticipantStatus === "MAYBE_ATTENDER";
      const meetWithTypeAll = !isEmpty(event.presetGroupIds);

      const set_category = event?.currentParticipantStatus &&
        !isNonAttender && {
          SET_CATEGORY: {
            onClick: () => {
              pressContextMenuActions("set_category")();
            },
            message: event.userCategory?.description || "setCategory",
            color: event.userCategory?.color,
          },
        };

      const actionsForNotTookPlaceWithParticipants = {
        view: {
          onClick: () => {
            pressContextMenuActions("handleViewEvent")();
          },
        },
        cancelPastStatus: {
          onClick: () => {
            pressContextMenuActions("removeStatus_not_take_place")();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
        ...set_category,
        addNewMeet: {
          onClick: () => {
            pressContextMenuActions("newMeetThisParticipants", true)();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
      };

      const actionsForNotTookPlaceWithOutParticipants = {
        view: {
          onClick: () => {
            pressContextMenuActions("handleViewEvent")();
          },
        },
        ...set_category,
        cancelPastStatus: {
          onClick: () => {
            pressContextMenuActions("removeStatus_not_take_place")();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
      };

      const actionsForWillNotTakePlaceWithParticipants = {
        view: {
          onClick: () => {
            pressContextMenuActions("handleViewEvent")();
          },
        },
        cancelFutureStatus: {
          onClick: () => {
            pressContextMenuActions("removeStatus_not_take_place")();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
        ...set_category,
        addNewMeet: {
          onClick: () => {
            pressContextMenuActions("newMeetThisParticipants", true)();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
      };

      const actionsForWillNotTakePlaceWithOutParticipants = {
        view: {
          onClick: () => {
            pressContextMenuActions("handleViewEvent")();
          },
        },
        ...set_category,
        cancelFutureStatus: {
          onClick: () => {
            pressContextMenuActions("removeStatus_not_take_place")();
            pressContextMenuActions("clearCurrentEventContext")();
          },
        },
      };

      if (!isOwner && !event?.active) {
        return {
          addNewMeet: {
            onClick: () => {
              pressContextMenuActions("newMeetThisParticipants", true)();
              pressContextMenuActions("clearCurrentEventContext")();
            },
          },
          ...(!isOwner && {
            removeCal: {
              onClick: () => {
                pressContextMenuActions("removeFromSchedule")();
                pressContextMenuActions("clearCurrentEventContext")();
              },
            },
          }),
        };
      }

      if (isNotTookPlace && totalParticipants > 1) {
        return actionsForNotTookPlaceWithParticipants;
      }
      if (isNotTookPlace && totalParticipants === 1) {
        return actionsForNotTookPlaceWithOutParticipants;
      }
      if (isWillNotTakePlace && totalParticipants > 1) {
        return actionsForWillNotTakePlaceWithParticipants;
      }
      if (isWillNotTakePlace && totalParticipants === 1) {
        return actionsForWillNotTakePlaceWithOutParticipants;
      }

      if (isMeetPastWithoutParticipants) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          ...(!(meetWithTypeAll && !isOwner) && {
            notTookPlace: {
              onClick: () => {
                pressContextMenuActions("did_not_take_place")();
              },
            },
          }),
          ...(!meetWithTypeAll && {
            remove: {
              onClick: () => {
                pressContextMenuActions("handleDelete")();
                pressContextMenuActions("clearCurrentEvent")();
                pressContextMenuActions("clearCurrentEventContext")();
              },
            },
          }),
          ...set_category,
        };
      }

      if (isMeetPastWithParticipants) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          ...(!(meetWithTypeAll && !isOwner) && {
            notTookPlace: {
              onClick: () => {
                pressContextMenuActions("did_not_take_place")();
              },
            },
          }),
          ...(!meetWithTypeAll && {
            addNewMeet: {
              onClick: () => {
                pressContextMenuActions("newMeetThisParticipants", true)();
                pressContextMenuActions("clearCurrentEventContext")();
              },
            },
          }),
          ...set_category,
        };
      }
      if (isMeetFutureWithoutParticipants) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          ...(!(meetWithTypeAll && !isOwner) && {
            statusTakePlaceNo: {
              onClick: () => {
                pressContextMenuActions("will_not_take_place")();
              },
            },
          }),
          ...(meetWithTypeAll &&
            isOwner && {
              cancel: {
                onClick: () => {
                  pressContextMenuActions("handleCancel")();
                  pressContextMenuActions("clearCurrentEvent")();
                  pressContextMenuActions("clearCurrentEventContext")();
                },
              },
            }),
          ...set_category,
        };
      }
      if (isMeetFutureWithParticipants) {
        return {
          ...(event?.active && {
            view: {
              onClick: () => {
                pressContextMenuActions("handleViewEvent")();
              },
            },
          }),
          ...(event?.active && {
            statusTakePlaceNo: {
              onClick: () => {
                pressContextMenuActions("will_not_take_place")();
              },
            },
          }),
          ...set_category,
          addNewMeet: {
            onClick: () => {
              pressContextMenuActions("newMeetThisParticipants", true)();
              pressContextMenuActions("clearCurrentEventContext")();
            },
          },
          ...(isOwner && {
            cancel: {
              onClick: () => {
                pressContextMenuActions("handleCancel")();
                pressContextMenuActions("clearCurrentEvent")();
                pressContextMenuActions("clearCurrentEventContext")();
              },
            },
          }),
          ...(!isOwner &&
            event?.active && {
              attender: {
                onClick: () => {
                  if (event.repeat && event.currentParticipantStatus) {
                    setStatusToSet("ATTENDER"), setVisibleComment("status");
                  } else {
                    setParticipantStatus({
                      id: event?.id,
                      status: "ATTENDER",
                      date: null,
                      comment: "",
                      participantId: "",
                      selectedDay: toFormatDate(dayjs(event.startTime)),
                      period: periodToLoadCalendar,
                    });
                    pressContextMenuActions();
                  }
                },
                ...(isAttender && { disabled: true, toast: "statusAlreadySet" }),
              },
              nonAttender: {
                onClick: () => {
                  setStatusToSet("NON_ATTENDER");
                  setVisibleComment("status");
                },
                ...(isNonAttender && { disabled: true, toast: "statusAlreadySet" }),
              },
              maybeAttender: {
                onClick: () => {
                  setStatusToSet("MAYBE_ATTENDER");
                  setVisibleComment("status");
                },
                ...(isMaybeAttender && { disabled: true, toast: "statusAlreadySet" }),
              },
            }),
        };
      }
      if (!currentEvent) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
        };
      }
    }

    if (event.type === "TASK") {
      const isDelegatedTaskStatus = event.taskDelegatedToMe || event.name.includes("Контроль:");
      const isOpenTaskStatus = event.taskStatus === "OPEN" && !isDelegatedTaskStatus && event.type === "TASK";
      const isCompletedTaskStatus = event.taskStatus === "COMPLETED" && !isDelegatedTaskStatus && event.type === "TASK";
      const isClosedTaskStatus = event.taskStatus === "CLOSED" && !isDelegatedTaskStatus && event.type === "TASK";
      const isPausedTaskStatus = event.taskStatus === "PAUSED" && !isDelegatedTaskStatus && event.type === "TASK";

      if (contextTask?.isFromOneS) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
        };
      }
      if (isOpenTaskStatus) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          delegateCalendar: { onClick: () => setVisibleComment("delegate") },
          closeCalendar: { onClick: () => setVisibleComment("close") },
        };
      }
      if (isCompletedTaskStatus) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          copyCalendar: {
            onClick: () => {
              pressContextMenuActions("copyTask")();
            },
          },
          deleteCalendar: {
            onClick: () => {
              pressContextMenuActions("deleteTask")();
            },
          },
        };
      }
      if (isClosedTaskStatus || isPausedTaskStatus) {
        return {
          view: {
            onClick: () => {
              pressContextMenuActions("handleViewEvent")();
            },
          },
          copyCalendar: {
            onClick: () => {
              pressContextMenuActions("copyTask")();
            },
          },
          returnToWorkCalendar: {
            onClick: () => {
              pressContextMenuActions("reopenTask")();
            },
          },
        };
      }
      if (isDelegatedTaskStatus) {
        return {
          complete: {
            onClick: () => {
              pressContextMenuActions("completeTask")();
            },
          },
          closeCalendar: { onClick: () => setVisibleComment("close") },

          deleteCalendar: {
            onClick: () => {
              pressContextMenuActions("deleteTask")();
            },
          },
        };
      }
    }
    return {
      view: {
        onClick: () => {
          pressContextMenuActions("handleViewEvent")();
        },
      },
    };
  }, [event, currentEvent, handlePress, contextTask, setVisibleComment]);

  return { contextConfig, statusToSet };
};
export default useConfigContext;
