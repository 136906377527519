import React, { FC } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { TextFont, ModalUI, HeaderModal } from "@ui";

interface IProps {
  comment: string;
  date: string;
  close: () => void;
}

export const Comment: FC<IProps> = ({ comment, date, close }) => (
  <ModalUI isVisible onClose={close}>
    <HeaderModal title="comment" leftSide={{ onPressClose: close }} />

    <ContentWrap>
      <TextFont size={18} weight="700" type="bold">
        {dayjs(date).format("DD MMMM")}
      </TextFont>
      <TextFont>{comment}</TextFont>
    </ContentWrap>
  </ModalUI>
);

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  gap: 9px;
`;
