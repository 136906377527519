import styled from "styled-components";

import { TextFont } from "@components/ui";
import { Colors } from "@theme/colors";

export const IconWrap = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const ButtonItem = styled.button<{ height: number; isModalList?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 95% 5%;
  padding-left: 8px;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.white};
  height: ${({ height }) => height}px;
  border-bottom: ${({ isModalList }) => isModalList && `1px solid ${Colors.LIGHT.lighGrey}`};
`;
export const AllGroupItem = styled(ButtonItem)`
  align-items: center;
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ContentWrap = styled.div`
  display: flex;
  width: 93%;
  height: 100%;
`;
export const BlockAvatars = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const IconStatusWrap = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0;
`;
export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
`;
export const BlockIconRemove = styled.div`
  display: flex;
  width: 7%;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 20px;
`;
export const ToastWrap = styled.div`
  padding-top: 8px;
  padding-right: 6px;
`;
export const TooltipWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0 17px;
`;

export const TextTitleMeet = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;

export const TextUserName = styled(TextFont)<{ isLineThrough: boolean }>`
  margin-bottom: 0;

  ${({ isLineThrough }) =>
    isLineThrough &&
    `
    text-decoration: line-through;
  `};
`;
export const TextUserWorkPosition = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
`;
