import React, { FC, useEffect, memo, useRef, useState, MouseEvent, useCallback } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { LoaderRenderWithCondition } from "@hoc";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import emitter from "@services/emitter";
import { useGetMeetingByIdQuery } from "@services/meetApi";
import { addContextTask } from "@store/attachedMeetingTasks/slice";
import { get as getTask } from "@store/businessTask";
import { selectConfigure } from "@store/configure";
import { selectCurrentDate, selectScreenDay } from "@store/screenDay";
import { clearCurrentEvent, setCurrentEventContext, setOpenContextData } from "@store/screenDay/slice";
import { getList, selectStoreTag } from "@store/tag";
import { arePropsEqual } from "@utils/arePropsEqual";
import { toFormatDate } from "@utils/toFormatTime";

import { DEVIATION, HOURTOSCROLL, arrHours, heightBlockEvent } from "./constants";
import { Row, Event } from "./row";

interface IMain {
  eventsOfDay: (IEventOfDaySimplified | IEventOfDaySimplified[])[];
  isFetching: boolean;
  handlePress: (arg: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => void;
  currentTime: Dayjs;
}

export const Main: FC<IMain> = ({ isFetching, eventsOfDay, handlePress, currentTime }) => {
  const refView = useRef(null);
  const toggleRefOpenContext = useRef("");

  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [paramsContext, setParamsContext] = useState(null);
  const [disableScroll, setDisableScroll] = useState(false);

  const selectedDay = useSelector(selectCurrentDate);
  const { isLoading } = useSelector(selectScreenDay);
  const { tags } = useSelector(selectStoreTag);
  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const { openContextData } = useSelector(selectScreenDay);

  const { data: currentEventContext } = useGetMeetingByIdQuery(paramsContext, {
    skip: !paramsContext,
  });

  function handleScroll() {
    if (openContextData) {
      dispatch(setOpenContextData(null));
    }
  }

  const scrollToHour = useCallback(() => {
    refView?.current?.scrollTo({
      left: 0,
      top: HOURTOSCROLL * 38,
      behavior: "smooth",
    });

    setDisableScroll(true);
  }, [refView]);

  useEffect(() => {
    setDisableScroll(false);
    scrollToHour();
  }, [selectedDay, isLoading, isFetching]);

  useEffect(() => {
    if (!disableScroll) {
      scrollToHour();
    }
  }, [refView, eventsOfDay, isLoading]);

  useEffect(() => {
    if (!tags.length) {
      dispatch(getList(""));
    }
  }, []);

  useEffect(() => {
    const block = document.getElementById("timetable");
    setWidth(block.clientWidth - DEVIATION);
  }, [window.innerWidth]);

  useEffect(() => {
    if (currentEventContext && paramsContext) {
      dispatch(setCurrentEventContext(currentEventContext));
    }
  }, [currentEventContext, paramsContext]);

  const handleCloseModal = useCallback(() => {
    if (position.top) {
      setPosition({ left: 0, top: 0 });
    }
  }, [position]);

  useEffect(() => {
    document.removeEventListener("click", handleCloseModal);
    document.addEventListener("click", handleCloseModal);
    return () => document.removeEventListener("click", handleCloseModal);
  }, [handleCloseModal]);

  useEffect(() => {
    if (!openContextData) {
      toggleRefOpenContext.current = "";
      dispatch(setCurrentEventContext(null));
      setParamsContext(null);
    }
  }, [openContextData]);

  const renderEvents = (hour: number) => {
    const filteredEvents = eventsOfDay;

    if (!filteredEvents) return null;

    async function mouseRightClick(e: MouseEvent<HTMLButtonElement>, data: IEventOfDaySimplified) {
      e.preventDefault();
      setPosition({ top: e.clientY, left: e.clientX });

      if (toggleRefOpenContext.current === data.id) {
        toggleRefOpenContext.current = "";
        dispatch(setOpenContextData(null));
        return;
      }
      toggleRefOpenContext.current = data.id;
      dispatch(setOpenContextData(data));

      emitter.emit("closeOthersContextMenu");

      if (data.type === "MEETING") {
        dispatch(addContextTask(null));
        setParamsContext({
          id: data.id,
          repeat: Boolean(data.repeat),
          currentDate: toFormatDate(dayjs(data.startTime)),
          ignoreListenerMiddleware: true,
        });
      }

      if (data.type === "TASK") {
        const response = await dispatch(getTask({ id: data.taskId, isPersonalTask: false }));
        const dataResp = response?.payload?.response?.data;
        dispatch(clearCurrentEvent());
        dataResp && dispatch(addContextTask(dataResp));
      }
    }

    return filteredEvents?.map((event, index) => {
      if (Array.isArray(event)) {
        return (
          <>
            {event.map((i: any) => (
              <Event
                key={i.id}
                hour={hour}
                press={handlePress}
                heightBlock={heightBlockEvent}
                index={index}
                startHour={arrHours[0]}
                columns={i.columns}
                width={width}
                mouseRightClick={mouseRightClick}
                position={position}
                {...i}
              />
            ))}
          </>
        );
      }
      return (
        <Event
          key={event.id}
          width={width}
          heightBlock={heightBlockEvent}
          hour={hour}
          press={handlePress}
          index={index}
          startHour={arrHours[0]}
          mouseRightClick={mouseRightClick}
          position={position}
          {...event}
        />
      );
    });
  };

  return (
    <ContentWrap isTightMode={!!isTightMode} onScroll={handleScroll} ref={refView}>
      <LoaderRenderWithCondition condition={isFetching}>
        <>
          <Content>
            {arrHours.map((hour, index) => (
              <RowWrap key={index}>
                <Row key={index} width={width} press={handlePress} hour={hour} currentTime={currentTime} />
                {renderEvents(hour)}
              </RowWrap>
            ))}
          </Content>
        </>
      </LoaderRenderWithCondition>
    </ContentWrap>
  );
};

export default memo(Main, arePropsEqual);

const ContentWrap = styled.div<{ isTightMode: boolean }>`
  display: flex;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: ${({ isTightMode }) => (isTightMode ? "100%" : "88%")};
`;
const Content = styled.div`
  padding-top: 20px;
  width: 100%;
`;
const RowWrap = styled.div`
  position: relative;
`;
