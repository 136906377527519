import React, { memo, useEffect, useState, useMemo, useRef, useCallback } from "react";

import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ArrowDownSvg } from "@assets/arrow-down.svg";
import { ReactComponent as SvgSearchGreen } from "@assets/search-green.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { Modal } from "@screens/day/components/timetable/main/modal/Modal";
import { NotFound } from "@screens/task/components/notFound";
import { IAttachEventMeta } from "@services/meet.service";
import { getMeetingsSeries } from "@store/attachedMeetingTasks/api";
import { getSelectedItemsIds, selectAttachedEvents, selectMeetingsSeries } from "@store/attachedMeetingTasks/selectors";
import { removeSelectedItemId, removeSelectedMeetingById, resetMeetingsSeries } from "@store/attachedMeetingTasks/slice";
import { selectScreenDay } from "@store/screenDay";
import { getList, selectStoreTag } from "@store/tag";
import { ButtonDrop, InputSearch, SearchWrap } from "@styles/styles";
import { Colors } from "@theme/colors";
import { EmptyBusinessTasks } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { TextFont } from "@ui/TextFont";
import EventHelperUtils from "@utils/event-helper.utills";

import { EventItem } from "./EventItem";

const eventHelperUtils = new EventHelperUtils();

interface IModalTag {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  onItemDelete: (id: string) => void;
  handleAttachItems: (itemsEntities: IMeet[], eventsMeta: IAttachEventMeta[]) => void;
}

interface ISection {
  data: IMeet[];
  day: string;
}

export const ModalMeetingAttach = memo(({ isVisible, onItemDelete, setIsVisible, handleAttachItems }: IModalTag) => {
  const dispatch = useDispatch();
  const selectedTaskIds = useSelector(getSelectedItemsIds);
  const { tags } = useSelector(selectStoreTag);
  const meetingsSeries = useSelector(selectMeetingsSeries);
  const selectedItemsIds = useSelector(getSelectedItemsIds);
  const { isLoading, errorMsg } = useSelector(selectAttachedEvents);
  const { isLoading: isLoadingMeeting } = useSelector(selectScreenDay);
  const newlySelectedEvents = useRef<IAttachEventMeta[]>([]);
  const selectedItemsEntities = useRef<IMeet[]>();
  const currenDetailedMeetingId = useRef("");
  const startDate = useRef(dayjs().format("YYYY-MM-DD"));
  const endDate = useRef(dayjs().add(6, "days").format("YYYY-MM-DD"));
  const selectMeetingSeriesQueryParams = useRef(`startDate=${startDate.current}&endDate=${endDate.current}`);
  const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [isVisibleNotFound, setIsVisibleNotFound] = useState(false);
  const phSearch = useIntl().formatMessage({ id: "search" });
  const [isDetailedMeetingViewModalVisible, setIsDetailedMeetingViewModalVisible] = useState(false);

  const fetchEvents = async () => {
    dispatch(getMeetingsSeries(selectMeetingSeriesQueryParams.current));
  };

  const openDetailedMeetingScreen = (id: string) => {
    setIsDetailedMeetingViewModalVisible(true);
    currenDetailedMeetingId.current = id;
  };

  const handleResetFilter = () => {
    startDate.current = dayjs().format("YYYY-MM-DD");
    endDate.current = dayjs(startDate.current).add(7, "days").format("YYYY-MM-DD");
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}`;

    setSearch("");
    dispatch(resetMeetingsSeries());
    fetchEvents();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);
    setIsVisibleNotFound(false);
    eventHelperUtils.debounce(async () => {
      startDate.current = dayjs().format("YYYY-MM-DD");
      endDate.current = dayjs(startDate.current).add(30, "days").format("YYYY-MM-DD");
      selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}&name=${value}`;

      dispatch(resetMeetingsSeries());
      fetchEvents();
    }, 1000);
  };

  const loadMoreData = useCallback(async () => {
    if (isLoading) return;

    startDate.current = dayjs(endDate.current).add(1, "days").format("YYYY-MM-DD");
    endDate.current = dayjs(startDate.current).add(30, "days").format("YYYY-MM-DD");
    const searchName = search ? `&name=${search}` : "";
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}${searchName}`;

    fetchEvents();
  }, [isLoading, errorMsg]);

  const isNewlySelectedEventsHasEntity = useCallback(
    (id: string, date: string) => {
      for (const item of newlySelectedEvents.current) {
        if (item.id === id && item.date === date) return true;
      }

      return false;
    },
    [newlySelectedEvents.current],
  );

  const handleSelectedItems = useCallback(
    (id: string, item: IMeet, date: string) => {
      if (isNewlySelectedEventsHasEntity(id, date)) {
        selectedItemsEntities.current = selectedItemsEntities.current?.filter((item) => item.id !== id);
        newlySelectedEvents.current = newlySelectedEvents.current?.filter((item) => item.id !== id || item.date !== date);

        dispatch(removeSelectedItemId(id));
        dispatch(removeSelectedMeetingById(id));

        return;
      }

      if (selectedTaskIds.includes(id)) {
        dispatch(removeSelectedItemId(id));
        onItemDelete(id);

        return;
      }

      if (!selectedTaskIds.includes(id) && !isNewlySelectedEventsHasEntity(id, date)) {
        selectedItemsEntities.current = [...(selectedItemsEntities.current ?? []), item];
        newlySelectedEvents.current = [...newlySelectedEvents.current, { id: item.id, date }];
      }
    },
    [selectedTaskIds],
  );

  const onAddHandler = () => {
    handleAttachItems(selectedItemsEntities.current ?? [], newlySelectedEvents.current);
    setIsVisible(false);
  };

  const checkIsChecked = useCallback(
    (id: string, date: string) => selectedItemsIds.includes(id) || isNewlySelectedEventsHasEntity(id, date),
    [selectedItemsIds, newlySelectedEvents.current],
  );

  const renderItems = useMemo(() => {
    const sectionHeaderRederer = (section: ISection) => {
      const day = section?.day;
      const sectionIndex = meetingsSeries.indexOf(section);
      const formattedDay = dayjs(day).format("DD.MM");
      const dayName = dayjs(day).format("dddd");
      const formattedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

      return (
        <TextDateTitle size={18} weight="700" color={Colors.LIGHT.text.grey} isFirst={sectionIndex === 0}>
          {`${formattedDay} ${formattedDayName}`}
        </TextDateTitle>
      );
    };

    const renderSectionData = (section: ISection) =>
      section.data.map((item) => (
        <EventItem
          item={item}
          checkbox
          handleCheckedItems={handleSelectedItems}
          isChecked={checkIsChecked(item.id, section.day)}
          date={section.day}
          onPress={() => {
            openDetailedMeetingScreen(item.id);
          }}
        />
      ));

    const renderFooter = () => (
      <ButtonMoreData onClick={loadMoreData}>
        {isLoading ? (
          <LoaderWrap>
            <ReactLoading type="spokes" color={Colors.LIGHT.green} height={25} width={25} />
          </LoaderWrap>
        ) : (
          <LineItems>
            <TextFont size={18} color={Colors.LIGHT.text.grey}>
              Еще 30 дней
            </TextFont>
            <ArrowDownSvg />
          </LineItems>
        )}
      </ButtonMoreData>
    );

    const RenderSectionList = () => (
      <>
        {meetingsSeries.map((item) => (
          <div>
            {sectionHeaderRederer(item)}
            {renderSectionData(item)}
          </div>
        ))}
        {renderFooter()}
      </>
    );

    const isEmptyArray = !meetingsSeries.length;

    if (isEmptyArray && !search.length && !isLoading) {
      return (
        <EmptyBusinessTasks
          press={() => {
            setIsAddEventModalVisible(true);
          }}
          titleTextId={"noMeetings"}
        />
      );
    } else if (isEmptyArray && !isLoading) {
      const dayStart = dayjs(startDate.current).format("DD.MM");
      const dayEnd = dayjs(endDate.current).format("DD.MM");
      const message = `В период с ${dayStart} - ${dayEnd} нет встреч удовлетворяющих поиску`;

      setTimeout(() => {
        setIsVisibleNotFound(true);
      }, 0);

      return (
        <>
          {isVisibleNotFound && (
            <>
              <NotFound handleResetFilter={handleResetFilter} disableButton titleTextId="meetingNotFound" descriptionTextId={message} />
              <ButtonNext onClick={loadMoreData}>
                <SvgSearchGreen />
                <TextFont size={18} weight="700" color={Colors.LIGHT.text.accent}>
                  Искать на следующие 30 дней
                </TextFont>
              </ButtonNext>
            </>
          )}
        </>
      );
    }
    return <RenderSectionList />;
  }, [meetingsSeries, newlySelectedEvents.current, isLoading]);

  const handleClose = () => {
    dispatch(resetMeetingsSeries());
    setIsVisible(false);
  };

  useEffect(() => {
    startDate.current = dayjs().format("YYYY-MM-DD");
    endDate.current = dayjs().add(6, "days").format("YYYY-MM-DD");
    selectMeetingSeriesQueryParams.current = `startDate=${startDate.current}&endDate=${endDate.current}`;

    if (!isLoadingMeeting) {
      dispatch(resetMeetingsSeries());
      fetchEvents();
    }
  }, [isLoadingMeeting]);

  useEffect(() => {
    if (!tags.length) {
      dispatch(getList(""));
    }
  }, []);

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose} scrollEnable={false}>
      <HeaderModal
        title="meetingAdd"
        leftSide={{ onPressClose: handleClose, onPressCancel: handleClose }}
        rightSide={{ onPress: onAddHandler, title: "add" }}
        isEdit={true}
      />
      <ContentWrap>
        <BlockSearch>
          <SearchWrap>
            <SvgSearch />
            <InputSearch value={search} onChange={handleSearch} placeholder={phSearch} maxLength={255} />
          </SearchWrap>
          <RenderWithCondition condition={Boolean(search)}>
            <ButtonDrop onClick={handleResetFilter}>
              <TextFont size={16} color={Colors.LIGHT.text.accent}>
                <FormattedMessage id="drop" />
              </TextFont>
            </ButtonDrop>
          </RenderWithCondition>
        </BlockSearch>

        <ButtonCreateText
          onClick={() => {
            setIsAddEventModalVisible(true);
          }}
        >
          <TextFont size={18} color={Colors.LIGHT.text.grey}>
            <FormattedMessage id="createMeeting" />
          </TextFont>
        </ButtonCreateText>

        <ListWrap>{renderItems}</ListWrap>
      </ContentWrap>

      <ModalRenderWithCondition condition={isAddEventModalVisible}>
        <Modal isVisible={isAddEventModalVisible} meetId={""} setIsVisible={setIsAddEventModalVisible} preventAttachedTasksReset />
      </ModalRenderWithCondition>

      {/* Open meeting detailed page */}
      <ModalRenderWithCondition condition={isDetailedMeetingViewModalVisible}>
        <Modal
          isVisible={isDetailedMeetingViewModalVisible}
          meetId={currenDetailedMeetingId.current}
          setIsVisible={setIsDetailedMeetingViewModalVisible}
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
});

const ContentWrap = styled.div`
  padding: 20px 12px 0 12px;
  overflow-y: auto;
  height: 92%;
`;
const BlockSearch = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonCreateText = styled.div`
  margin: 20px 0;
  cursor: pointer;
`;
const ListWrap = styled.div`
  height: 82%;
`;
const LineItems = styled.div`
  display: flex;
  align-items: center;
`;
const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  position: absolute;
`;
const ButtonMoreData = styled.button`
  margin: 10px 0;
`;
const ButtonNext = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 14px;
  gap: 6px;
`;
const TextDateTitle = styled(TextFont)<{ isFirst: boolean }>`
  margin-bottom: 8px;
  margin-top: ${(props) => (props.isFirst ? "0" : "20px")};
`;
