import React, { CSSProperties, FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ModalRenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { InterfaceUser } from "@interfaces/index";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { ModalDelegate } from "../../../components/modalDelegate";

interface IProps {
  task: ITask;
  onSave?: (user: InterfaceUser.IStateUser) => void;
  styleButton?: CSSProperties;
  sizeIcon?: number;
  delegatedToUser?: InterfaceUser.IStateUser | null;
  handleTakeMe?: () => void;
  isNeedCloseAfterSave?: boolean;
}

export const ButtonDelegate: FC<IProps> = ({
  onSave,
  handleTakeMe,
  task,
  styleButton,
  sizeIcon,
  isNeedCloseAfterSave,
  delegatedToUser,
  ...props
}) => {
  const [isVisibleDelegate, setIsVisibleDelegate] = useState(false);

  return (
    <div>
      <ButtonItem onClick={() => setIsVisibleDelegate(true)} {...props}>
        <SvgDelegate width={sizeIcon ?? 24} />
        <TextFont size={18} color={Colors.LIGHT.yellow}>
          <FormattedMessage id="delegate" />
        </TextFont>
      </ButtonItem>

      <ModalRenderWithCondition condition={isVisibleDelegate}>
        <ModalDelegate
          close={() => setIsVisibleDelegate(false)}
          isModal={true}
          onSave={onSave}
          task={task}
          delegatedToUser={delegatedToUser}
          onTakeMe={handleTakeMe}
          isNeedCloseAfterSave={isNeedCloseAfterSave}
        />
      </ModalRenderWithCondition>
    </div>
  );
};

const ButtonItem = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 0 12px 8px;
  width: 100%;
  border-radius: 11px;
  gap: 8px;
`;
