import { http } from "@api/httpClient";

import { IDataUserMessage } from "../interfaces/user.interface";

export interface IDataUpdateUser {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
}

export interface IParamsDelegationList {
  query?: string;
  total?: number;
  current?: number;
  next?: number;
  userId?: string;
  showFromDelegationChain?: boolean;
  showFromDepartment?: boolean;
  showOthers?: boolean;
  active?: boolean;
}

export interface IParamsGetMessages {
  page: number;
  size: number;
  sort?: string;
}

export interface ICreateGroup {
  name: string;
  userIds: string[];
  externalUserIds: string[];
}

class UserService {
  getInfo = async (): Promise<any> => await http.get("/api/uaa/v1/users/info");

  get = async (id: string): Promise<any> => await http.get(`/api/uaa/v1/users/${id}`);

  update = async (data: IDataUpdateUser): Promise<any> => await http.patch("/api/uaa/v1/users/me", data);

  delete = async (): Promise<any> => await http.delete("/api/uaa/v1/users/me");

  getList = async (): Promise<any> => await http.get("/api/uaa/v1/users/list");

  sendUserMessage = async (data: IDataUserMessage): Promise<any> => await http.post("/api/api-gateway/v1/user-messages/send", data);

  getListMessages = async (): Promise<any> => await http.get("/api/notifications/v1/messages");

  viewMessage = async (id: string): Promise<any> => await http.post(`/api/notifications/v1/messages/${id}/view`);

  paginationMessages = async (params: IParamsGetMessages): Promise<any> =>
    await http.get(
      `/api/notifications/v2/messages?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );

  viewAllMessages = async (): Promise<any> => await http.post("/api/notifications/v1/messages/view");

  delegationList = async (id: string, params: IParamsDelegationList): Promise<any> => {
    const taskId = id ? `/${id}` : "";
    return params
      ? await http.get(
          `/api/api-gateway/v1/business-tasks${taskId}/delegation?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`,
        )
      : await http.get(`/api/api-gateway/v1/business-tasks${taskId}/delegation?`);
  };

  participationList = async (id: string | undefined, params: IParamsDelegationList): Promise<any> => {
    const meetId = id ? `/${id}` : "";
    return await http.get(
      `/api/api-gateway/v1/meetings${meetId}/participants?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );
  };

  getManagers = async (): Promise<any> => await http.get("api/api-gateway/v1/users/managers");

  getUnreadCountMessages = async (): Promise<number> => await http.get("/api/notifications/v2/messages/unread-count");

  deleteMessage = async (id: string): Promise<any> => await http.delete(`/api/notifications/v1/messages/${id}`);

  deleteAllMessages = async (): Promise<any> => await http.delete("/api/notifications/v3/messages");

  paginationGroups = async (params: IParamsGetMessages): Promise<any> =>
    await http.get(
      `/api/api-gateway/v1/participant-groups?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );
  paginationPreGroups = async (params: IParamsGetMessages): Promise<any> =>
    await http.get(
      `/api/api-gateway/v1/preset-groups?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`,
    );

  getGroup = async (id: string): Promise<any> => await http.get(`api/api-gateway/v1/participant-groups/${id}`);

  createGroup = async (data: ICreateGroup): Promise<any> => await http.post("api/settings/v1/participant-groups", data);

  updateGroup = async (id: string, data: ICreateGroup): Promise<any> => await http.patch(`api/settings/v1/participant-groups/${id}`, data);

  deleteGroup = async (id: string): Promise<any> => await http.delete(`api/settings/v1/participant-groups/${id}`);
}

export const userService = new UserService();
