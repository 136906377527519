import { FC, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/close.svg";
import { ReactComponent as SvgEdit } from "@assets/edit.svg";
import { RenderWithCondition } from "@hoc";
import { ITask } from "@interfaces/businessGoals.interface";
import { selectTheme } from "@store/theme";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  isPersonalTask: boolean;
  isSaveBtnVisible: boolean;
  isFromOneS: boolean;
  onClose: (...arg: unknown[]) => void;
  onSave?: (...arg: unknown[]) => void;
  task: ITask;
}

export const HeaderTablet: FC<IProps> = ({ isFromOneS, isPersonalTask, isSaveBtnVisible, onClose, onSave, task }) => {
  const theme = selectTheme("extra");
  const { directorId } = userSelector();

  const isEditAllowed = useMemo(() => {
    if (directorId) {
      return task.createdByAssistant;
    }

    return task.status === "OPEN" && !isFromOneS;
  }, [directorId, task, isFromOneS]);

  return (
    <ContentWrap isPersonalTask={isPersonalTask}>
      <Container>
        <RenderWithCondition condition={isSaveBtnVisible}>
          <button onClick={onSave}>
            <TextFontButton color={Colors.LIGHT.white} size={18}>
              <FormattedMessage id="save" />
            </TextFontButton>
          </button>
        </RenderWithCondition>
        <RenderWithCondition condition={isEditAllowed}>
          <button onClick={onSave}>
            <SvgEdit fill={Colors.LIGHT.white} />
          </button>
        </RenderWithCondition>
        <button onClick={onClose}>
          <SvgClose fill={Colors.LIGHT.white} />
        </button>
      </Container>
    </ContentWrap>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  padding-bottom: 5px;
  border-top-left-radius: 20px;
  gap: 20px;
`;
const TextFontButton = styled(TextFont)`
  margin-top: -5px;
`;
const ContentWrap = styled.div<{ isPersonalTask: boolean }>`
  background-color: ${(props) => (props.isPersonalTask ? Colors.LIGHT.darkPurple : props.theme.extra.background.darkGreen)};
`;
