import { createAsyncThunk } from "@reduxjs/toolkit";

import { IDataUserMessage } from "@interfaces/user.interface";
import { userService } from "@services";
import emitter from "@services/emitter";
import { ICreateGroup, IDataUpdateUser, IParamsDelegationList, IParamsGetMessages } from "@services/user.service";
import { getError } from "@store/getError";

export const getList: any = createAsyncThunk("user/getList", async (thunkAPI) => {
  try {
    const response = await userService.getList();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUserInfo: any = createAsyncThunk("user/getUserInfo", async (directorId: string, thunkAPI) => {
  try {
    const response = await userService.getInfo();
    emitter.emit("get_user_id", directorId ? directorId : response.data.id);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async (data: IDataUpdateUser, thunkAPI) => {
  try {
    const response = await userService.update(data);

    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (thunkAPI) => {
  try {
    await userService.delete();
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const sendUserMessage: any = createAsyncThunk("user/sendMessage", async (data: IDataUserMessage, thunkAPI) => {
  try {
    const response = await userService.sendUserMessage(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const delegatedUsers: any = createAsyncThunk(
  "user/delegedUsers",
  async (data: { id: string; params: IParamsDelegationList }, thunkAPI) => {
    try {
      const response = await userService.delegationList(data.id, data.params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const pagingDelegatedUsers: any = createAsyncThunk(
  "user/pagingDelegatedUsers",
  async (data: { id: string; params: IParamsDelegationList }, thunkAPI) => {
    try {
      const response = await userService.delegationList(data.id, data.params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const participantedUsers: any = createAsyncThunk(
  "user/participantedUsers",
  async (data: { id: string; params: IParamsDelegationList }, thunkAPI) => {
    try {
      const response = await userService.participationList(data.id, data.params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const pagingParticipantedUsers: any = createAsyncThunk(
  "user/pagingParticipantedUsers",
  async (data: { id: string; params: IParamsDelegationList }, thunkAPI) => {
    try {
      const response = await userService.participationList(data.id, data.params);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const getListMessages: any = createAsyncThunk("user/getListMessages", async (params: IParamsGetMessages, thunkAPI) => {
  try {
    const response = await userService.paginationMessages(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const pagingMessages: any = createAsyncThunk("user/pagingMessages", async (params: IParamsGetMessages, thunkAPI) => {
  try {
    const response = await userService.paginationMessages(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const viewMessage: any = createAsyncThunk("user/viewMessage", async (id: string, thunkAPI) => {
  try {
    const response = await userService.viewMessage(id);
    return { response, id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const viewAllMessages: any = createAsyncThunk("user/viewAllMessages", async (thunkAPI) => {
  try {
    const response = await userService.viewAllMessages();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getManagers: any = createAsyncThunk("user/getManagers", async (thunkAPI) => {
  try {
    const response = await userService.getManagers();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getUnreadCountMessages: any = createAsyncThunk("user/getUnreadCountMessages", async (thunkAPI) => {
  try {
    const response = await userService.getUnreadCountMessages();
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteMessage: any = createAsyncThunk(
  "user/deleteMessage",
  async ({ id, viewed }: { id: string; viewed: boolean }, thunkAPI) => {
    try {
      await userService.deleteMessage(id);
      return { id, viewed };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);

export const deleteAllMessages: any = createAsyncThunk("user/deleteAllMessages", async (thunkAPI) => {
  try {
    await userService.deleteAllMessages();
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const getListGroups: any = createAsyncThunk("user/getListGroups", async (params: IParamsGetMessages, thunkAPI) => {
  try {
    const response = await userService.paginationGroups(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
export const getListPreGroups: any = createAsyncThunk("user/getListPreGroups", async (params: IParamsGetMessages, thunkAPI) => {
  try {
    const response = await userService.paginationPreGroups(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const pagingGroups: any = createAsyncThunk("user/pagingGroups", async (params: IParamsGetMessages, thunkAPI) => {
  try {
    const response = await userService.paginationGroups(params);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const createGroup: any = createAsyncThunk("user/createGroup", async (data: ICreateGroup, thunkAPI) => {
  try {
    const response = await userService.createGroup(data);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateGroup: any = createAsyncThunk("user/updateGroup", async (data: { id: string; body: ICreateGroup }, thunkAPI) => {
  try {
    const response = await userService.updateGroup(data.id, data.body);
    return { response, id: data.id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteGroup: any = createAsyncThunk("user/deleteGroup", async (id: string, thunkAPI) => {
  try {
    await userService.deleteGroup(id);
    return { id };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});
