import { Colors } from "@theme/colors";

interface IStylesForDay {
  isSelect: boolean;
  isDisabled?: boolean;
  isPastDay?: boolean;
  isToday: boolean;
  isDeadline: boolean;
  enableDisable?: boolean;
  deadline: string;
}

export const stylesForDay = (props: IStylesForDay): { wrapper: React.CSSProperties; text: React.CSSProperties } => {
  if (props.isDisabled && props.enableDisable) {
    return { wrapper: {}, text: { color: Colors.LIGHT.grey } };
  }
  if (props.isDeadline && props.isSelect && props.deadline) {
    return {
      wrapper: { backgroundColor: Colors.LIGHT.asphalt, border: `1px solid ${Colors.LIGHT.orange}` },
      text: { color: Colors.LIGHT.white },
    };
  }
  if (props.isDeadline && !props.isSelect && props.deadline) {
    return {
      wrapper: { backgroundColor: Colors.LIGHT.white, border: `1px solid ${Colors.LIGHT.orange}` },
      text: {},
    };
  }
  if (props.isToday && props.isSelect) {
    return { wrapper: { backgroundColor: Colors.LIGHT.background.green }, text: { color: Colors.LIGHT.white } };
  }
  if (props.isToday && !props.isSelect) {
    return {
      wrapper: { backgroundColor: Colors.LIGHT.white, border: `1px solid ${Colors.LIGHT.background.green}` },
      text: { color: Colors.LIGHT.text.accent },
    };
  }

  if (props.isPastDay && props.isSelect) {
    return {
      wrapper: { backgroundColor: Colors.LIGHT.lighGrey },
      text: { color: Colors.LIGHT.grey },
    };
  }

  if (props.isPastDay && !props.isSelect) {
    return { wrapper: {}, text: { color: Colors.LIGHT.grey } };
  }

  if (props.isSelect) return { wrapper: { backgroundColor: Colors.LIGHT.asphalt }, text: { color: Colors.LIGHT.white } };
  return { wrapper: {}, text: { color: Colors.LIGHT.text.main } };
};
