import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { IMeet } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { ArrowSvg, TextFont } from "@ui";

import { TStringUnknownTupleArray } from "../utils";

import { AccessOptions } from "./components/AccessOptions";

interface IProps {
  meet: IMeet;
  isEditMode: boolean;
  handleData: (name: string | TStringUnknownTupleArray, value: unknown) => void;
}

export const AccessRightsPicker: FC<IProps> = ({ meet, isEditMode, handleData }) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  return (
    <>
      <DropDown onClick={() => setIsSectionExpanded(!isSectionExpanded)}>
        <TextFont size={18} weight="700" type="bold">
          <FormattedMessage id="access" defaultMessage={"Доступ"} />
        </TextFont>
        <ArrowSvg type={isSectionExpanded ? "up" : "down"} fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
      </DropDown>

      {isSectionExpanded && (
        <DropDownContent isShow={isSectionExpanded}>
          <AccessOptions meet={meet} handleData={handleData} isEditMode={isEditMode} />
        </DropDownContent>
      )}
    </>
  );
};

const DropDown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const DropDownContent = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;
