import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { TTypeMeetingStatus } from "@interfaces/meet.interface";
import { useRemoveMeetingStatusMutation } from "@services/meetApi";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { toFormatDate } from "@utils";

interface IProps {
  id: string;
  status: TTypeMeetingStatus;
}

export const Actions: FC<IProps> = ({ status, id }) => {
  const [removeMeetingStatus] = useRemoveMeetingStatusMutation();

  const date = useSelector(selectCurrentDate);

  const buttonsMeetingStatus: Record<TTypeMeetingStatus, string> = {
    DID_NOT_TAKE_PLACE: "cancelMeetBack",
    WILL_NOT_TAKE_PLACE: "cancelFutureMeetBack",
  };

  const handleMeetingStatus = () => {
    removeMeetingStatus({ id, date: toFormatDate(date) });
  };

  return (
    <div>
      <TextTitle type="bold" weight="700" size={18}>
        <FormattedMessage id="availableActions" />
      </TextTitle>

      <div>
        <ButtonItem onClick={handleMeetingStatus}>
          <SvgNotTake />
          <TextFont size={18}>
            <FormattedMessage id={buttonsMeetingStatus[status]} />
          </TextFont>
        </ButtonItem>
      </div>
    </div>
  );
};

const ButtonItem = styled.button`
  display: flex;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  padding: 12px 40px 12px 8px;
  width: 100%;
  align-items: center;
  gap: 8px;
`;
const TextTitle = styled(TextFont)`
  margin-bottom: 8px;
`;
