import React from "react";

import classNames from "classnames";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { selectConfigure } from "@store/configure";
import { selectIsModeAssistant, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui/TextFont";
import globalState from "@utils/globalState";
import { myTracker } from "@utils/myTracker";

import { ReactComponent as Day } from "./assets/day.svg";
import { ReactComponent as Mouth } from "./assets/mounth.svg";
import { ReactComponent as Profile } from "./assets/profile.svg";
import { ReactComponent as Task } from "./assets/task.svg";
import { configButtons } from "./config";
import "./animated.css";

export const NavBar = () => {
  const location = useLocation();

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);
  const { unreadMessages } = userSelector();
  const isModeAssistant = useSelector(selectIsModeAssistant);

  const renderIcon = (label: string, active: boolean) => {
    const color = active ? "#297952" : "#B3BEB8";
    const profileIcon = unreadMessages ? (
      <div style={{ position: "relative" }}>
        <Profile fill={color} />
        <div style={styles.unreadMessage}>
          <TextFont size={9} color="white">
            {unreadMessages}
          </TextFont>
        </div>
      </div>
    ) : (
      <Profile fill={color} />
    );

    const obj: Record<string, JSX.Element> = {
      "": <Day fill={color} />,
      month: <Mouth fill={color} />,
      tasks: <Task fill={color} />,
      profile: profileIcon,
    };

    return obj[label];
  };

  return (
    <div style={{ float: "right", height: "100%" }} className={!!isTightMode && location.pathname === "/" ? "tabbar-open" : "tabbar-close"}>
      <nav style={styles.container}>
        {configButtons.map(({ id, label, path, isDisabledAssistant }) => (
          <NavLink
            onClick={() => {
              myTracker(label === "Месяц" ? "ClickMonth" : label === "День" ? "ClickDay" : "ClickAllTasks");
            }}
            key={id}
            to={`/${path}`}
            style={styles.button}
            className={classNames({ "disabled-link": isModeAssistant && isDisabledAssistant })}
          >
            {renderIcon(path, location.pathname === `/${path}`)}
            <TextFont
              style={{
                ...styles.label,
                color: location.pathname === `/${path}` ? globalState.colorSchema.text.accent : globalState.colorSchema.grey,
              }}
            >
              {label}
            </TextFont>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#FBFBFF",
    boxShadow: "0px -2px 14px rgba(186, 186, 202, 0.38)",
    borderRadius: "20px 20px 0px 0px",
  },
  button: {
    display: "flex",
    "flex-direction": "column",
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontStyle: "normal",
    marginTop: 5,
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "12px",
  },
  unreadMessage: {
    position: "absolute",
    top: -3,
    right: -5,
    borderRadius: 55,
    backgroundColor: Colors.LIGHT.red,
    paddingLeft: 1,
    paddingRight: 1,
  },
};
