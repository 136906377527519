import { ITheme } from "@interfaces/index";

export const Colors: Record<ITheme.TKeysTheme, ITheme.IColors> = {
  LIGHT: {
    green: "#219653",
    green100: "#EFFAF4",
    green400: "#5ab18733",
    green500: "#2979524D",
    green700: "#38795aa0",
    greenHover: "#30D158",
    white: "#FFFFFF",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    red100: "#ffe7e6",
    red200: "#ffdad8",
    red300: "#f8c1be",
    lightYellow: "#FFF3D4",
    lightYellow100: "#EEEDE9",
    yellow: "#FFB800",
    yellow100: "#FFE296",
    yellow300: "#FFF0CB",
    yellow400: "#FFECBA",
    purple: "#8264FB",
    purple100: "#DFD8FF",
    orange: "#FB9231",
    orange500: "#FB923133",
    grey: "#C7C7C7",
    grey200: "#CFCFCF",
    grey400: "#C7C7C742",
    grey500: "#5F6E67",
    lighGrey: "#E3E9E7",
    lightGrey100: "#1212120a",
    lightGrey150: "#e3e9e7ac",
    lightGrey200: "#dddddd",
    lightGrey300: "#f5f6f6",
    lightGrey400: "#e3e9e740",
    lightGrey500: "#12121229",
    black: "#000000",
    black100: "#0000000a",
    black300: "#0000004d",
    black400: "#00000066",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#ECF0EF",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",
    hover: "#71737311",
    shadowTooltip: "#00000017",
    shadowEvent: "#51437a80",

    text: {
      main: "#274335",
      accent: "#297952",
      grey: "#5F6E67",
      grey400: "#939393",
      grey800: "#12121299",
      grey900: "#121212E5",
      darkGreen: "#274335",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
      accent: "#3c3c4399",
    },
    background: {
      main: "#F5F9F8",
      lightGreen: "#72AF5C",
      blue100: "#007AFF33",
      darkGreen: "#274335",
      green: "#297952",
      green100: "#D1E7E0",
      green200: "#DAF1E5",
      green300: "#BDECD3",
      grey: "#B3BEB8",
      grey100: "#f3f3f3",
      grey150: "#ecebeb",
      grey600: "#A6A6A6",
    },
    title: "#274335",
  },
  DARK: {
    green: "#219653",
    green100: "#EFFAF4",
    green400: "#5ab18733",
    green500: "#2979524D",
    green700: "#38795aa0",
    white: "#fff",
    greenHover: "#30D158",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    red100: "#ffe7e6",
    red200: "#ffdad8",
    red300: "#f8c1be",
    lightYellow: "#FFF3D4",
    lightYellow100: "#EEEDE9",
    yellow: "#FFB800",
    yellow100: "#FFE296",
    yellow300: "#FFF0CB",
    yellow400: "#FFECBA",
    purple: "#8264FB",
    purple100: "#DFD8FF",
    orange: "#FB9231",
    orange500: "#FB923133",
    grey: "#C7C7C7",
    grey200: "#CFCFCF",
    grey400: "#C7C7C742",
    grey500: "#5F6E67",
    lighGrey: "#E3E9E7",
    lightGrey100: "#1212120a",
    lightGrey150: "#e3e9e7ac",
    lightGrey200: "#dddddd",
    lightGrey300: "#f5f6f6",
    lightGrey400: "#e3e9e740",
    lightGrey500: "#12121229",
    black: "#000000",
    black100: "#0000000a",
    black300: "#0000004d",
    black400: "#00000066",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#ECF0EF",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",
    hover: "#71737311",
    shadowTooltip: "#00000017",
    shadowEvent: "#51437a80",

    text: {
      main: "#274335",
      accent: "#297952",
      grey: "#5F6E67",
      grey400: "#939393",
      grey800: "#12121299",
      grey900: "#121212E5",
      darkGreen: "#274335",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
      accent: "#3c3c4399",
    },
    background: {
      main: "#F5F9F8",
      lightGreen: "#72AF5C",
      blue100: "#007AFF33",
      darkGreen: "#274335",
      green: "#297952",
      green100: "#D1E7E0",
      green200: "#DAF1E5",
      green300: "#BDECD3",
      grey: "#B3BEB8",
      grey100: "#f3f3f3",
      grey150: "#ecebeb",
      grey600: "#A6A6A6",
    },
    title: "#274335",
  },
  ASSISTANT: {
    green: "#433327",
    green100: "#fff8f0",
    green400: "#5ab18733",
    green500: "#2979524D",
    green700: "#38795aa0",
    white: "#FFFFFF",
    blue: "#007AFF",
    lightBlue: "#bcd3ff",
    lightRed: "#FFEFEF",
    red: "#FF3B30",
    red100: "#ffe7e6",
    red200: "#ffdad8",
    red300: "#f8c1be",
    lightYellow: "#FFF3D4",
    lightYellow100: "#EEEDE9",
    yellow: "#FFB800",
    yellow100: "#FFE296",
    yellow300: "#FFF0CB",
    yellow400: "#FFECBA",
    purple: "#8264FB",
    purple100: "#DFD8FF",
    orange: "#FB9231",
    orange500: "#FB923133",
    grey: "#C7C7C7",
    grey200: "#CFCFCF",
    grey400: "#C7C7C742",
    grey500: "#5F6E67",
    lighGrey: "#F0EFEC",
    lightGrey100: "#1212120a",
    lightGrey150: "#e3e9e7ac",
    lightGrey200: "#dddddd",
    lightGrey300: "#f5f6f6",
    lightGrey400: "#e3e9e740",
    lightGrey500: "#12121229",
    black: "#000000",
    black100: "#0000000a",
    black300: "#0000004d",
    black400: "#00000066",
    darkPurple: "#191945",
    lightPurple: "#F8F9FF",
    disabled: "#E4E2DB",
    lighterYellow: "#FFE9B0",
    softGrey: "#FBFBFB",
    asphalt: "#A1B6AD",
    hover: "#71737311",
    shadowTooltip: "#00000017",
    shadowEvent: "#51437a80",

    text: {
      main: "#433327",
      accent: "#795929",
      grey: "#5F6E67",
      grey400: "#939393",
      grey800: "#12121299",
      grey900: "#121212E5",
      darkGreen: "#433327",
    },
    placeholder: {
      main: "#B3BEB8",
      system: "#777777",
      accent: "#3c3c4399",
    },
    background: {
      main: "#F9F7F5",
      lightGreen: "#72AF5C",
      blue100: "#007AFF33",
      darkGreen: "#453831",
      green: "#795929",
      green100: "#D1E7E0",
      green200: "#DAF1E5",
      green300: "#BDECD3",
      grey: "#B3BEB8",
      grey100: "#f3f3f3",
      grey150: "#ecebeb",
      grey600: "#A6A6A6",
    },
    title: "#453831",
    greenHover: "#F0EFEC",
  },
};
