import React, { FC } from "react";

import ContentLoader from "react-content-loader";

/*
  ToDo: SVG требует числовые значение ширины и высоты.
  Придумать, как можно посчитать размеры родителя
  (особенно, если они меняются со временем, как при открытии модалки)
*/
interface IItemListContentLoaderProps {
  width?: number | string;
  height?: number | string;
}

export const ItemListContentLoader: FC<IItemListContentLoaderProps> = ({ width = 460, height = 1000 }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${(width as number) + 20} ${(height as number) + 20}`}
    backgroundColor="#ecf0ef"
    foregroundColor="#f5f9f8"
    backgroundOpacity={1}
    foregroundOpacity={0.7}
  >
    <rect x="10" y="10" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="60" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="110" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="160" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="210" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="260" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="310" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="360" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="410" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="460" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="510" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="560" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="610" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="660" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="710" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="760" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="810" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="860" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="910" rx="6" ry="6" width={width} height="40" />
    <rect x="10" y="960" rx="6" ry="6" width={width} height="40" />
  </ContentLoader>
);
