import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

import { selectCurrentDate } from "@store/screenDay";
import { toFormatISO } from "@utils";

import { toFormatStartTime } from "../utils/toFormatTime";

type onChange = (type: "startTime" | "endTime", arg: string | Dayjs) => string;
type Args = { startValue?: string | null; endValue?: string | null };

type UseTime = ({ startValue, endValue }: Args) => {
  startTime: string;
  endTime: string;
  isValidTime: boolean;
  handleTime: onChange;
};

export const useTime: UseTime = ({ startValue, endValue }) => {
  const dateNow = useSelector(selectCurrentDate);

  const [startTime, setStartTime] = useState<string | null>(startValue ?? toFormatStartTime(dateNow).startTime);
  const [endTime, setEndTime] = useState<string | null>(endValue ?? toFormatStartTime(dateNow).endTime);

  const isValidTime = useMemo(() => dayjs(startTime ?? "").diff(dayjs(endTime ?? ""), "minutes") <= -15, [startTime, endTime]);

  const handleTime = useCallback((type: "startTime" | "endTime", date: string | null) => {
    const value = date ? toFormatISO(dayjs(date)) : date;
    if (type === "startTime") {
      setStartTime(value);
      if (date) {
        const startTimePlusHour = dayjs(value).add(1, "hours").date(dayjs(value).date()).format("YYYY-MM-DDTHH:mm:ss");
        setEndTime(startTimePlusHour);
      }
    } else {
      setEndTime(value);
    }
  }, []);

  useEffect(() => {
    if (startTime) {
      if (dayjs(startTime).isAfter(dayjs(endTime))) {
        const startTimePlusHour = dayjs(startTime).add(1, "hours").date(dayjs(startTime).date()).format("YYYY-MM-DDTHH:mm:ss");
        setEndTime(startTimePlusHour);
      }
    }
  }, [startTime]);

  const isValidEndTime = useCallback(
    (value: string) => {
      const isStartTimeLessThanEndTime = dayjs(dayjs(startTime) ?? "").isBefore(value, "minutes");
      const startOfNextDay = toFormatISO(dayjs(value).startOf("day").add(23, "hour").add(60, "minutes"));

      return dayjs(value ?? "").isBefore(startOfNextDay) && isStartTimeLessThanEndTime;
    },
    [startTime],
  );

  const fixEndTime = useCallback(() => toFormatISO(dayjs(startTime).add(1, "hour").date(dayjs(startTime).date())), [startTime]);

  useEffect(() => {
    if (!isValidTime && endTime) {
      let value = toFormatISO(dayjs(startTime).add(15, "minute").date(dayjs(startTime).date()));

      if (!isValidEndTime(value)) {
        value = fixEndTime();
      }

      setEndTime(value);
    }
  }, [endTime]);

  return useMemo(
    () => ({
      startTime,
      endTime,
      isValidTime,
      handleTime,
    }),
    [startTime, endTime, isValidTime, handleTime],
  );
};
