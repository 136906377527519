import React, { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as RemoveGreen } from "@assets/remove-green.svg";
import { ReactComponent as SearchGreen } from "@assets/search-green.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFilter } from "@interfaces/businessTask.interface";
import { SelectRole } from "@shared";
import { RootState } from "@store";
import { getFilteredList, setFilter } from "@store/businessTask";
import { selectTheme, themeSelector } from "@store/theme";
import { selectUserPermissoins } from "@store/user";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { myTracker } from "@utils/myTracker";

import { ReactComponent as Filter } from "./assets/filter.svg";
import { ReactComponent as Grouping } from "./assets/grouping.svg";
import { ReactComponent as Project } from "./assets/project.svg";
import { FilterModal } from "./components/filterModal";
import { GroupingModal } from "./components/groupingModal";
import { ProjectModal } from "./components/projectModal";

const eventHelperUtils = new EventHelperUtils();

export const Header = () => {
  const dispatch = useDispatch<any>();
  const { filter } = useSelector((state: RootState) => state.businessTasks);
  const { name } = filter;
  const permissons = useSelector(selectUserPermissoins);
  const theme = selectTheme("extra");
  const { extra } = themeSelector();

  const [isSearchPressed, setIsSearchPressed] = useState<boolean>(false);

  const [isShownProject, setIsShownProject] = useState<boolean>(false);
  const [isShownGrouping, setIsShownGrouping] = useState<boolean>(false);
  const [isShownFilter, setIsShownFilter] = useState<boolean>(false);
  const [search, setSearch] = useState(name);

  useEffect(() => {
    if (search) {
      setIsSearchPressed(true);
    }
  }, []);

  useEffect(() => {
    applyEvent();
  }, []);

  const applyEvent = async (callback?: () => void, proporties?: Partial<IFilter>) => {
    dispatch(
      getFilteredList({
        ...filter,
        pageable: {
          next: 20,
          current: 0,
        },
        ...proporties,
      }),
    );
    if (callback) callback();
  };

  const closeGroupModal = () => {
    setIsShownGrouping(false);
  };

  const closeFilterModal = () => {
    setIsShownFilter(false);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    dispatch(setFilter({ name: value }));

    eventHelperUtils.debounce(() => {
      dispatch(
        getFilteredList({
          ...filter,
          name: value,
          pageable: {
            next: 20,
            current: 0,
          },
        }),
      );
    }, 1000);
  };

  const handleToggleSearch = (arg: boolean) => {
    if (!arg && search) {
      handleSearch("");
    }
    setIsSearchPressed(arg);
  };

  const colorSvg = extra === "ASSISTANT" ? Colors.ASSISTANT.background.darkGreen : Colors.ASSISTANT.grey500;

  return (
    <ContentWrap>
      <BlockLeft>
        <RenderWithCondition condition={permissons.WORK_WITH_SPACE_PROJECTS}>
          <ButtonLeft disabled={extra === "ASSISTANT"} onClick={() => setIsShownProject(true)}>
            <Project fill={extra === "ASSISTANT" ? Colors.LIGHT.lightYellow100 : Colors.LIGHT.text.accent} />
          </ButtonLeft>
        </RenderWithCondition>
        <RenderWithCondition condition={extra === "ASSISTANT"}>
          <SelectRole type="view" />
        </RenderWithCondition>
      </BlockLeft>
      <TextTitle type="bold" size={16} weight="700" color={theme.text.main} align="center">
        <FormattedMessage id={"availableTasks"} />
      </TextTitle>
      <BlockRight>
        <SearchLine>
          {isSearchPressed && (
            <InputWrap>
              <SvgSearch />
              <InputSearch value={search} onChange={(e) => handleSearch(e.target.value)} placeholder="Поиск" maxLength={255} />
            </InputWrap>
          )}
          <ButtonClose onClick={() => handleToggleSearch(!isSearchPressed)}>
            {isSearchPressed ? <RemoveGreen fill={colorSvg} /> : <SearchGreen fill={colorSvg} />}
          </ButtonClose>
        </SearchLine>

        <ButtonGroup
          onClick={() => {
            myTracker("GroupingClick");
            setIsShownGrouping(true);
          }}
        >
          <Grouping fill={colorSvg} />
        </ButtonGroup>
        <ButtonFilter
          onClick={() => {
            myTracker("FilterClick");
            setIsShownFilter(true);
          }}
        >
          <Filter fill={colorSvg} />
        </ButtonFilter>
      </BlockRight>
      <ModalRenderWithCondition condition={isShownProject}>
        <ProjectModal isVisible={isShownProject} closeEvent={() => setIsShownProject(false)} />
      </ModalRenderWithCondition>
      <FilterModal applyEvent={() => applyEvent(closeFilterModal)} isVisible={isShownFilter} closeEvent={closeFilterModal} />
      <ModalRenderWithCondition condition={isShownGrouping}>
        <GroupingModal applyEvent={applyEvent} isVisible={isShownGrouping} closeEvent={closeGroupModal} />
      </ModalRenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  ${boxCenterCss};
  justify-content: space-between;
  flex: 1;
  padding: 4px 13px;
  height: 100%;
  position: relative;
`;
const BlockLeft = styled.div`
  ${boxCenterCss};
  justify-content: flex-start;
  flex: 2;
  gap: 20px;
  z-index: 1;
`;
const BlockRight = styled.div`
  ${boxCenterCss};
  justify-content: flex-end;
  flex: 2;
  z-index: 1;
`;
const SearchLine = styled.div`
  ${boxCenterCss};
  justify-content: flex-end;
  width: auto;
  flex: 1.5;
`;
const InputWrap = styled.div`
  ${boxCenterCss};
  height: 100%;
  width: 90%;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.disabled};
  padding: 9px 8px;
`;
const ButtonLeft = styled.button`
  display: flex;
  justify-content: flex-start;
`;
const ButtonClose = styled.button`
  margin-left: 20px;
`;
const ButtonGroup = styled.button`
  ${boxCenterCss};
  justify-content: flex-end;
  margin-left: 20px;
  width: 50px;
  height: 25px;
`;
const ButtonFilter = styled.button`
  ${boxCenterCss};
  justify-content: flex-end;
  width: 42px;
  margin-left: 0px;
`;
const TextTitle = styled(TextFont)`
  flex: 1;
`;
const InputSearch = styled.input`
  margin-left: 6px;
  width: 100%;
`;
