import React, { FC, useEffect, useMemo, useRef } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgCalendar } from "@assets/calendar_red.svg";
import { RenderWithCondition } from "@hoc";
import { useBoolean } from "@hooks/useBoolean";
import { IMeetRepeat } from "@interfaces/meet.interface";
import { Calendar } from "@shared/planningWork";
import { RootState } from "@store/store";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { showToast, toFormatDate } from "@utils";

interface IProps {
  startTime: string;
  isVisibleStart: boolean;
  pressCalendar: (arg: Dayjs) => void;
  disabled: boolean;
  repeat: IMeetRepeat;
  startDays: any[];
}

export const Date: FC<IProps> = ({ startTime, isVisibleStart, pressCalendar, disabled, repeat, startDays }) => {
  const blockRef = useRef(null);

  const [value, { toggle }] = useBoolean();

  useEffect(() => {
    if (value) {
      blockRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [value, blockRef]);

  const onPress = () => {
    if (disabled) return showToast("dateSelectionUnavaliable");
    return toggle();
  };

  const handlePressCalendar = (arg: any) => {
    pressCalendar(arg);
    toggle();
  };

  const { startDate } = useSelector((state: RootState) => state.screenDay);

  const currentDay = useMemo(
    () => (dayjs(startTime).isBefore(dayjs()) ? dayjs().format("YYYY-MM-DD") : dayjs(startTime).format("YYYY-MM-DD")),
    [startTime],
  );

  return (
    <ContentWrap ref={blockRef} disabled={disabled}>
      <ButtonCalendar isVisible={isVisibleStart} onClick={onPress}>
        <CalendarWrap>
          <SvgCalendar />
          <TextFont size={16} weight="700">
            <FormattedMessage id="date" />
          </TextFont>
        </CalendarWrap>
        <RightBlock>
          <TextWrap>
            <TextFont>{dayjs(startTime).format("DD MMM YYYYг.")}</TextFont>
          </TextWrap>
        </RightBlock>
      </ButtonCalendar>

      <RenderWithCondition condition={value}>
        <Calendar
          currentDay={currentDay}
          startDay={toFormatDate(startDate)}
          press={handlePressCalendar}
          deadline={toFormatDate(dayjs(repeat?.endTime ?? ""))}
          days={startDays}
        />
      </RenderWithCondition>
    </ContentWrap>
  );
};

const ContentWrap = styled.div<{ disabled?: boolean }>`
  background-color: ${Colors.LIGHT.white};
  margin-top: 8px;
  border-radius: 11px;
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${Colors.LIGHT.disabled};
  `}
`;
const CalendarWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const TextWrap = styled.div`
  background-color: ${Colors.LIGHT.background.main};
  border-radius: 5px;
  padding: 3px 5px 3px 9px;
  margin-right: 11px;
`;
const RightBlock = styled.div`
  display: flex;
`;
const ButtonCalendar = styled.button<{ isVisible?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 8px;
  width: 100%;

  ${({ isVisible }) =>
    isVisible &&
    `
  border: 1px solid ${Colors.LIGHT.lighGrey};
`}
`;
