import React, { useState, useRef, useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { IStateUser } from "@interfaces/user.interface";
import { Layout } from "@layout";
import { selectNotifications } from "@store/notification";
import { selectUserForAssistant, userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { ModalUI } from "@ui";

import { Home } from "./components";
import { TypeTab } from "./types";
import { useConfigTabs } from "./useConfigTabs";

export const Profile = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const { isTablet } = useDimension();

  const [activeTab, setActiveTab] = useState<TypeTab>(isTablet ? "devices" : "");
  const containerRef = useRef<HTMLDivElement>(null);
  const { tab } = useConfigTabs({ activeTab, setTab: setActiveTab });

  const { isLoading, directorId } = userSelector();
  const directorUser = useSelector(selectUserForAssistant);
  const { activeTabProfile } = useSelector(selectNotifications);

  useEffect(() => {
    if ((location.pathname === "/profile/zoom-success" && params.get("code")) || params.get("tab") === "integrations") {
      setActiveTab("settings");
    }
  }, [location.pathname, params]);

  useEffect(() => {
    if (activeTabProfile) setActiveTab(activeTabProfile === "integrations" ? "settings" : activeTabProfile);
  }, [activeTabProfile]);

  const mob = () => (
    <ContentMobile>
      <Home user={directorUser as IStateUser} setActiveTab={setActiveTab} isAssistant={!!directorId} />
      <ModalRenderWithCondition condition={Boolean(activeTab)}>
        <ModalUI isVisible={!!activeTab} onClose={() => setActiveTab("")}>
          {tab}
        </ModalUI>
      </ModalRenderWithCondition>
    </ContentMobile>
  );

  const tablet = () => (
    <ContentTablet>
      <BlockLeft>
        <Home user={directorUser as IStateUser} setActiveTab={setActiveTab} isAssistant={!!directorId} />
      </BlockLeft>
      <BlockRight>{tab}</BlockRight>
    </ContentTablet>
  );

  return (
    <Layout headerHeight="0%" mainStyle={{ paddingLeft: 5 }} backgroundColor={Colors.LIGHT.white}>
      <ContentWrap ref={containerRef}>
        <LoaderRenderWithCondition condition={isLoading} parent={containerRef.current}>
          {isTablet ? tablet() : mob()}
        </LoaderRenderWithCondition>
      </ContentWrap>
    </Layout>
  );
};

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
`;
const ContentTablet = styled.div`
  display: flex;
  height: 100%;
`;
const ContentMobile = styled.section`
  height: 100%;
`;
const BlockLeft = styled.div`
  width: 33%;
  margin-right: 2%;
`;
const BlockRight = styled.div`
  width: 65%;
  background-color: ${Colors.LIGHT.background.main};
`;
