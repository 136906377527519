import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgError } from "@assets/cancel.svg";
import { ReactComponent as SvgSuccess } from "@assets/complete.svg";
import { ReactComponent as SvgTextOff } from "@assets/eye.svg";
import { ReactComponent as SvgTextOn } from "@assets/textOn.svg";
import { domain_outlook, regExpIsDomainOutlook } from "@constants/regExp";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { useAuthOutlookMutation } from "@services/userApi";
import { ButtonMain, commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import { NotificationCustom } from "@ui/notification/NotificationCustom";

interface IProps {
  onClose: () => void;
}

export const AuthOutlook: FC<IProps> = ({ onClose }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordHide, setIsPasswordHide] = useState(true);
  const [notificationType, setNotificationType] = useState<"success" | "error" | undefined>(undefined);

  const [authOutlook, { isLoading }] = useAuthOutlookMutation();

  const handleAuth = async () => {
    const isFullLogin = regExpIsDomainOutlook.test(login);
    const transformLogin = isFullLogin ? login : domain_outlook + login;

    const data = await authOutlook({ username: transformLogin, password });

    setNotificationType(data?.error ? "error" : "success");
  };

  return (
    <ModalUI isVisible onClose={onClose}>
      <HeaderModal title="createLinkOutlookWindow" leftSide={{ onPressClose: onClose }} />

      <Container>
        <TextFont size={16}>
          <FormattedMessage id="outlookLinkInfo" />
        </TextFont>

        <BlockInputs>
          <InputLogin value={login} onChange={(e) => setLogin(e.target.value)} placeholder="Логин от вашего Outlook" maxLength={30} />
          <WrapperInput>
            <InputPassword
              type={isPasswordHide ? "password" : "text"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Пароль от вашего Outlook"
              maxLength={30}
            />
            <button onClick={() => setIsPasswordHide((prev) => !prev)}>
              {isPasswordHide ? <SvgTextOff fill={Colors.LIGHT.placeholder.main} /> : <SvgTextOn />}
            </button>
          </WrapperInput>
        </BlockInputs>
        <ButtonMainCustom onClick={handleAuth}>
          <LoaderButtonRenderWithCondition condition={isLoading}>
            <TextFont size={18} weight="700" color={Colors.LIGHT.white}>
              <FormattedMessage id="logInOutlook" />
            </TextFont>
          </LoaderButtonRenderWithCondition>
        </ButtonMainCustom>
      </Container>

      {notificationType && (
        <NotificationCustom onClose={() => (notificationType === "success" ? onClose() : setNotificationType(undefined))}>
          <Notify>
            <IconWrap>
              {notificationType === "success" ? <SvgSuccess width={72} height={72} /> : <SvgError width={72} height={72} />}
            </IconWrap>
            <TextFont size={18} type="bold" align="center">
              <FormattedMessage id={notificationType === "success" ? "authorizationОk" : "authorizationError"} />
            </TextFont>
          </Notify>
        </NotificationCustom>
      )}
    </ModalUI>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 12px;
  padding-right: 12px;
  gap: 24px;
`;

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border: 1px solid ${Colors.LIGHT.placeholder.main};
  border-radius: 10px;
`;

const InputLogin = styled.input`
  padding: 16px 12px;
  border: 1px solid ${Colors.LIGHT.placeholder.main};
  border-radius: 10px;
  font-size: 18px;
  ::placeholder {
    color: ${Colors.LIGHT.placeholder.main};
  }
`;

const InputPassword = styled.input`
  font-size: 18px;
  width: 80%;
  ::placeholder {
    color: ${Colors.LIGHT.placeholder.main};
  }
`;

const Notify = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 40px;
  gap: 12px;
`;
const BlockInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const IconWrap = styled.div`
  height: 80px;
`;
const ButtonMainCustom = styled(ButtonMain)`
  min-height: 56px;
`;
