import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { TSingleSections } from "@interfaces/businessTask.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  show?: () => void;
  styleWrapper?: any;
  styleTextShow?: any;
  type: TSingleSections;
}

const title: Record<TSingleSections, string> = {
  transferred: "taskFromPreviousDays",
  delegated: "delegatedTasks",
  takenBack: "takenBackDelegatedTasks",
};

export const SingleSectionHeader: FC<IProps> = ({ show, styleWrapper, styleTextShow, type }) => (
  <ButtonItem onClick={show} style={{ ...styleWrapper }}>
    <TextFont size={16} color={Colors.LIGHT.red}>
      <FormattedMessage id={title[type]} />
    </TextFont>
    <TextFont size={14} style={styleTextShow}>
      <FormattedMessage id="showAll" />
    </TextFont>
  </ButtonItem>
);

const ButtonItem = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
`;
