import React, { Dispatch, useMemo } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY } from "@constants/calendar";
import { RootState } from "@store";
import { saveDate } from "@store/screenDay";
import { boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";

export const daysOfWeekDefault = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const configWeek = {
  MONDAY: "пн",
  TUESDAY: "вт",
  WEDNESDAY: "ср",
  THURSDAY: "чт",
  FRIDAY: "пт",
  SATURDAY: "сб",
  SUNDAY: "вс",
};

type TWeek = Record<string, Dayjs>;

interface ICalendarCustom {
  setIsVisible: Dispatch<boolean>;
  press: (...arg: any) => void;
}

export const CalendarCustom = ({ setIsVisible, press }: ICalendarCustom) => {
  const dispatch = useDispatch();
  const { date, startDate } = useSelector((state: RootState) => state.screenDay);

  const selectedDate = date ? date : startDate;
  const titleSelectedMonth = dayjs.months()[selectedDate.month()];

  const month: Array<TWeek> = useMemo(() => {
    const selectedYear = dayjs(selectedDate).year();
    const selectedMonth = dayjs(selectedDate).month();
    const firstDayCurrentMonth = dayjs().year(selectedYear).month(selectedMonth).date(1);
    let isEndMonth = false;
    const resultMonth: Array<TWeek> = [];
    let tempWeek = 0;
    while (isEndMonth === false) {
      const week: TWeek = {};

      daysOfWeekDefault.forEach((day: string, index) => {
        const firstDayIndexWeek = new Date(String(firstDayCurrentMonth)).getDay();
        const temp = index - firstDayIndexWeek + 1;
        week[day] = dayjs(firstDayCurrentMonth).add(temp + tempWeek, "day");
        if (
          dayjs(firstDayCurrentMonth)
            .add(temp + tempWeek - 1, "day")
            .get("month") !==
            dayjs(firstDayCurrentMonth)
              .add(temp + tempWeek, "day")
              .get("month") &&
          tempWeek > 20
        ) {
          isEndMonth = true;
        }
      });
      tempWeek += 7;
      resultMonth.push(week);
    }

    return resultMonth;
  }, [selectedDate]);

  return (
    <aside>
      <BlockHeader>
        <button
          onClick={() => {
            dispatch(saveDate(selectedDate.subtract(1, "month")));
          }}
        >
          <ArrowSvg type="left" fill={Colors.LIGHT.grey500} width={26} height={26} />
        </button>

        <TextTitle size={16} weight="700">
          {titleSelectedMonth}
        </TextTitle>

        <button
          onClick={() => {
            dispatch(saveDate(selectedDate.add(1, "month")));
          }}
        >
          <ArrowSvg type="right" fill={Colors.LIGHT.grey500} width={26} height={26} />
        </button>
      </BlockHeader>
      <BlockDaysOfWeek>
        {Object.entries(configWeek).map(([key, value]) => (
          <Cell key={key}>
            <TextFont color={Colors.LIGHT.placeholder.main}>{value}</TextFont>
          </Cell>
        ))}
      </BlockDaysOfWeek>

      <BlockTable>
        {month.map((week, index) => (
          <BodyRow key={index}>
            {Object.entries(week).map(([dayWeek, dateWeek]) => {
              const numberDay = dateWeek.date();
              const numberMonth = dateWeek.month();
              const numberYear = dateWeek.year();
              const isToday =
                numberDay === dayjs(startDate).date() && numberMonth === dayjs(startDate).month() && numberYear === dayjs(startDate).year();

              return (
                <ButtonItem
                  key={String(dateWeek)}
                  onClick={() => {
                    press(dateWeek);
                    setIsVisible(false);
                  }}
                >
                  <TextWrap isToday={isToday}>
                    <TextFont color={isToday && Colors.LIGHT.white}>{`${new Date(String(dateWeek)).getDate()}`}</TextFont>
                  </TextWrap>
                </ButtonItem>
              );
            })}
          </BodyRow>
        ))}
      </BlockTable>
    </aside>
  );
};

const BlockHeader = styled.div`
  ${boxCenterCss}
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`;
const BlockDaysOfWeek = styled.div`
  ${boxCenterCss}
  justify-content: space-between;
  width: 90%;
  margin: 4px auto;
`;
const Cell = styled.div`
  ${boxCenterCss}
  align-items: flex-start;
  flex-direction: column;
`;
const BlockTable = styled.div`
  width: 100%;
  height: 88%;
`;
const BodyRow = styled.div`
  ${boxCenterCss}
  justify-content: space-between;
  flex: 1;
`;
const TextWrap = styled.div<{ isToday: boolean }>`
  ${boxCenterCss}
  flex-direction: column;
  width: 24px;
  border-radius: 12px;
  padding: 4px;
  ${({ isToday }) =>
    isToday &&
    `
   background-color: ${Colors.LIGHT.background.green};

 `}
`;
const ButtonItem = styled.button`
  ${boxCenterCss}
  justify-content: flex-start;
  flex: 1;
  border-top: 1px solid ${Colors.LIGHT.placeholder.main};
  border-left: 1px solid ${Colors.LIGHT.placeholder.main};
  padding-top: 8px;
`;
const TextTitle = styled(TextFont)`
  text-transform: capitalize;
`;
