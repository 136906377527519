import { ReactComponent as MarkerBoardIcon } from "@assets/markerBoard.svg";
import { ReactComponent as MicrophoneIcon } from "@assets/microphone.svg";
import { ReactComponent as MonitorIcon } from "@assets/monitor_tv.svg";
import { ReactComponent as ProjectorIcon } from "@assets/projector.svg";
import { ReactComponent as VideoIcon } from "@assets/video.svg";

export const equipmentsBase = ["projector", "monitor", "microphone", "markerBoard", "videoCommunication"];

export const equipmentsData: Record<string, any> = {
  projector: {
    textId: "projector",
    defaultMessage: "В наличии проектор",
    img: <ProjectorIcon />,
  },
  monitor: {
    textId: "monitor",
    defaultMessage: "В наличии монитор",
    img: <MonitorIcon />,
  },
  videoCommunication: {
    textId: "videoCommunication",
    defaultMessage: "В наличии видеосвязь",
    img: <VideoIcon />,
  },
  microphone: {
    textId: "microphone",
    defaultMessage: "В наличии микрофон",
    img: <MicrophoneIcon />,
  },
  markerBoard: {
    textId: "markerBoard",
    defaultMessage: "В наличии маркерная доска",
    img: <MarkerBoardIcon />,
  },
};
