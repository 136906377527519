import { FC, useCallback, useEffect, useState } from "react";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ModalRenderWithCondition } from "@hoc";
import { useDimension } from "@hooks/useDimension";
import { useSubscribe } from "@hooks/useSubscribe";
import { IEventOfDaySimplified, TFirstArgPress } from "@interfaces/eventsOfDay.interface";
import { Layout } from "@layout";
import { useGetMeetingByIdQuery, useGetMeetingListQuery } from "@services/meetApi";
import { TaskSubtypes, selectBusinessTasks } from "@store/businessTask";
import { selectCalendar } from "@store/calendar";
import { selectConfigure } from "@store/configure";
import { selectNotifications, setMeetingId } from "@store/notification";
import { selectCurrentDate, selectScreenDay, updateId } from "@store/screenDay";
import { setActiveTabId } from "@store/screenDay/slice";
import { userSelector } from "@store/user";
import { Colors } from "@theme/colors";
import { AddCircle, Prompting, TextFont } from "@ui";
import { myTracker } from "@utils/myTracker";
import { toFormatDate } from "@utils/toFormatTime";

import { Notes, tightModeElements } from "../../shared";

import { Header } from "./components/header";
import { Modal as ModalTask } from "./components/ListTasks";
import { ListTasks } from "./components/ListTasks/ListTasks";
import { Tabs } from "./components/tabs";
import { Timetable } from "./components/timetable";
import { Modal } from "./components/timetable/main/modal/Modal";
import { filterEvents } from "./components/timetable/main/util";

const HEADER_HEIGHT = "40px";

export const Day: FC = () => {
  const { activeTabId } = useSelector(selectScreenDay);
  const { isTablet } = useDimension();
  const dispatch = useDispatch();
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isVisibleMeet, setIsVisibleMeet] = useState(false);
  const [isVisibleTask, setIsVisibleTask] = useState(false);
  const [currentMeet, setCurrentMeet] = useState<TFirstArgPress | undefined>(undefined);
  const [taskEvent, setTaskEvent] = useState<{ id: string; taskType: TaskSubtypes } | null>();

  const {
    settings: { isTightMode },
  } = useSelector(selectConfigure);

  const notifications = useSelector(selectNotifications);

  const [filteredEventsOfDay, setFilteredEventsOfDay] = useState<(IEventOfDaySimplified | IEventOfDaySimplified[])[]>([]);
  const { eventToReopen, currentId: eventId, joinData, isOpenModalMeet, date } = useSelector(selectScreenDay);
  const selectedDay = useSelector(selectCurrentDate);
  const { currentTask, isOpenModalTask, isPersonalTask } = useSelector(selectBusinessTasks);
  const { isVisibleAppCalendar } = useSelector(selectCalendar);

  const { data: eventsOfDay, isFetching, isLoading, refetch } = useGetMeetingListQuery({ date: toFormatDate(selectedDay) });

  const [meetId, setMeetId] = useState("");
  const [skipFetchMeet, setSkipFetchMeet] = useState(false);
  const { directorId } = userSelector();

  useSubscribe(
    "updateMeetings",
    () => {
      refetch();
    },
    [selectedDay],
  );

  useSubscribe(
    "change_listener_user",
    () => {
      setFilteredEventsOfDay([]);
      setIsFetchingData(true);
      refetch();
    },
    [directorId],
  );

  useEffect(() => {
    setIsFetchingData(true);
  }, [date]);

  useEffect(() => {
    if (!isFetching) {
      setIsFetchingData(false);
    }
  }, [isFetching, isFetchingData]);

  useEffect(() => {
    if (eventId && isOpenModalMeet) {
      setIsVisibleMeet(true);
      handleChangeCurrentMeet("id", eventId);
    }
    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskEvent({ id: currentTask.id, taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business });
    }
  }, [eventId, isOpenModalMeet, currentTask, isOpenModalTask]);

  useEffect(() => {
    if (currentTask?.id === taskEvent?.id) return;

    if (currentTask && isOpenModalTask && isVisibleAppCalendar) {
      setIsVisibleTask(true);
      setTaskEvent({ id: currentTask.id, taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business });
    }
  }, [currentTask, isOpenModalTask, isPersonalTask, taskEvent, isVisibleAppCalendar]);

  useGetMeetingByIdQuery(
    {
      id: meetId,
      repeat: currentMeet?.repeat,
      currentDate: toFormatDate(selectedDay),
    },
    {
      skip: skipFetchMeet,
    },
  );

  useEffect(() => {
    if (notifications.meetingID) {
      handleChangeCurrentMeet("id", notifications.meetingID);
      dispatch(setActiveTabId("timetable"));
      setIsVisibleMeet(true);
      dispatch(setMeetingId(undefined));
    }
  }, [notifications.meetingID]);

  const handlePress = useCallback(
    async (meet: TFirstArgPress, type: "TASK" | "MEETING", isPersonalTask?: boolean) => {
      if (type === "MEETING") {
        dispatch(updateId({ id: meet.id, modalOpen: true }));
        setIsVisibleMeet(true);
        setCurrentMeet(meet);

        if (taskEvent) {
          setTaskEvent(null);
        }
      }

      if (type === "TASK") {
        setTaskEvent({ taskType: isPersonalTask ? TaskSubtypes.Personal : TaskSubtypes.Business, id: meet.id });
        setIsVisibleTask(true);
      }
    },
    [taskEvent],
  );

  useEffect(() => {
    const url = new URL(location.href);
    if (url.search) {
      const arrQueryParams = Array.from(url.searchParams);

      (async () => {
        for await (const [type, id] of arrQueryParams) {
          if (type === "meeting") {
            setSkipFetchMeet(false);
            setMeetId(id);
            handlePress({ id, repeat: false }, "MEETING");
            break;
          }

          if (type === "task") {
            handlePress({ id }, "TASK");
            break;
          }
        }
      })();
    }
  }, [location]);

  useEffect(() => {
    /*
      ToDo: продумать вариант обновления экрана встречи без жесткой перезагрузки
      после создания исключения из серии.
    */
    if (eventToReopen?.id) {
      setIsVisibleMeet(true);
      setCurrentMeet({ id: eventToReopen.id, date: eventToReopen.date, repeat: eventToReopen.repeat });
    }
  }, [eventToReopen]);

  useEffect(() => {
    // ToDo: Типизировать данные от селектора и фильтра!!!
    if (!isLoading && !isFetching) {
      const result = filterEvents(eventsOfDay);
      setFilteredEventsOfDay(result);
    }
  }, [eventsOfDay, isLoading, isFetching]);

  useEffect(() => {
    if (joinData) handlePress({ id: "", repeat: Boolean(joinData?.repeat) }, "MEETING");
  }, [joinData]);

  const handleChangeCurrentMeet = (name: keyof TFirstArgPress, val: boolean | string) => {
    setCurrentMeet({
      ...currentMeet,
      [name]: val,
    });
  };

  const configTabs = {
    notes: <Notes backgroundColor={Colors.LIGHT.white} />,
    timetable: <Timetable filteredEventsOfDay={filteredEventsOfDay} handlePress={handlePress} isFetching={isLoading} />,
    tasks: <ListTasks handlePress={handlePress} />,
  };

  const mob = () => (
    <MobContentWrap>
      <Tabs />
      <ContentTable taskTable={activeTabId === "tasks"}>{configTabs[activeTabId]}</ContentTable>
    </MobContentWrap>
  );

  const tablet = () =>
    isTightMode ? (
      tightModeElements.day
    ) : (
      <TabletContentWrap>
        <LeftSide>
          <HeaderTable left>
            <TextFont type="bold" weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Задачи
            </TextFont>
          </HeaderTable>
          <ListTasks handlePress={handlePress} />
          <AddCircle
            press={() => {
              myTracker("CreateTaskOnDayClick");
              handlePress({ id: "" }, "TASK");
            }}
            bottom={"6%"}
            right={0}
          />
        </LeftSide>
        <CenterSide>
          <HeaderTable>
            <TextFont weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Расписание
            </TextFont>
          </HeaderTable>
          <CalcWrap>
            <TimeTableWrap>
              <Timetable handlePress={handlePress} filteredEventsOfDay={filteredEventsOfDay} isFetching={isLoading || isFetchingData} />
              <AddCircle press={() => handlePress({ id: "", repeat: false }, "MEETING")} bottom={"6%"} right={0} />
            </TimeTableWrap>
          </CalcWrap>
        </CenterSide>
        <RightSide>
          <HeaderTable right>
            <TextFont weight="700" size={24} lineHeight={32} color={Colors.LIGHT.text.darkGreen}>
              Заметки
            </TextFont>
          </HeaderTable>
          <NotesWrap>
            <Notes autoFocus={false} />
          </NotesWrap>
        </RightSide>
      </TabletContentWrap>
    );

  return (
    <Layout
      backgroundColor={Colors.LIGHT.white}
      header={!isTightMode && <Header handlePressEvent={handlePress} />}
      footerHeight={isTightMode ? "0px" : undefined}
      headerHeight={isTightMode ? "0px" : undefined}
    >
      {isTablet ? tablet() : mob()}

      <Prompting kind="day" keySetting="isPromptDay" />

      <ModalRenderWithCondition condition={isVisibleMeet}>
        <Modal
          isVisible={isVisibleMeet}
          meetId={currentMeet?.id ?? eventId}
          setIsVisible={setIsVisibleMeet}
          data={joinData ?? {}}
          isRepeating={currentMeet?.repeat}
          meetDate={currentMeet?.date}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleTask}>
        <ModalTask isVisible={isVisibleTask} id={taskEvent?.id ?? ""} setIsVisible={setIsVisibleTask} taskSubType={taskEvent?.taskType} />
      </ModalRenderWithCondition>
    </Layout>
  );
};

const MobContentWrap = styled.div`
  height: 100%;
`;
const ContentTable = styled.div<{ taskTable: boolean }>`
  height: 90%;
  margin: ${({ taskTable }) => (taskTable ? "0" : "12px 12px 0 12px")};
`;
const TabletContentWrap = styled.section`
  display: flex;
  height: 100%;
  background-color: ${Colors.LIGHT.white};
`;
const Side = styled.div`
  background-color: ${Colors.LIGHT.background.main};
  margin-top: 14px;
  height: 100%;
`;
const LeftSide = styled(Side)`
  width: 33%;
  margin-right: 1%;
  border-top-right-radius: 20px;
  position: relative;
`;
const RightSide = styled(Side)`
  width: 33%;
  margin-left: 1%;
  border-top-left-radius: 20px;
`;
const CenterSide = styled(Side)`
  width: 33%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
`;
const NotesWrap = styled.div`
  padding: 0 12px;
  height: 87%;
  overflow-y: auto;
`;
const TimeTableWrap = styled.div`
  height: 88%;
  margin: 12px;
`;
const CalcWrap = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
`;
const HeaderTable = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  background-color: ${Colors.LIGHT.disabled};
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  height: ${HEADER_HEIGHT};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${({ left }) =>
    left &&
    `
  border-top-left-radius: 0;
`}
  ${({ right }) =>
    right &&
    `
  border-top-right-radius: 0;
`}
`;
