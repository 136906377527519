import React, { useEffect, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { isEqual, xorWith } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import Toggle from "react-toggle";
import styled from "styled-components";

import { TextFont } from "@components/ui";
import { RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import { Notification } from "@ui/notification/Notification";
import { showToast } from "@utils/toast";

import { IPlanningWork } from "./types";

import { Calendar, ListTime } from ".";

export const PlanningWork = ({
  isVisible,
  setIsVisible,
  isTime = true,
  startDays = [],
  tempObject,
  onSave,
  selectedWorkDay,
  isMultiple = true,
  startDate,
  keyTitle,
  disable = { button: true, style: true },
  closeAfterPress = true,
  deadline,
  timeEnabled,
  handleData,
  disableAddTimeToggle = false,
  close3DotMenu,
  onRemindLater,
}: IPlanningWork) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [days, setDays] = useState<WorkDay[]>(startDays);
  const [localTimeEnabled, setLocalTimeEnabled] = useState(!!timeEnabled);
  const msgCancelExeption = useIntl().formatMessage({ id: "cancelException" });
  const title = useIntl().formatMessage({ id: keyTitle });

  useEffect(() => {
    setIsEdit((Boolean(xorWith(days, startDays, isEqual).length) && (isMultiple || isTime)) || timeEnabled !== localTimeEnabled);
  }, [days, localTimeEnabled]);

  const handlePress = (dateWeek: Dayjs, isDisable: boolean) => {
    if (isDisable && disable?.press) {
      return disable.press();
    }

    const isSelect = !!days.find((i: WorkDay) => i.date === dateWeek.format("YYYY-MM-DD"));

    if (isSelect) {
      if (isMultiple) handleRemoveDay(dateWeek);
    } else {
      if (isMultiple) handleAddDay(dateWeek);
      else handleReplaceDay(dateWeek);
    }
  };

  const handleReplaceDay = (date: Dayjs) => {
    const tempDays = [{ ...days[0], date: date.format("YYYY-MM-DD") }];

    if (isTime) {
      setDays(tempDays);
    } else {
      handleSave(tempDays);
    }
  };

  const handleAddDay = (date: Dayjs) => {
    const index = startDays.findIndex((i) => i.date === date.format("YYYY-MM-DD"));

    if (dayjs(date).isBefore(dayjs(), "date")) {
      showToast("pastDateSelected");
    }

    if (index === -1) {
      setDays([...days, { ...tempObject, date: date.format("YYYY-MM-DD") } as WorkDay]);
    } else {
      setDays([...days, startDays[index]]);
    }
  };

  const handleRemoveDay = (date: Dayjs) => {
    setDays([...days.filter((i: WorkDay) => i.date !== date.format("YYYY-MM-DD"))]);
  };

  const handleSave = (days: WorkDay[]) => {
    handleData?.("timeEnabled", localTimeEnabled);
    onSave(days, localTimeEnabled);
    if (closeAfterPress) {
      setIsVisible(false);
      close3DotMenu?.();
    }
    onRemindLater?.();
  };

  const handleCancel = () => {
    setDays(startDays ?? []);
    setIsCancel(false);
    setIsVisible(false);
    close3DotMenu?.();
    onRemindLater?.();
  };

  const handleClose = () => {
    setIsVisible(false);
    close3DotMenu?.();
  };

  return (
    <ModalUI isVisible={isVisible} onClose={() => (isEdit ? setIsCancel(true) : handleClose())} scrollEnable={false}>
      <HeaderModal
        title={title}
        leftSide={{ onPressClose: handleClose, onPressCancel: () => setIsCancel(true) }}
        rightSide={{ onPress: () => handleSave(days) }}
        isEdit={isEdit}
      />
      <div style={{ overflow: "auto", height: "95%" }}>
        <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 34, marginBottom: 20 }}>
          <Calendar
            days={days}
            currentDay={days[0]?.date ?? startDate}
            startDay={startDate}
            press={handlePress}
            type={isMultiple ? "multituple" : "default"}
            selectedWorkDay={selectedWorkDay}
            disable={disable}
            deadline={deadline}
          />

          <RenderWithCondition condition={!disableAddTimeToggle}>
            <StTimeSetSection>
              <TextFont style={{ display: "flex", alignItems: "center" }} type="bold" weight="700" size={16}>
                <FormattedMessage id="taskTimeSet" />
              </TextFont>
              <Toggle checked={localTimeEnabled} onChange={() => setLocalTimeEnabled((prev) => !prev)} icons={false} />
            </StTimeSetSection>
          </RenderWithCondition>

          <ListTime days={days} timeEnabled={localTimeEnabled} setDays={setDays} />
        </div>
      </div>
      <RenderWithCondition condition={isCancel}>
        <Notification type="confirm" phraseId={msgCancelExeption} onOk={handleCancel} onCancel={() => setIsCancel(false)} />
      </RenderWithCondition>
    </ModalUI>
  );
};

const StTimeSetSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: ${Colors.LIGHT.white};
  border-radius: 11px;
  margin-top: 10px;
`;
