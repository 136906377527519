import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as SvgInfoCircle } from "@assets/info-circle.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { RenderWithCondition } from "@components/hoc";
import { LoaderButtonRenderWithCondition } from "@components/hoc/LoaderButtonRenderWithCondition";
import { Colors } from "@theme/colors";
import EventHelperUtils from "@utils/event-helper.utills";
import { showToast } from "@utils/toast";

import { TextFont } from "./TextFont";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  setValue: (arg: string) => void;
  placeholder?: string;
  isDrop?: boolean;
  autoRenderDrop?: boolean;
  styleWrapper?: CSSProperties;
  styleInput?: CSSProperties;
  showCheckEmailsButton?: boolean;
  isCheckBtnHidden?: boolean;
  isCheckingForMultipleEvents?: boolean;
  multipleEmailsSearch?: boolean;
  onDrop?: () => void;
  onCheckEmailsPress?: () => void;
  onFocus?: () => void;
}
const eventHelperUtils = new EventHelperUtils();

const isProhibitedSymbolsRegex = /[^\w\s,;@.]/;

export const SearchInput: FC<IProps> = ({
  value,
  setValue,
  placeholder = "search",
  isDrop,
  autoRenderDrop,
  showCheckEmailsButton = false,
  isCheckBtnHidden = false,
  isCheckingForMultipleEvents = false,
  multipleEmailsSearch = true,
  onDrop,
  onFocus,
  onCheckEmailsPress,
  ...props
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const phSearch = useIntl().formatMessage({ id: placeholder });

  const inputRef = useRef<HTMLInputElement>(null);

  const renderIsDrop = autoRenderDrop ? !!value : true;

  const handleDrop = () => {
    setValue("");
    onDrop?.();
  };

  useEffect(() => {
    if (!value) return;
    /* Перевод курсора и фокуса на конец строки при copy-paste */
    eventHelperUtils.debounce(() => {
      inputRef.current.blur();
      inputRef.current.focus();
    }, 500);
  }, [value]);

  const input = (
    <div style={{ ...styles.searchInput, ...props.styleInput }}>
      <SvgSearch />
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={styles.input}
        placeholder={phSearch}
        maxLength={props.maxLength ?? 255}
        autoFocus={props.autoFocus ?? true}
        onFocus={onFocus ? onFocus : undefined}
        {...props}
      />

      <RenderWithCondition condition={multipleEmailsSearch}>
        <button onClick={() => setIsTooltipVisible(!isTooltipVisible)} data-tooltip-id="search-by-emails-info" style={{ marginRight: -8 }}>
          <SvgInfoCircle />
        </button>
      </RenderWithCondition>
    </div>
  );

  const isDropForEmailSearch = useMemo(() => {
    if (showCheckEmailsButton) return isCheckBtnHidden;

    return true;
  }, [showCheckEmailsButton, isCheckBtnHidden]);

  const onCheckEmailsPressWrapper = () => {
    if (isProhibitedSymbolsRegex.test(value)) {
      showToast("unableToParseEmailsString");

      return;
    }

    onCheckEmailsPress?.();
  };

  if (isDrop) {
    return (
      <>
        <div style={{ ...styles.wrapper, ...props.styleWrapper }}>
          {input}
          {renderIsDrop && isDropForEmailSearch && (
            <button onClick={handleDrop}>
              <TextFont size={16} color={Colors.LIGHT.text.accent}>
                <FormattedMessage id="drop" />
              </TextFont>
            </button>
          )}

          <RenderWithCondition condition={multipleEmailsSearch && showCheckEmailsButton && !isCheckBtnHidden}>
            <StLoaderButtonWrapperDiv>
              <LoaderButtonRenderWithCondition condition={isCheckingForMultipleEvents}>
                <button onClick={onCheckEmailsPressWrapper}>
                  <TextFont size={16} color={Colors.LIGHT.text.accent}>
                    <FormattedMessage id="check" defaultMessage="Проверить" />
                  </TextFont>
                </button>
              </LoaderButtonRenderWithCondition>
            </StLoaderButtonWrapperDiv>
          </RenderWithCondition>
        </div>

        <Tooltip
          id="search-by-emails-info"
          style={{ width: 240, backgroundColor: "#fff", boxShadow: `0 2px 6px ${Colors.LIGHT.shadowTooltip}` }}
          place="left"
        >
          <TextFont>
            <FormattedMessage
              id="hintLotOfMail"
              defaultMessage="Несколько e-mail адресов необходимо ввести через запятую, точку с запятой или пробел"
            />
          </TextFont>
        </Tooltip>
      </>
    );
  }

  return input;
};

const StLoaderButtonWrapperDiv = styled.div`
  width: 94px;
`;

const styles = {
  wrapper: {
    display: "flex",
    gap: 14,
    alignItems: "center",
  },
  searchInput: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: 38,
    borderRadius: 10,
    backgroundColor: "#ECF0EF",
    padding: "1px 8px",
  },
  input: {
    marginLeft: 6,
    width: "90%",
  },
};
