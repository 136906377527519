import React from "react";

import { ReactComponent as AnswerIcon } from "@assets/answer_yellow.svg";
import { ReactComponent as CheckIcon } from "@assets/check_green.svg";
import { ReactComponent as CancelIcon } from "@assets/close-red.svg";
import { ReactComponent as SvgClose } from "@assets/close.svg";
import { ReactComponent as SvgColorBucket } from "@assets/color-bucket.svg";
import { ReactComponent as SvgComment } from "@assets/comment.svg";
import { ReactComponent as CommentIcon } from "@assets/comment_blue.svg";
import { ReactComponent as SvgCopy } from "@assets/copy.svg";
import { ReactComponent as SvgDealWith } from "@assets/deal_with.svg";
import { ReactComponent as SvgDelegate } from "@assets/delegate.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgDone } from "@assets/done-circle-background.svg";
import { ReactComponent as SvgReadOne } from "@assets/done_thin.svg";
import { ReactComponent as SvgCalendar } from "@assets/month.svg";
import { ReactComponent as SvgMonth } from "@assets/month.svg";
import { ReactComponent as SvgMove } from "@assets/move.svg";
import { ReactComponent as ChangeStatusIcon } from "@assets/not-take.svg";
import { ReactComponent as NotAccessIcon } from "@assets/not_access.svg";
import { ReactComponent as SvgCancelDealWith } from "@assets/not_deal_with.svg";
import { ReactComponent as SvgNotEngaged } from "@assets/not_engaged.svg";
import { ReactComponent as NotReadyIcon } from "@assets/not_ready2.svg";
import { ReactComponent as PeopleIcon } from "@assets/people.svg";
import { ReactComponent as RefreshIcon } from "@assets/refresh.svg";
import { ReactComponent as SvgReturnWork } from "@assets/return_work.svg";
import { ReactComponent as SettingIcon } from "@assets/settings.svg";
import { ReactComponent as SvgTakeBack } from "@assets/takeBack.svg";
import globalState from "@utils/globalState";

export const bgItem = {
  COMPLETED: "#EFFAF4",
  NOT_COMPLETED: "#FFEFEF",
  NEED_MORE_TIME: "#EDF9E9",
};

export interface ISwipeButton {
  id: TKeysButtons;
  img: JSX.Element;
  messageId: string;
}

export interface IButton {
  onClick: () => void;
  disabled?: boolean;
  toast?: string;
  message?: string;
  color?: string;
}

export type TKeysButtons =
  | "ready"
  | "readyGoal"
  | "takeToWork"
  | "delegate"
  | "close"
  | "returnToWork"
  | "dealWith"
  | "cancelDealWith"
  | "needMoreTime"
  | "takeBack"
  | "comment"
  | "notEngaged"
  | "transfer"
  | "delete"
  | "continueAgain"
  | "didYesterday"
  | "readOne"
  | "view"
  | "notTookPlace"
  | "statusTakePlaceNo"
  | "attender"
  | "nonAttender"
  | "maybeAttender"
  | "remove"
  | "addNewMeet"
  | "cancel"
  | "deleteCalendar"
  | "delegateCalendar"
  | "closeCalendar"
  | "copyCalendar"
  | "returnToWorkCalendar"
  | "complete"
  | "AGREED"
  | "AGREED_WITH_REMARKS"
  | "FAMILIARIZED"
  | "REPEAT_AGREEMENT"
  | "COMPLETE_AGREEMENT"
  | "NOT_AGREED"
  | "SET_CATEGORY";

/**
 * Конфиг для всех возможных вариантов кнопок
 */
export const variantsButton: (props) => Record<TKeysButtons, ISwipeButton> = (props) => ({
  readOne: {
    id: "readOne",
    img: <SvgReadOne {...props} />,
    messageId: "readOne",
  },
  ready: {
    id: "ready",
    img: <SvgDone {...props} />,
    messageId: "readyTask",
  },
  readyGoal: {
    id: "readyGoal",
    img: <SvgDone {...props} />,
    messageId: "ready",
  },
  delete: {
    id: "delete",
    img: <SvgDelete {...props} />,
    messageId: "delete",
  },
  takeToWork: {
    id: "takeToWork",
    img: <SvgMove {...props} fill="#72AF5C" />,
    messageId: "takeToWork",
  },
  delegate: {
    id: "delegate",
    img: <SvgDelegate {...props} />,
    messageId: "delegate",
  },
  close: {
    id: "close",
    img: <SvgClose {...props} />,
    messageId: "close",
  },
  returnToWork: {
    id: "returnToWork",
    img: <SvgReturnWork {...props} />,
    messageId: "returnToWork",
  },
  dealWith: {
    id: "dealWith",
    img: <SvgDealWith {...props} />,
    messageId: "dealWith",
  },
  cancelDealWith: {
    id: "cancelDealWith",
    img: <SvgCancelDealWith {...props} />,
    messageId: "cancelDealWith",
  },
  takeBack: {
    id: "takeBack",
    img: <SvgTakeBack {...props} />,
    messageId: "takeBack",
  },
  needMoreTime: {
    id: "needMoreTime",
    img: <SvgCalendar {...props} />,
    messageId: "needMoreTime",
  },
  transfer: {
    id: "transfer",
    img: <SvgMove {...props} />,
    messageId: "transfer",
  },
  comment: {
    id: "comment",
    img: <SvgComment {...props} />,
    messageId: "comment",
  },
  notEngaged: {
    id: "notEngaged",
    img: <SvgNotEngaged {...props} />,
    messageId: "notEngaged",
  },
  didYesterday: {
    id: "didYesterday",
    img: <SvgDealWith />,
    messageId: "didYesterday",
  },
  continueAgain: {
    id: "continueAgain",
    img: <SvgMonth />,
    messageId: "continueAgain",
  },
  view: {
    id: "view",
    img: <SettingIcon width={16} height={16} />,
    messageId: "viewAction",
  },
  notTookPlace: {
    id: "notTookPlace",
    img: <NotReadyIcon width={14} height={14} />,
    messageId: "statusTookPlaceNo",
  },
  statusTakePlaceNo: {
    id: "statusTakePlaceNo",
    img: <NotAccessIcon width={14} height={14} />,
    messageId: "statusTakePlaceNo",
  },
  attender: {
    id: "attender",
    img: <CheckIcon width={14} height={14} />,
    messageId: "attender",
  },
  nonAttender: {
    id: "nonAttender",
    img: <CancelIcon width={14} height={14} />,
    messageId: "nonAttender",
  },
  maybeAttender: {
    id: "maybeAttender",
    img: <AnswerIcon width={14} height={14} />,
    messageId: "maybeAttender",
  },
  remove: {
    id: "remove",
    img: <SvgDelete width={16} height={16} />,
    messageId: "delete",
  },
  addNewMeet: {
    id: "addNewMeet",
    img: <PeopleIcon width={18} height={18} />,
    messageId: "meetSameParticipant",
  },
  cancel: {
    id: "cancel",
    img: <SvgClose width={14} height={14} />,
    messageId: "cancel",
  },
  cancelFutureStatus: {
    id: "cancelFutureStatus",
    img: <ChangeStatusIcon width={18} height={18} />,
    messageId: "cancelFutureMeetBack",
  },
  cancelPastStatus: {
    id: "cancelFutureStatus",
    img: <ChangeStatusIcon width={18} height={18} />,
    messageId: "cancelMeetBack",
  },
  removeCal: {
    id: "cancelFutureStatus",
    img: <SvgClose width={14} height={14} />,
    messageId: "removeCal",
  },
  deleteCalendar: {
    id: "deleteCalendar",
    img: <SvgDelete width={16} height={16} />,
    messageId: "delete",
  },
  delegateCalendar: {
    id: "delegateCalendar",
    img: <SvgDelegate width={16} height={16} />,
    messageId: "delegate",
  },
  closeCalendar: {
    id: "close",
    img: <SvgClose width={14} height={14} />,
    messageId: "close",
  },
  copyCalendar: {
    id: "copyCalendar",
    img: <SvgCopy width={14} height={14} />,
    messageId: "copyTask",
  },
  returnToWorkCalendar: {
    id: "returnToWork",
    img: <SvgReturnWork width={14} height={14} />,
    messageId: "returnToWork",
  },
  complete: {
    id: "complete",
    img: <SvgDone width={14} height={14} />,
    messageId: "readyTask",
  },
  AGREED: {
    id: "AGREED",
    img: <CheckIcon width={20} height={20} />,
    messageId: "oneSApprove",
  },
  FAMILIARIZED: {
    id: "FAMILIARIZED",
    img: <CheckIcon width={20} height={20} />,
    messageId: "oneSFamiliarize",
  },
  AGREED_WITH_REMARKS: {
    id: "AGREED_WITH_REMARKS",
    img: (
      <div style={{ width: 20, height: 20 }}>
        <CommentIcon />
      </div>
    ),
    messageId: "oneSApproveWithComments",
  },
  NOT_AGREED: {
    id: "NOT_AGREED",
    img: <CancelIcon width={20} height={20} />,
    messageId: "oneSNotApprove",
  },
  COMPLETE_AGREEMENT: {
    id: "COMPLETE_AGREEMENT",
    img: <CancelIcon width={20} height={20} />,
    messageId: "oneSCompleteAgreement",
  },
  COMPLETE: {
    id: "COMPLETE",
    img: <CheckIcon width={20} height={20} />,
    messageId: "oneSComplete",
  },
  RETURN: {
    id: "RETURN",
    img: <SvgReturnWork width={14} height={14} />,
    messageId: "oneSReturn",
  },
  EXECUTED: {
    id: "EXECUTED",
    img: <CheckIcon width={20} height={20} />,
    messageId: "oneSExecute",
  },
  REPEAT_AGREEMENT: {
    id: "REPEAT_AGREEMENT",
    img: <RefreshIcon width={20} height={20} />,
    messageId: "oneSRepeatAgreement",
  },
  SET_CATEGORY: {
    id: "SET_CATEGORY",
    img: <SvgColorBucket width={14} height={14} fill={props.color || globalState.colorSchema.grey} />,
    messageId: props.message || "setCategory",
  },
});
