import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { TypeTab } from "@components/screens/profile/types";
import { resetStore } from "@constants/logic";
import { TTaskSubType } from "@interfaces/businessTask.interface";

type TActiveTabProfile = TypeTab | "integrations";

interface IInitalState {
  task?: { id: string; type: TTaskSubType };
  meetingID?: string;
  activeTabProfile?: TActiveTabProfile;
}

const initialState: IInitalState = {
  task: undefined,
  meetingID: undefined,
  activeTabProfile: undefined,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setTaskID: (state, action: PayloadAction<{ id: string; type: TTaskSubType } | undefined>) => {
      state.task = action.payload;
    },
    setMeetingId: (state, action: PayloadAction<string | undefined>) => {
      state.meetingID = action.payload;
    },
    setActiveTabProfile: (state, action: PayloadAction<TActiveTabProfile>) => {
      state.activeTabProfile = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetStore, () => initialState);
  },
});

export const { setTaskID, setMeetingId, setActiveTabProfile } = notificationSlice.actions;
export default notificationSlice.reducer;
