// @ts-nocheck
import { createAsyncThunk } from "@reduxjs/toolkit";

import { ICreateGoalDTO, IGoal, TypeStatusGoal } from "@interfaces/businessGoals.interface";
import { businessGoalsService } from "@services";
import { getError } from "@store/getError";
import { showToast } from "@utils";

interface IUpdateGoal {
  id: string;
  data: Record<string, string>;
  yearMonth: string;
}

interface IMoveDateGoal {
  id: string;
  yearMonth: string;
}

interface IMoveMonthGoal {
  id: string;
  fromYearMonth: string;
  toYearMonth: string;
}

interface IUpdateStatusGoal {
  id: string;
  status: TypeStatusGoal;
}

export const getList: any = createAsyncThunk("businessGoals/getList", async (yearMonth: string, thunkAPI) => {
  try {
    const response = await businessGoalsService.getList(yearMonth);
    return { response };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateList: any = createAsyncThunk("businessGoals/updateList", async (data: IGoal[], thunkAPI) => {
  try {
    await businessGoalsService.updateList(data);
    return { data };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const create: any = createAsyncThunk("businessGoals/create", async (data: ICreateGoalDTO, thunkAPI) => {
  try {
    const response = await businessGoalsService.create(data);
    return { response, yearMonth: data.yearMonth };
  } catch (error: any) {
    showToast("somethingWentWrong", "error");
    return getError(error, thunkAPI);
  }
});

export const update: any = createAsyncThunk("businessGoals/update", async ({ id, data, yearMonth }: IUpdateGoal, thunkAPI) => {
  try {
    const response = await businessGoalsService.update(id, data);
    return { response, id, yearMonth };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const deleteGoal: any = createAsyncThunk("businessGoals/deleteGoal", async (id: string, thunkAPI) => {
  try {
    const response = await businessGoalsService.delete(id);

    return { response, id, notifyText: "businessDeleteSuccess" };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const moveDate: any = createAsyncThunk("businessGoals/moveDate", async ({ id, yearMonth }: IMoveDateGoal, thunkAPI) => {
  try {
    await businessGoalsService.moveDate(id, yearMonth);

    return { id, notifyText: "transferSuccess" };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const updateStatus: any = createAsyncThunk("businessGoals/updateStatus", async ({ id, status }: IUpdateStatusGoal, thunkAPI) => {
  try {
    const month = thunkAPI.getState().date.date;
    await businessGoalsService.updateStatus(id, status);

    return { notifyText: status === "DONE" ? "goalAchived" : "goalResumed", id, status, month };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const continueGoal: any = createAsyncThunk("businessGoals/continueGoal", async ({ id, yearMonth }: IMoveDateGoal, thunkAPI) => {
  try {
    await businessGoalsService.continue(id, yearMonth);
    return { id, yearMonth, notifyText: "businessGoalMonthAddSuccess" };
  } catch (error: any) {
    return getError(error, thunkAPI);
  }
});

export const moveMonth: any = createAsyncThunk(
  "businessGoals/moveMonth",
  async ({ id, fromYearMonth, toYearMonth }: IMoveMonthGoal, thunkAPI) => {
    try {
      await businessGoalsService.moveMonth(id, fromYearMonth, toYearMonth);
      thunkAPI.dispatch(getList(fromYearMonth));
      return { id, notifyText: "transferSuccess" };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
