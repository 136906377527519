import React, { memo, useState, FC, useEffect, useCallback, MouseEvent } from "react";

import styled from "styled-components";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { RenderWithCondition } from "@hoc";
import { Colors } from "@theme/colors";
import { Checkbox, TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";

import { LetterAvatar } from "../components/LetterAvatar";

import { TExtendedOnSave } from "./Item";

interface IProps {
  item: string;
  isRenderCheckbox?: boolean;
  isAdded?: boolean | undefined;
  setIsCreationFormVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onPress: (email: string) => void;
  onSave?: TExtendedOnSave;
  setEmailToCreateUser?: React.Dispatch<React.SetStateAction<string>>;
}

export const EmailItem: FC<IProps> = memo(function Item({
  item,
  isRenderCheckbox = true,
  isAdded = false,
  onPress,
  setIsCreationFormVisible,
  setEmailToCreateUser,
}: IProps) {
  const [isItemChecked, setIsItemChecked] = useState<boolean>(isAdded);

  const openCreationForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (setIsCreationFormVisible) {
      setIsCreationFormVisible(true);
      setEmailToCreateUser?.(item);
    }
  };

  useEffect(() => {
    setIsItemChecked(isAdded);
  }, [isAdded]);

  const handleTaskCheckBox = useCallback(
    (item: string) => {
      onPress?.(item);
    },
    [onPress],
  );

  return (
    <Button onClick={() => handleTaskCheckBox(item)}>
      <ContentWrap>
        <LetterAvatar userName={item[0]?.toUpperCase()} lastName={item[1]?.toUpperCase()} translucent={isItemChecked} />

        <BlockText>
          <TextUserName size={16} weight="700" color={isItemChecked && Colors.LIGHT.grey}>
            {item}
          </TextUserName>
        </BlockText>

        <AddUserButton onClick={openCreationForm}>
          <SvgPlus height={14} width={14} fill={Colors.DARK.placeholder.main} />
        </AddUserButton>

        <RenderWithCondition condition={isRenderCheckbox}>
          <Checkbox onChange={() => handleTaskCheckBox(item)} isChecked={isItemChecked} />
        </RenderWithCondition>
      </ContentWrap>
    </Button>
  );
},
arePropsEqual);

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.white};
  padding: 8px 12px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
`;
const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
const BlockText = styled.div`
  flex: 1;
  overflow: hidden;
`;
const TextUserName = styled(TextFont)`
  text-align: left;
`;
const AddUserButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 30px;
  margin-right: 5px;
`;
