import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Colors } from "@theme/colors";
import { ContentEditable, ModalUI, TextFont } from "@ui";

interface IProps {
  value: string;
  setValue: (arg: string) => void;
  isVisible: boolean;
  onClose: () => void;
  onSend: () => void;
}

export const ModalComment: FC<IProps> = ({ value, setValue, isVisible, onClose, onSend }) => {
  const handleSend = () => {
    onSend();
    onClose();
  };
  return (
    <ModalUI isVisible={isVisible} className="bottomModal" onClose={onClose}>
      <ContentWrap>
        <ContentEditable
          style={commentEnterStyle}
          onChange={(e) => setValue(e)}
          value={value}
          autoFocus
          placeholder="Комментарий организатору"
        />
        <ButtonsLine>
          <ButtonModal onClick={onClose} backColor={Colors.LIGHT.lighGrey}>
            <TextFont size={18} type="bold" color={Colors.LIGHT.text.grey}>
              <FormattedMessage id="cancelClose" />
            </TextFont>
          </ButtonModal>
          <ButtonModal onClick={handleSend}>
            <TextFont size={18} type="bold" color={Colors.LIGHT.white}>
              <FormattedMessage id="send" />
            </TextFont>
          </ButtonModal>
        </ButtonsLine>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 0 12px;
  gap: 16px;
`;
const ButtonsLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const ButtonModal = styled.button<{ backColor?: string }>`
  display: flex;
  border-radius: 10px;
  justify-content: center;
  padding: 16px 0;
  width: 45%;
  background-color: ${(props) => (props.backColor ? props.backColor : Colors.LIGHT.green)};
`;
const CommentEnter = styled(ContentEditable)`
  border: 1px solid ${Colors.LIGHT.background.grey};
  border-radius: 10px;
  padding: 16px 12px;
  outline: none;
`;

const commentEnterStyle = { border: `1px solid ${Colors.LIGHT.background.grey}`, borderRadius: 10, padding: "16px 12px" };
