import React, { FC, memo, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgCancel } from "@assets/cancel.svg";
import { ReactComponent as SvgNotTake } from "@assets/not-take.svg";
import { ReactComponent as SvgOutlook } from "@assets/outlook.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { RenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

interface IProps {
  meet: IMeet;
  statusParticipant?: IMeetParticipants;
  pressDotes: () => void;
  isRenderDotes: boolean;
}

export const ViewSaveHeader: FC<IProps> = memo(function ViewSaveHeader({ meet, statusParticipant, pressDotes, isRenderDotes }) {
  const meetingStatus = useMemo(() => {
    if (!meet.active) return "canceled";
    if (statusParticipant?.meetingStatus === "DID_NOT_TAKE_PLACE") return "didNotTake";
    if (statusParticipant?.meetingStatus === "WILL_NOT_TAKE_PLACE") return "willNotTake";

    return null;
  }, [meet.active, statusParticipant?.meetingStatus]);

  const config: Record<"canceled" | "didNotTake" | "willNotTake" | "outlook", { title: string; icon: JSX.Element; colorText: string }> = {
    canceled: {
      title: "statusCancelForAll",
      icon: <SvgCancel width={17} height={17} />,
      colorText: Colors.LIGHT.red,
    },
    didNotTake: {
      title: "statusTookPlaceNo",
      icon: <SvgNotTake width={17} height={17} fill={Colors.LIGHT.yellow} />,
      colorText: Colors.LIGHT.yellow,
    },
    willNotTake: {
      title: "statusTakePlaceNo",
      icon: <SvgNotTake width={17} height={17} fill={Colors.LIGHT.yellow} />,
      colorText: Colors.LIGHT.yellow,
    },
    outlook: {
      title: "Событие из Outlook",
      icon: <SvgOutlook width={17} height={17} fill={Colors.LIGHT.blue} />,
      colorText: Colors.LIGHT.blue,
    },
  };

  const isEditAllGroup = useMemo(() => {
    const isGroupAll = meet?.presetGroups?.length && meet?.presetGroups?.[0]?.type === "ALL_IN_SPACE";
    const isOwner = meet.currentParticipantStatus === "OWNER";
    return !(isGroupAll && !isOwner);
  }, [meet]);

  return (
    <ContentWrap>
      <ContentWrapStatuses>
        {meet.externalId && (
          <IconWrap>
            {config["outlook"].icon}
            <IconText color={config["outlook"]?.colorText}>
              <FormattedMessage id={config["outlook"]?.title} />
            </IconText>
          </IconWrap>
        )}
        <RenderWithCondition condition={meetingStatus}>
          <IconWrap>
            {meetingStatus && config[meetingStatus].icon}
            <IconText color={config[meetingStatus]?.colorText}>
              <FormattedMessage id={config[meetingStatus]?.title} />
            </IconText>
          </IconWrap>
        </RenderWithCondition>
      </ContentWrapStatuses>
      <RenderWithCondition condition={!meetingStatus && isRenderDotes && isEditAllGroup}>
        <ButtonWrap onClick={pressDotes}>
          <SvgDotes fill={Colors.LIGHT.white} />
        </ButtonWrap>
      </RenderWithCondition>
    </ContentWrap>
  );
});

const ButtonWrap = styled.button`
  align-self: flex-start;
`;
const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 9px;
  border-radius: 55px;
  align-items: center;
  align-self: flex-start;
  background-color: ${Colors.LIGHT.white};
  margin-bottom: 6px;
  margin-right: 10px;
  gap: 5px;
`;
const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentWrapStatuses = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconText = styled(TextFont)<{ color: string }>`
  font-size: 14px;
  color: ${(props) => props.color};
`;
