/* eslint-disable @typescript-eslint/no-empty-function */
import axios, { AxiosResponse, CancelTokenSource } from "axios";

import { http } from "@api/httpClient";
import { ITask } from "@interfaces/businessGoals.interface";
import { IFilter } from "@interfaces/businessTask.interface";
import { OneSResolutions } from "@screens/day/components/ListTasks/Modal/components/viewSave/components/resolutionOneSActionButtons/constants";

export interface IMoveDate {
  id: string;
  date: string;
  newDate: string;
  isPersonalTask: boolean;
}

export interface IClose {
  id: string;
  comment: string;
  reason: string;
}

export interface INeedMoreTime {
  additionalDate: string;
  currentDate: string;
}

export interface IAddWorkDay {
  currentDate: string;
  additionalDate: string;
}

export interface ICommentWorkDay {
  date: string;
  comment: string;
}
export interface IUpdatePosition {
  date: string;
  data: { id: string; position: number }[];
  result: Record<string, ITask[]>;
  isPersonalTask: boolean;
}

export interface IGroupedList {
  date: string;
  total?: number;
  current?: number;
  next?: number;
  notLoader?: boolean;
}

export class BusinessTasksService {
  source: CancelTokenSource;
  lastRequest: CancelTokenSource | undefined;

  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
  constructor() {
    this.source = axios.CancelToken.source();
  }

  getGroupedList = async (params: IGroupedList): Promise<any> => {
    if (this.lastRequest) {
      this.source.cancel();
    }

    this.source = axios.CancelToken.source();

    try {
      const response = await http.get(
        `/api/api-gateway/v1/common-tasks/grouped-list?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`,
        {
          cancelToken: this.source.token,
        },
      );

      this.lastRequest = this.source;

      return response;
    } catch (e) {
      return e;
    }
  };

  getFilteredList = async (data: Partial<IFilter>): Promise<any> => await http.post("api/api-gateway/v1/common-tasks/view/all-tasks", data);

  getList = async (goalId: string): Promise<any> => await http.get(`/api/crud/v1/business-tasks/list?businessGoalId=${goalId}`);

  updatePosition = async ({ data, date }: IUpdatePosition): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/list/${date}`, data);

  get = async (id: string): Promise<any> => await http.get(`/api/api-gateway/v1/business-tasks/${id}`);

  create = async (data: Record<string, string>): Promise<any> => await http.post("/api/api-gateway/v1/business-tasks", data);

  createUnplanned = async (data: Record<string, string>): Promise<any> =>
    await http.post("/api/api-gateway/v1/business-tasks/unplanned", data);

  createControl = async (data: Record<string, string>): Promise<any> => await http.post("/api/api-gateway/v1/business-tasks/control", data);

  update = async (id: string, data: Record<string, string>): Promise<any> =>
    await http.patch(`/api/api-gateway/v1/business-tasks/${id}`, data);

  delete = async (id: string): Promise<any> => await http.delete(`/api/api-gateway/v1/business-tasks/v1/business-tasks/${id}`);

  complete = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/complete`);

  reopen = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/reopen`);

  pause = async (id: string, comment: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/pause`, { comment });

  close = async ({ id, comment, reason }: IClose): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/close`, {
      comment,
      reason,
    });

  clone = async (id: string): Promise<any> => await http.post(`/api/api-gateway/v1/business-tasks/${id}/clone`);

  moveDate = async ({ id, date, newDate }: IMoveDate): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/${date}/move-date/${newDate}`);

  workDayComplete = async (id: string, date: string): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/complete/${date}`);

  workDayNotComplete = async (id: string, date: string, body?: any): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/not-complete`, { ...body, date });

  workDayNeedMoreTime = async (id: string, body?: INeedMoreTime): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/need-more-time`, body);

  workDayCancelComplete = async (id: string, date: string): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/not-complete/${date}`);

  addWorkDay = async (id: string, body: IAddWorkDay): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/add`, body);

  takeToWork = async (id: string, date: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/work-day/add/${date}`);

  commentWorkDay = async (id: string, body: ICommentWorkDay): Promise<any> =>
    await http.post(`/api/crud/v1/business-tasks/${id}/work-day/comment`, body);

  deleteWorkDay = async (id: string, date: string): Promise<any> => await http.delete(`/api/crud/v1/business-tasks/${id}/work-day/${date}`);

  markWorkDay = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/work-day/mark`);

  forgetWorkDay = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/work-day/forget`);

  hideDelegated = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/delegated/hide`);

  forgetAllTransferred = async (): Promise<any> => await http.post("/api/crud/v1/business-tasks/work-day/forget");

  forgetAllDelegated = async (): Promise<any> => await http.post("/api/crud/v1/business-tasks/delegated/hide");

  delegate = async (id: string, userId: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/delegate/${userId}`);

  takeBack = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/take-back`);

  hideAllTakenBack = async (): Promise<any> => await http.post("/api/crud/v1/business-tasks/taken-back/hide");

  hideTakenBack = async (id: string): Promise<any> => await http.post(`/api/crud/v1/business-tasks/${id}/taken-back/hide`);

  getStatistic = async (date: string): Promise<any> => await http.get(`/api/api-gateway/v1/business-tasks/statistics/day/${date}`);

  attachFileToTask = async (taskId: string, fileId: string) => await http.post(`/api/crud/v1/business-tasks/${taskId}/file/${fileId}`);

  addComment = async (taskId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.post(`/api/crud/v1/business-tasks/${taskId}/comments`, { comment });

  editComment = async (taskId: string, commentId: string, comment: string): Promise<AxiosResponse<{ comment: string }, unknown>> =>
    await http.patch(`/api/crud/v1/business-tasks/${taskId}/comments/${commentId}`, { comment });

  deleteComment = async (taskId: string, commentId: string): Promise<AxiosResponse<unknown, unknown>> =>
    await http.delete(`/api/crud/v1/business-tasks/${taskId}/comments/${commentId}`);

  setOneSResolution = async (taskId: string, resolution: OneSResolutions, comment?: string) =>
    await http.post(`/api/crud/v1/business-tasks/one-s/${taskId}/resolution`, { comment, resolution });

  changeAuthor = async (id: string, userId: string) => await http.patch(`api/crud/v1/business-tasks/${id}/assign-author-to/${userId}`);
}

export const businessTasks = new BusinessTasksService();
