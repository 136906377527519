import React, { CSSProperties, FC, memo, useEffect, useState } from "react";

import styled from "styled-components";

import defaultAvatar from "@assets/avatar_temp.png";
import { RenderWithCondition } from "@hoc";
import { fileService } from "@services/file.service";
import { userAvatarStyles } from "@shared/userAvatar";
import { AvatarPreloader } from "@shared/userAvatar/LazyAvatar";
import { Colors } from "@theme/colors";

interface IUserAvatar {
  size?: number;
  avatarFileId?: string;
  containerStyles?: CSSProperties;
}

export const ProfileUserAvatar: FC<IUserAvatar> = memo(function UserAvatar({
  size = 95,
  avatarFileId,
  containerStyles,
  ...props
}: IUserAvatar) {
  const [avatar, setAvatar] = useState<string>();
  const handleUserProfileAvatar = async () => {
    if (!avatarFileId) return;

    const avatarURI = await fileService.getAvatar(avatarFileId);

    setAvatar(avatarURI);
  };

  useEffect(() => {
    handleUserProfileAvatar();
  }, [avatarFileId]);

  return (
    <div {...props}>
      <RenderWithCondition condition={!avatarFileId}>
        <ImageItem src={defaultAvatar} alt="user avatar" />
      </RenderWithCondition>

      <RenderWithCondition condition={avatarFileId}>
        {avatar ? <ImageItem src={avatar} alt="user avatar" /> : <AvatarPreloaderCustom />}
      </RenderWithCondition>
    </div>
  );
});

const ImageItem = styled.img`
  width: 95px;
  height: 95px;
  border-radius: 95px;
  object-fit: cover;
  border: 3px solid ${Colors.LIGHT.white};
  margin-bottom: 10px;
`;
const AvatarPreloaderCustom = styled(AvatarPreloader)`
  width: 95px;
  height: 95px;
  border-radius: 95px;
  object-fit: cover;
  border: 3px solid ${Colors.LIGHT.white};
  margin-bottom: 10px;
`;
