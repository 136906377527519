import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

import { RenderWithCondition } from "@hoc";
import { configWeek, daysOfWeekDefault } from "@screens/day/components/header/modal/CalendarCustom";
import { MonthsOfYear } from "@screens/mounth/components/header/YearMonthsOverview";
import { useGetCalendarEventsListQuery } from "@services/calendarApi";
import { TEventsOfMonth } from "@store/calendar/slice";
import { selectCurrentDate } from "@store/screenDay";
import { TextFont, ArrowSvg } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDate } from "@utils/toFormatTime";

import { ICalendar, IDaysOfSelectedMonth, TWeek } from "./types";
import { stylesForDay } from "./utils";

export const Calendar = ({
  press,
  days = [],
  currentDay,
  type = "default",
  selectedWorkDay = "",
  startDay,
  yearOverview,
  disable = { button: true, style: true },
  deadline,
  highlightedDay,
}: ICalendar) => {
  const [eventsOfMonth, setEventsOfMonth] = useState<Record<string, TEventsOfMonth> | null>(null);
  const [toggleMonthYearOverview, setToggleMonthYearOverview] = useState(yearOverview);
  const dateNow = useSelector(selectCurrentDate);
  const selectedDay = selectedWorkDay ?? currentDay;
  const [currentDate, setCurrentDate] = useState(selectedDay ? dayjs(selectedDay) : dateNow);
  const [skipRequest, setSkipRequest] = useState(false);

  const titleSelectedMonth = `${dayjs.months()[currentDate.month()]} ${currentDate.year()}`;

  const periodStart = useMemo(() => {
    if (currentDate.isBefore(dateNow)) return dateNow.format("YYYY-MM-DD");

    return currentDate.isSame(dateNow) ? currentDate.format("YYYY-MM-DD") : currentDate.startOf("month").format("YYYY-MM-DD");
  }, [currentDate, dateNow]);

  const { data: eventsOfMonthRaw } = useGetCalendarEventsListQuery(
    {
      startDate: periodStart,
      endDate: currentDate.endOf("month").format("YYYY-MM-DD"),
    },
    {
      skip: skipRequest,
    },
  );

  const pressMonthHandler = useCallback((date: Dayjs) => {
    setToggleMonthYearOverview(false);
    setCurrentDate(date);
  }, []);

  const handleMonthChange = (direction: "forward" | "backward") => {
    const date = direction === "backward" ? currentDate.subtract(1, "month") : currentDate.add(1, "month");

    setSkipRequest(date.isBefore(dateNow));
    setCurrentDate(date);
  };

  const currentMonth = useMemo(() => {
    if (eventsOfMonth) {
      return eventsOfMonth[currentDate.format("YYYY-MM")];
    }
    return null;
  }, [eventsOfMonth, currentDate]);

  useEffect(() => {
    if (!eventsOfMonthRaw) return;
    setEventsOfMonth({ [currentDate.format("YYYY-MM")]: eventsOfMonthRaw });
  }, [eventsOfMonthRaw]);

  const month: Array<TWeek> = useMemo(() => {
    const selectedYear = currentDate.year();
    const selectedMonth = currentDate.month();
    const firstDayCurrentMonth = dayjs().year(selectedYear).month(selectedMonth).date(1);
    let isEndMonth = false;
    const resultMonth: Array<TWeek> = [];
    let tempWeek = 0;
    while (isEndMonth === false) {
      const week: TWeek = {};

      daysOfWeekDefault.forEach((day: string, index) => {
        const firstDayIndexWeek = firstDayCurrentMonth.weekday() + 1;
        const temp = index - firstDayIndexWeek + 1;
        const value = firstDayCurrentMonth.add(temp + tempWeek, "day");
        week[day] = value.month() === firstDayCurrentMonth.month() ? value : null;
        if (
          dayjs(firstDayCurrentMonth)
            .add(temp + tempWeek - 1, "day")
            .get("month") !==
            dayjs(firstDayCurrentMonth)
              .add(temp + tempWeek, "day")
              .get("month") &&
          tempWeek > 20
        ) {
          isEndMonth = true;
        }
      });
      tempWeek += 7;
      resultMonth.push(week);
    }

    return resultMonth;
  }, [currentDate]);

  const DaysOfSelectedMonth: FC<IDaysOfSelectedMonth> = ({ onMonthTitlePress }) => (
    <>
      <div style={styles.headerArrow}>
        <button onClick={onMonthTitlePress} style={{ display: "flex", alignItems: "center" }}>
          <TextFont style={styles.headerTitle}>{titleSelectedMonth}</TextFont>
          <ArrowSvg type="right" fill="#297952" width={18} height={18} />
        </button>

        <div style={{ width: "15%", display: "flex", justifyContent: "space-between" }}>
          <button onClick={() => handleMonthChange("backward")} style={{ width: 47, alignItems: "flex-end" }}>
            <ArrowSvg type="left" fill="#297952" width={26} height={26} />
          </button>

          <button onClick={() => handleMonthChange("forward")} style={{ width: 41 }}>
            <ArrowSvg type="right" fill="#297952" width={26} height={26} />
          </button>
        </div>
      </div>
      <div style={styles.header}>
        {Object.entries(configWeek).map(([key, value]) => (
          <div style={styles.cell} key={key}>
            <TextFont style={styles.text}>{value.toUpperCase()}</TextFont>
          </div>
        ))}
      </div>
      <div>
        {month.map((week, index) => (
          <div style={styles.bodyRow} key={index}>
            {Object.entries(week).map(([, dateWeek]) => {
              if (!dateWeek) return <div style={styles.bodyCell} />;

              const config = {
                isHighlighted: highlightedDay ? toFormatDate(dateWeek) === toFormatDate(dayjs(highlightedDay)) : false,
                isSelect: {
                  multituple: !!days.find((i) => i.date === toFormatDate(dateWeek)),
                  default: !!days.find((i) => i.date === toFormatDate(dateWeek)),
                },
                isDisabled: {
                  multituple: toFormatDate(dateWeek) < toFormatDate(dayjs()),
                  default: startDay ? dateWeek < dayjs(startDay) : false,
                },
              };

              const { wrapper, text } = stylesForDay({
                isSelect: config.isSelect[type],
                isDisabled: config.isDisabled[type],
                isPastDay: dayjs(dateWeek).isBefore(dayjs(), "day"),
                isToday: toFormatDate(dateNow) === toFormatDate(dateWeek),
                isDeadline: toFormatDate(dayjs(deadline ?? "")) === toFormatDate(dateWeek),
                deadline,
                enableDisable: disable?.style,
              });
              const eventsLength = currentMonth ? currentMonth[toFormatDate(dateWeek)]?.length : null;
              const isHighlighted = config.isHighlighted;

              return (
                <button
                  key={String(dateWeek)}
                  style={{ ...styles.bodyCell, position: "relative" }}
                  disabled={config.isDisabled[type] && disable?.button}
                  onClick={() => press(dateWeek, config.isDisabled[type])}
                >
                  <div
                    style={{
                      ...styles.bodyTextWrap,
                      position: "relative",
                      ...wrapper,
                      ...(isHighlighted && styles.highlightedDay),
                    }}
                  >
                    <TextFont
                      style={{
                        ...styles.textDay,
                        ...text,
                      }}
                    >{`${new Date(String(dateWeek)).getDate()}`}</TextFont>
                    <RenderWithCondition condition={!!eventsLength}>
                      <div style={{ ...styles.bodyCellEvents, ...wrapper, position: "absolute" }}>
                        <TextFont size={8} style={text}>
                          {eventsLength}
                        </TextFont>
                      </div>
                    </RenderWithCondition>
                  </div>
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div style={styles.container}>
      {toggleMonthYearOverview ? (
        <MonthsOfYear onMonthPress={pressMonthHandler} />
      ) : (
        <DaysOfSelectedMonth onMonthTitlePress={() => setToggleMonthYearOverview(true)} />
      )}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: "13px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "26px",
    paddingBottom: "9px",
  },
  headerArrow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "16px",
    marginBottom: "12px",
  },
  headerTitle: {
    fontWeight: "700",
    fontSize: "18px",
    "text-transform": "capitalize",
    marginRight: "4px",
  },
  header: {
    display: "flex",
    marginTop: "4px",
    marginBottom: "4px",
  },
  cell: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
    width: "14.5%",
  },
  text: {
    color: "#B3BEB8",
  },
  bodyRow: {
    display: "flex",
  },
  bodyCell: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "8px",
    width: "14.5%",
  },
  bodyCellEvents: {
    bottom: 0,
    right: -4,
    width: 15,
    height: 15,
    borderRadius: 13,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${globalState.colorSchema.white}`,
  },
  bodyTextWrap: {
    display: "flex",
    "flex-direction": "column",
    width: "40px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    padding: "2px",
  },
  cellSelected: {
    backgroundColor: "#5F6E67",
  },
  textSelected: {
    color: "#fff",
  },
  textDay: {
    fontSize: "20px",
    fontWeight: "400",
  },
  highlightedDay: {
    borderColor: "#FB9231",
    borderStyle: "solid",
    borderWidth: 1,
  },
};
