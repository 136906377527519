import React, { CSSProperties } from "react";

import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";

import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { Colors } from "@theme/colors";

import { TextFont } from "../TextFont";

interface ILeftSide {
  /** text for close button */
  title?: string;

  /** defines custom element that has the highest priority */
  element?: JSX.Element;

  /** defines whether the "cancel" button will be hidden */
  isHideCancel?: boolean;

  /** press event handler on "close" button */
  onPressClose: (...arg: unknown[]) => void;

  /** press event handler on "cancel" button */
  onPressCancel?: (...arg: unknown[]) => void;

  /** defines style for "cancel/close" button */
  styleBtn?: CSSProperties;

  /** defines style for "cancel/close" text */
  styleText?: CSSProperties;
}

interface IRightSide {
  /** text for save button */
  title?: string;

  /** defines custom element that has the highest priority */
  element?: JSX.Element;

  /** press event handler on "save" button */
  onPress?: (...arg: unknown[]) => void;

  /** defines style for "save" button */
  styleBtn?: CSSProperties;

  /** defines style for "save" text */
  styleText?: CSSProperties;

  /** defines whether the loader will be displaying */
  isLoading?: boolean;
}

interface IHeaderModal {
  /** text in center side */
  title: string;

  /** defines changes in the current modal, responsible for switching buttons "close"/"save"/"cancel" */
  isEdit?: boolean;

  /** defines style for component */
  styleContainer?: CSSProperties;

  /** defines color for text in buttons */
  themeTextColor?: string;

  /** interface for "cancel", "close" button */
  leftSide: ILeftSide;

  /** interface for "save" button */
  rightSide?: IRightSide;
}

/**
 * @description Renders a component.
 * @param {IHeaderModal} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * <HeaderModal
 *    title="newTask"
 *    leftSide={{ onPressClose: handleCloseWrapper, onPressCancel: handleCancelWrapper }}
 *    isEdit={isEdit}
 *    rightSide={{ onPress: pressSave, isLoading: isLoadingTask }}
 *    themeTextColor={theme.text.main}
 *    styleContainer={{ backgroundColor: theme.background.main }}
 * />
 */

export const HeaderModal = ({
  title,
  isEdit = false,
  styleContainer,
  themeTextColor = Colors.LIGHT.text.main,
  leftSide,
  rightSide,
  ...props
}: IHeaderModal) => {
  const close = leftSide?.element ?? (
    <Block onClick={leftSide?.onPressClose} style={leftSide?.styleBtn}>
      <Title color={themeTextColor} weight="400" style={leftSide?.styleText}>
        <FormattedMessage id={leftSide?.title ?? "close"} />
      </Title>
    </Block>
  );

  const save = rightSide?.element ?? (
    <LoaderButtonRenderWithCondition condition={rightSide?.isLoading}>
      <Block onClick={rightSide?.onPress} style={rightSide?.styleBtn}>
        <Title color={themeTextColor} style={rightSide?.styleText}>
          <FormattedMessage id={rightSide?.title ?? "save"} />
        </Title>
      </Block>
    </LoaderButtonRenderWithCondition>
  );

  return (
    <Modal style={{ ...styleContainer }} {...props}>
      {!isEdit || leftSide?.isHideCancel ? (
        close
      ) : (
        <Block onClick={leftSide?.onPressCancel} style={leftSide?.styleBtn}>
          <Title color={themeTextColor} weight="400" style={leftSide?.styleText}>
            <FormattedMessage id="cancel" />
          </Title>
        </Block>
      )}
      <Title color={themeTextColor} weight="700">
        <FormattedMessage id={title} />
      </Title>

      {isEdit && rightSide ? save : <Block />}
    </Modal>
  );
};

const Modal = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  border-bottom-width: 0.5px;
  border-bottom-color: rgba(0, 0, 0, 0.3);
  border-bottom-style: solid;
  padding-top: 20px;
  padding-bottom: 9px;
`;

const Block = styled.button`
  justify-self: center;
`;

const Title = styled(TextFont)<{ color: string }>`
  justify-self: center;
  font-size: 16px;
  color: ${(props) => props.color};
`;
