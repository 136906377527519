import React, { FC, useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { TTypeOfAction, TTypeParticipants } from "@interfaces/meet.interface";
import { LineHorizontal } from "@shared";
import { Colors } from "@theme/colors";
import { ArrowSvg, ModalUI } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";

import { AttendanceStatuses } from "../../types";
import { getButtonContentByStatus } from "../ButtonContent";
import { ModalComment } from "../ModalComment";

import {
  BilletAttendance,
  BottomBlock,
  ContentWrap,
  InnerContainer,
  RoundButton,
  StartCenterWrapper,
  StatusButton,
  TabText,
  TextBottom,
  TextComments,
  TextHeader,
  TopBlock,
} from "./styled";

export interface IProps {
  isSerieOrException: boolean;
  currentStatus: TTypeParticipants;
  isStatusSet?: boolean;
  onPress: (status: TTypeParticipants, comment: string, type?: TTypeOfAction) => Promise<void>;
  setTypeAction: (arg: TTypeOfAction) => void;
  typeAction: TTypeOfAction;
  participantStatusComment: string;
}

export const CurrentAttendanceStatus: FC<IProps> = ({
  currentStatus,
  isStatusSet,
  onPress,
  isSerieOrException,
  setTypeAction,
  typeAction,
  participantStatusComment,
}) => {
  const options = [AttendanceStatuses.ATTENDER, AttendanceStatuses.NON_ATTENDER, AttendanceStatuses.MAYBE_ATTENDER];
  const mapTitles = ["onlyIntoThisMeeting", "intoAllMeetingInSeries"];
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const [currentOption, setCurrentOption] = useState<TTypeParticipants | null>(null);
  const [comment, setComment] = useState("");
  const [isVisibleComment, setisVisibleComment] = useState(false);
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);

  useEffect(() => {
    if (!isSerieOrException) {
      setTypeAction("ALL_SERIES");
    }
  }, [isSerieOrException]);

  const onPressHandler = async (arg?: TTypeParticipants, type?: TTypeOfAction) => {
    if (currentOption === null && !arg) return;
    setNotificationVisible(false);
    setIsVisibleOptions(false);

    await onPress(arg ?? currentOption, comment, type);
    setComment("");
    isSerieOrException && setTypeAction("ALL_SERIES");
  };

  const onPressOption = (option: AttendanceStatuses) => {
    if (option === currentStatus) return;

    setCurrentOption(option);
    if (isSerieOrException) {
      setNotificationVisible(true);
    } else if (option === "ATTENDER") {
      onPressHandler(option);
    } else {
      setisVisibleComment(true);
    }
  };

  const handleNotifyType = (type: TTypeOfAction) => {
    setTypeAction(type);
    setNotificationVisible(false);
    if (currentOption === "ATTENDER") {
      onPressHandler("ATTENDER", type);
    } else {
      setisVisibleComment(true);
    }
  };

  useEffect(() => {
    isStatusSet && setIsVisibleOptions(false);
  }, [isStatusSet]);

  return (
    <ContentWrap>
      <LineHorizontal />
      <BilletAttendance onClick={() => setIsVisibleOptions(true)}>
        <StartCenterWrapper>{getButtonContentByStatus(currentStatus)}</StartCenterWrapper>
        <StartCenterWrapper>
          <ArrowSvg type="right" fill={Colors.LIGHT.placeholder.main} width={26} height={26} />
        </StartCenterWrapper>
      </BilletAttendance>
      {!!participantStatusComment && (
        <TextComments size={14} weight="400" color={Colors.LIGHT.grey500}>
          {participantStatusComment}
        </TextComments>
      )}

      <ModalUI isVisible={isVisibleOptions} className="bottomModal" bg={Colors.LIGHT.white} onClose={() => undefined}>
        <InnerContainer>
          <RoundButton onClick={() => setIsVisibleOptions(false)}>
            <SvgClose />
          </RoundButton>

          <RenderWithCondition condition={!typeAction}>
            <TopBlock>
              <TextHeader weight="400" size={16} lineHeight={24} align="center" marginBottom={20}>
                <FormattedMessage id="wantChangeStatusMeeting" />
              </TextHeader>
              <LineHorizontal />
              {mapTitles.map((text, index) => (
                <TabText onClick={() => setTypeAction(index === 0 ? "SINGLE_MEETING" : "ALL_SERIES")}>
                  <TextHeader weight="400" size={18} lineHeight={24} color={Colors.LIGHT.text.accent} align="center">
                    <FormattedMessage id={text} />
                  </TextHeader>

                  <LineHorizontal />
                </TabText>
              ))}
            </TopBlock>
          </RenderWithCondition>

          <RenderWithCondition condition={typeAction}>
            <BottomBlock>
              {options.map((option) => (
                <>
                  <StatusButton onClick={() => onPressOption(option)}>{getButtonContentByStatus(option)}</StatusButton>
                  <RenderWithCondition condition={option === AttendanceStatuses.MAYBE_ATTENDER}>
                    <TextBottom weight="400" size={14} lineHeight={22} color={Colors.LIGHT.placeholder.main}>
                      <FormattedMessage id="setMaybeAttenderStatusMeeting" />
                    </TextBottom>
                  </RenderWithCondition>
                  <LineHorizontal />
                </>
              ))}
            </BottomBlock>
          </RenderWithCondition>
        </InnerContainer>
        <ModalRenderWithCondition condition={isNotificationVisible}>
          <NotificationConfirm
            phraseId={"wantChangeStatusMeeting"}
            phraseOkId="intoAllMeetingInSeries"
            phraseCancelId="onlyIntoThisMeeting"
            onOk={() => handleNotifyType("ALL_SERIES")}
            onCancel={() => handleNotifyType("SINGLE_MEETING")}
            isOpen={isNotificationVisible}
            onClose={() => setNotificationVisible(false)}
          />
        </ModalRenderWithCondition>

        <ModalComment
          value={comment}
          setValue={setComment}
          onClose={() => setisVisibleComment(false)}
          isVisible={isVisibleComment}
          onSend={onPressHandler}
        />
      </ModalUI>
    </ContentWrap>
  );
};
