import React, { FC } from "react";

import styled from "styled-components";

import { Colors } from "@theme/colors";

import { IAttendanceOptionsProps, AttendanceStatuses, IStatusButtonProps } from "../types";

import { getButtonContentByStatus } from "./ButtonContent";

const StatusButton: FC<IStatusButtonProps> = ({ status, containerStyles, onPress }) => (
  <ButtonItem onClick={() => onPress(status)}>{getButtonContentByStatus(status)}</ButtonItem>
);

export const AttendanceOptions: FC<IAttendanceOptionsProps> = ({ onPress }) => (
  <>
    <OptionRow>
      <StatusButton
        status={AttendanceStatuses.ATTENDER}
        onPress={() => {
          onPress(AttendanceStatuses.ATTENDER);
        }}
      />
      <StatusButton
        status={AttendanceStatuses.NON_ATTENDER}
        onPress={() => {
          onPress(AttendanceStatuses.NON_ATTENDER);
        }}
      />
    </OptionRow>
    <OptionRow>
      <StatusButton
        status={AttendanceStatuses.MAYBE_ATTENDER}
        onPress={() => {
          onPress(AttendanceStatuses.MAYBE_ATTENDER);
        }}
      />
    </OptionRow>
  </>
);

const ButtonItem = styled.div`
  display: flex;
  background-color: ${Colors.LIGHT.lighGrey};
  height: 44px;
  padding: 0 16px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  width: 100%;
`;
const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;
