import React, { FC, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEmpty, isEqual } from "lodash";
import { useIntl } from "react-intl";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { UserAvatar } from "@components/shared/userAvatar/UserAvatar";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { ParticipantsList } from "@screens/day/components/timetable/main/modal/components/participants/participantsList/ParticipantsList";
import { IExternalUser } from "@services/meet.service";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { Billet, HeaderModal, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { showToast } from "@utils/toast";
import { toFormatISO } from "@utils/toFormatTime";
import { isInnerMeetParticipant } from "@utils/userTypeChecks";

import { IGroup, IUnitedGroupItem } from "./Groups";

interface IProps {
  onClose: () => void;
  group?: Partial<IUnitedGroupItem>;
  onRemove?: (id: string) => void;
  onSave: (arg: Partial<IGroup>) => void;
  forParticipants?: boolean;
  joinData?: Partial<IGroup>;
}

export const ModalGroup: FC<IProps> = ({ onClose, group, onRemove, onSave, forParticipants, joinData }) => {
  const [data, setData] = useState<Partial<IGroup>>({ name: "", userIds: [], externalUserIds: [], ...joinData });
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);

  useEffect(() => {
    if (group) {
      setData(group);
    }
  }, [group]);

  const handleData = (name: keyof IGroup, value: string | IMeetParticipants) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAddUser = (users: (IStateUser | IExternalUser)[]) => {
    if (!users) return;

    const externalUsers = [];
    const innerUsers: IMeetParticipants[] = [];

    for (const user of users) {
      const isInner = isInnerMeetParticipant(user);

      if (isInner) {
        innerUsers.push({
          user: { ...user },
          userId: user?.user?.id ?? user.id,
          isExternalUser: false,
          meetingStatus: null,
        } as unknown as IMeetParticipants);
      } else {
        externalUsers.push(user as IExternalUser);
      }
    }

    setData({
      ...data,
      userIds: innerUsers,
      externalUserIds: externalUsers as IExternalUser[],
    });
  };

  const handleRemove = () => {
    if (!group) return;

    onRemove(group?.id);
  };

  const handleRemoveUser = (userId: string) => {
    setData((prev) => ({
      ...prev,
      userIds: (prev.userIds ?? []).filter((i) => i.userId !== userId && i.id !== userId),
      externalUserIds: (prev.externalUserIds ?? []).filter((i) => i.id !== userId),
    }));
  };

  const handleSave = () => {
    if (!data.name.trim()) {
      return showToast("nameOfTheGroup");
    }
    onSave(data);
  };

  const isEdit = useMemo(() => (group ? !isEqual(data, group) : true), [data, group]);

  const renderItem = ({ item: user, handleRightClick }) => {
    const userItem = (user.user ? user.user : user) as IStateUser;
    const isInner =
      Object.prototype.hasOwnProperty.call(userItem, "avatarFileId") || Object.prototype.hasOwnProperty.call(userItem, "workPosition");
    const isNotFired = userItem?.active === true || userItem?.active === undefined;
    const activePosition = isInner ? userItem?.workPosition ?? "" : user?.email;
    const currentPosition = isNotFired ? activePosition : "Не активен";

    return (
      <button key={user.userId} style={styles.item} onContextMenu={(e) => handleRightClick(e, user.id)}>
        <div style={{ position: "relative", alignSelf: "flex-start" }}>
          <RenderWithCondition condition={isInner}>
            <>
              <RenderWithCondition condition={!isNotFired}>
                <LetterAvatar
                  style={{ backgroundColor: Colors.DARK.asphalt }}
                  userName={userItem?.lastName}
                  lastName={userItem?.firstName}
                  colorText={Colors.LIGHT.white}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={isNotFired}>
                <UserAvatar
                  avatarFileId={userItem?.avatarFileId ?? null}
                  style={{ width: 45, height: 45, borderRadius: 45, marginRight: 8 }}
                />
              </RenderWithCondition>
            </>
          </RenderWithCondition>

          <RenderWithCondition condition={!isInner}>
            <LetterAvatar
              userName={user?.firstName && user?.firstName[0]}
              lastName={user?.lastName && user?.lastName[0]}
              style={{ backgroundColor: Colors.DARK.asphalt }}
              colorText={Colors.LIGHT.white}
            />
          </RenderWithCondition>
        </div>
        <div style={{ width: "80%" }}>
          <TextFont
            type="bold"
            size={16}
            weight="700"
            style={{ ...commonStyles.oneOfLines, ...((user?.deleted || !isNotFired) && styles.strikeThroughText) }}
          >
            {!isInner ? `${user?.firstName} ${user?.lastName}` : `${userItem?.lastName ?? ""} ${userItem?.firstName ?? ""}`}
          </TextFont>
          <TextFont size={14} color={Colors.LIGHT.placeholder.main} style={commonStyles.oneOfLines}>
            {currentPosition}
          </TextFont>
        </div>
        <button onClick={() => handleRemoveUser(user.id ?? user.userId)} disabled={!!group?.type}>
          <RenderWithCondition condition={!group?.type}>
            <SvgRemove fill={Colors.LIGHT.background.grey} />
          </RenderWithCondition>
        </button>
      </button>
    );
  };

  const textTitle = useMemo(() => {
    if (!group) return "newGroup";
    return group?.type ? " " : "editGroup";
  }, [group]);

  return (
    <ModalUI isVisible onClose={onClose}>
      <HeaderModal
        title={textTitle}
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        isEdit={!!isEdit}
        rightSide={{ onPress: handleSave }}
      />

      <section style={styles.container}>
        <div style={styles.wrapperBlock}>
          <TextFont size={18} weight="700" type="bold">
            Название группы
          </TextFont>
          <input
            value={data.name ?? ""}
            onChange={(e) => handleData("name", e.target.value)}
            style={styles.searchInput}
            placeholder="Название группы"
            maxLength={100}
            disabled={!!group?.type}
          />
        </div>

        <div>
          <div style={styles.participants}>
            <TextFont size={18} weight="700" type="bold">
              {useIntl().formatMessage({ id: "groupParticipant" })}
            </TextFont>
            <RenderWithCondition condition={!group?.type}>
              <button onClick={() => setIsVisible(true)}>
                <SvgPlus width={16} height={16} fill={Colors.LIGHT.background.grey} />
              </button>
            </RenderWithCondition>
          </div>

          {!isEmpty(data.userIds) || !isEmpty(data.externalUserIds) || !isEmpty(group?.participants) ? (
            <SwipeList
              data={[...(data.userIds ?? []), ...(data.externalUserIds ?? []), ...(group?.participants ?? [])]}
              element={renderItem}
              buttons={() => null}
            />
          ) : (
            <Billet
              textId="addParticipants"
              additionalText="+ "
              onPress={() => setIsVisible(true)}
              styleText={{ color: Colors.LIGHT.text.accent, fontWeight: "400" }}
            />
          )}
        </div>

        {group && !forParticipants && !group?.type && (
          <button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => setIsVisibleDelete(true)}>
            <SvgDelete />
            <TextFont size={18} color={Colors.LIGHT.red}>
              Удалить группу
            </TextFont>
          </button>
        )}
      </section>

      <ModalRenderWithCondition condition={isVisible}>
        <ParticipantsList
          onClose={() => setIsVisible(false)}
          meet={
            {
              ...data,
              participants: data.userIds ?? [],
              externalUsers: data.externalUserIds ?? [],
              startTime: toFormatISO(dayjs().hour(0)),
              endTime: toFormatISO(dayjs().hour(23)),
            } as unknown as IMeet
          }
          onSave={handleAddUser}
          isSelectFromMeeting={false}
          isSelectGroup={false}
          isShowStatusBorder={false}
          isMeet={false}
          handleData={handleData}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDelete}>
        <NotificationConfirm
          isOpen
          phraseId="deleteGroup"
          onOk={handleRemove}
          onCancel={() => setIsVisibleDelete(false)}
          phraseCancelId="cancelClose"
          phraseOkId="yes"
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    padding: "16px 12px",
    gap: 30,
    "overflow-y": "auto",
    height: "90%",
  },
  participants: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapperBlock: {
    display: "flex",
    "flex-direction": "column",
    gap: 8,
  },
  searchInput: {
    borderRadius: 10,
    backgroundColor: "white",
    padding: 16,
    color: "black",
    width: "92%",
    fontSize: 18,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    backgroundColor: Colors.LIGHT.white,
    borderBottom: `1px solid ${Colors.LIGHT.lighGrey}`,
    width: "100%",
  },
  wrapperHiddenBtn: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  hiddenBtn: {
    display: "flex",
    "flex-direction": "column",
    gap: 6,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: 65,
    backgroundColor: Colors.LIGHT.red,
  },
  strikeThroughText: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
};
