import React, { FC, memo, useState } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";
import { SelectedUsersList } from "@shared/selectedUserList/SelectedUsersList";
import { Colors } from "@theme/colors";
import { TextFont, Checkbox } from "@ui";

interface IProps {
  meet: IMeet;
  titleTextId: string;
  isAllParticipantsEditable: boolean;
  isSomeParticipantsEditable: boolean;
  isAllParticipantsChecked: boolean;
  isSomeParticipantsChecked: boolean;
  selectedUsers: IStateUser[];
  unavailableUsersIds?: string[];
  handleAddUser: (users: (IStateUser | IExternalUser)[]) => void;
  handleRemoveUser: (id: string, isExternalUser: boolean) => void;
  handleAllParticipantsCheckbox: () => void;
  handleSomeParticipantsCheckbox: () => void;
}

export const OptionsSection: FC<IProps> = memo(function OptionsSection({
  meet,
  titleTextId,
  isAllParticipantsEditable,
  isSomeParticipantsEditable,
  isAllParticipantsChecked,
  isSomeParticipantsChecked,
  selectedUsers = [],
  unavailableUsersIds = [],
  handleAddUser,
  handleRemoveUser,
  handleAllParticipantsCheckbox,
  handleSomeParticipantsCheckbox,
}: IProps) {
  const [isUserPickerVisible, setIsUserPickerVisible] = useState(false);

  return (
    <ContentWrap>
      <TextFont size={16} weight="700" type="bold">
        <FormattedMessage id={titleTextId} />
      </TextFont>
      <Divider />
      <LineOption>
        <TextFont size={16} weight="700" type="bold">
          <FormattedMessage id="allParticipants" defaultMessage={"Все участники"} />
        </TextFont>
        <CheckboxCustom
          onChange={() => handleAllParticipantsCheckbox()}
          isChecked={isAllParticipantsChecked}
          disabled={!isAllParticipantsEditable}
        />
      </LineOption>
      <Divider />
      <BlockOption>
        <LineOption marginBottom={12}>
          <TextFont size={16} weight="700" type="bold">
            <FormattedMessage id="someParticipants" defaultMessage={"Только некоторые"} />
          </TextFont>
          <CheckboxCustom
            onChange={() => handleSomeParticipantsCheckbox()}
            isChecked={isSomeParticipantsChecked}
            disabled={!isSomeParticipantsEditable}
          />
        </LineOption>

        <RenderWithCondition condition={isSomeParticipantsEditable && isSomeParticipantsChecked}>
          <>
            <SelectedUsersList
              meet={meet}
              isSave={false}
              handleDeleteItem={handleRemoveUser}
              handleAddUser={handleAddUser}
              isVisibleUserPicker={isUserPickerVisible}
              onCloseUserList={() => setIsUserPickerVisible(false)}
              disableStandardAddButtons
              hideHeader
              innerUsers={selectedUsers}
              unavailableUsersIds={unavailableUsersIds}
            />

            <ButtonAdd onClick={() => setIsUserPickerVisible(true)}>
              <TextFont size={16} color={Colors.LIGHT.text.accent}>
                <FormattedMessage id={selectedUsers.length ? "changeList" : "addParticipants"} />
              </TextFont>
            </ButtonAdd>
          </>
        </RenderWithCondition>
      </BlockOption>
    </ContentWrap>
  );
});

const ContentWrap = styled.div`
  background-color: ${Colors.LIGHT.white};
  padding: 12px;
  border-radius: 10px;
`;
const Divider = styled.div`
  height: 1px;
  flex: 0.8;
  margin: 12px 0;
  background-color: ${Colors.LIGHT.lighGrey};
`;
const LineOption = styled.div<{ marginBottom?: number }>`
  display: flex;
  justify-content: space-between;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
`;
const BlockOption = styled.div`
  display: flex;
  flex-direction: column;
`;
const CheckboxCustom = styled(Checkbox)`
  margin-right: 8px;
  margin-left: 5px;
`;
const ButtonAdd = styled.button`
  align-self: center;
  padding: 13px 0;
`;
