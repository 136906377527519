import { createAsyncThunk } from "@reduxjs/toolkit";

import { userSettings } from "@services/userSettings.service";
import { getError } from "@store/getError";

export const getPersonalSettings: any = createAsyncThunk("userSettings/getPersonalSettings", async (thunkAPI) => {
  try {
    const response = await userSettings.getPersonalSettings();
    return { response };
  } catch (error: unknown) {
    return getError(error, thunkAPI);
  }
});

export const updatePersonalSettings: any = createAsyncThunk(
  "userSettings/updatePersonalSettings",
  async (data: { id: string; body: Record<string, string> }, thunkAPI) => {
    try {
      const response = await userSettings.updatePersonalSettings(data.id, data.body);
      return { response };
    } catch (error: any) {
      return getError(error, thunkAPI);
    }
  },
);
