import React from "react";

import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SvgReturnToWork } from "@assets/return_work.svg";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

export const HeaderAnalysis = () => {
  const navigate = useNavigate();

  return (
    <ContentWrap>
      <ButtonBack onClick={() => navigate(-1)}>
        <SvgReturnToWork fill={Colors.LIGHT.background.green} />
      </ButtonBack>
      <TextFont weight="700" align="center">
        <FormattedMessage id="dayAnalysis" />
      </TextFont>
      <div></div>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0 1´0px;
`;
const ButtonBack = styled.button`
  justify-self: flex-start;
`;
