import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { InterfaceBusinessTask, InterfaceUser } from "@interfaces";
import { DelegatedList } from "@shared/delegatedList/DelegatedList";
import { setFilter } from "@store/businessTask";
import { Spacer, boxCenterCss } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, TextFont } from "@ui";

interface IProps {
  isVisible: boolean;
  close: () => void;
  initUsers: InterfaceUser.IStateUser[];
  title: "delegatedTo" | "delegatedFrom";
}

export const DelegatedTab: FC<IProps> = ({ isVisible, close, initUsers, title }) => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState(initUsers);

  useEffect(() => {
    dispatch(setFilter({ [`${title}Type`]: null }));
  }, []);

  const addUser = useCallback(
    (user: InterfaceUser.IStateUser) => {
      if (!users.find((i) => i.id === user.id)) {
        setUsers([...users, user]);
      }
    },
    [users],
  );

  const removeUser = (id: string) => {
    setUsers([...users].filter((i) => i.id !== id));
  };

  const onSave = useCallback(() => {
    dispatch(setFilter({ [title]: users.map((i) => i.id), [`${title}Users`]: users }));
    close();
  }, [users]);

  const handleButton = (type: InterfaceBusinessTask.TDelegatedFilter) => {
    dispatch(setFilter({ [`${title}Type`]: type, [title]: [], [`${title}Users`]: [] }));
    close();
  };

  const isEdit = useMemo(() => !isEqual(users, initUsers), [users, initUsers]);

  return (
    <ModalUI isVisible={isVisible} onClose={close}>
      <HeaderModal
        title="userChoice"
        leftSide={{ onPressClose: close, isHideCancel: true }}
        isEdit={isEdit}
        rightSide={{ title: "apply", onPress: onSave }}
      />

      <Content>
        <BlockButtons>
          <ButtonItem onClick={() => handleButton(title === "delegatedFrom" ? "DELEGATED_FROM_ME" : "DELEGATED_TO_ME")}>
            <TextFont>Делегировано</TextFont>
            <TextFont type="bold" weight="700">
              {title === "delegatedFrom" ? " ОТ " : " НА "}
            </TextFont>
            <TextFont>
              <FormattedMessage id={"delegatedMe"} />
            </TextFont>
          </ButtonItem>
          <ButtonItem onClick={() => handleButton(title === "delegatedFrom" ? "DELEGATED_TO_ME" : "DELEGATED_FROM_ME")}>
            <TextFont>Делегировано</TextFont>
            <TextFont type="bold" weight="700">
              {title === "delegatedFrom" ? " ОТ " : " НА "}
            </TextFont>
            <TextFont>
              <FormattedMessage id={"delegatedAny"} />
            </TextFont>
          </ButtonItem>
        </BlockButtons>

        <RenderWithCondition condition={!!users.length}>
          <BlockUsers>
            <TextFont>
              <FormattedMessage id={title} />
            </TextFont>
            <Spacer px={10} />

            <LineUsers>
              {users.map((user) => (
                <ButtonItemUser onClick={() => removeUser(user.id)} key={user.id}>
                  <TextFont>{`${user.lastName} ${user.firstName}`}</TextFont>
                  <SvgRemove fill={Colors.LIGHT.background.grey} width={9} height={9} />
                </ButtonItemUser>
              ))}
            </LineUsers>
          </BlockUsers>
        </RenderWithCondition>
      </Content>

      <DelegatedList close={close} onSave={addUser} isModal={true} isNeedCloseAfterSave={false} />
    </ModalUI>
  );
};

const Content = styled.div`
  padding: 14px 19px;
`;
const BlockButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;
const BlockUsers = styled.div`
  margin-top: 30px;
`;
const LineUsers = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const ButtonItemUser = styled.button`
  ${boxCenterCss};
  border-radius: 45px;
  padding: 3px 9px;
  margin-right: 6px;
  background-color: ${Colors.LIGHT.lighGrey};
  margin-bottom: 6px;
  gap: 5px;
`;
const ButtonItem = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 3px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid ${Colors.LIGHT.green};
  background-color: ${Colors.LIGHT.lighGrey};
  margin-right: 5px;
`;
