import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { orderBy } from "lodash";
import styled from "styled-components";

import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants, IMeetingRoom } from "@interfaces/meet.interface";
import { useGetOccupancyRoomsQuery } from "@services/meetApi";
import { Colors } from "@theme/colors";
import { ArrowSvg, TextFont } from "@ui";
import { calendar, toFormatISO } from "@utils";

import { HOURTOSCROLL, arrHours } from "../../../../../constants";
import { BlockTime } from "../../../activityParticipants/components/Activity/components/BlockTime";
import { Row } from "../../../activityParticipants/components/Activity/components/Row";
import { RowParticipant } from "../../../activityParticipants/components/Activity/components/RowParticipant";
import { WIDTH_BLOCK } from "../../../activityParticipants/components/Activity/constants";

interface IProps {
  time: { startTime: string; endTime: string };
  currentDate: Dayjs;
  setCurrentDate: (arg: Dayjs) => void;
  participants: IMeetParticipants[];
  participantLight: string;
  meetingRooms?: IMeetingRoom[];
  handleHourSlotPress: (hour: number) => void;
}

export const OccupancyMeetingRooms: FC<IProps> = ({
  currentDate,
  setCurrentDate,
  participants,
  participantLight,
  time,
  meetingRooms,
  handleHourSlotPress,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<Partial<IEventsOfDay> | null>(null);

  const refView = useRef(null);
  const { data: occupancyRooms } = useGetOccupancyRoomsQuery({
    startTime: toFormatISO(currentDate.hour(0)),
    endTime: toFormatISO(currentDate.hour(23)),
    externalIds: meetingRooms.map((item) => item.externalId),
  });

  useEffect(() => {
    refView?.current?.scrollTo(HOURTOSCROLL * WIDTH_BLOCK, 0);
  }, [refView]);

  const isLightColumnNumber = useMemo(
    () => participants?.findIndex((i) => i.userId === participantLight),
    [participantLight, participants],
  );

  const sortedExtendedOccupancyRooms = useMemo(() => {
    if (!occupancyRooms) return [];
    const extendedOccupancyRooms = [...occupancyRooms];

    meetingRooms.forEach((room) => {
      if (!occupancyRooms.find((item) => item.externalId === room.externalId)) {
        extendedOccupancyRooms.push({ externalId: room.externalId, meetings: [] });
      }
    });

    return orderBy(extendedOccupancyRooms, ["externalId"], ["asc", "desc"]) ?? [];
  }, [occupancyRooms, meetingRooms]);

  return (
    <StContentDiv>
      <StCalendarDiv>
        <StHeaderDiv>
          <button onClick={() => setCurrentDate(currentDate.subtract(1, "day"))}>
            <ArrowSvg fill={Colors.LIGHT.text.main} type="left" />
          </button>
          <TextFont type="bold" size={16} weight="700">{`${calendar.getWeekDayFull(currentDate.day())}, ${currentDate.format(
            "DD MMMM",
          )}`}</TextFont>
          <button onClick={() => setCurrentDate(currentDate.add(1, "day"))}>
            <ArrowSvg fill={Colors.LIGHT.text.main} type="right" />
          </button>
        </StHeaderDiv>

        <StOccupancyWrapDiv ref={refView}>
          <StOccupancyDiv>
            <StOccupiedDiv>
              {sortedExtendedOccupancyRooms?.map((meetingRoom) => (
                <RowParticipant
                  key={meetingRoom?.externalId}
                  events={meetingRoom?.meetings ?? []}
                  isRooms
                  setSelectedEvent={setSelectedEvent}
                  selectedEvent={selectedEvent}
                />
              ))}
            </StOccupiedDiv>
            <StRowsDiv>
              {arrHours?.map((hour) => (
                <StButtonRowDiv key={hour} isPointer={hour === 24} onClick={() => handleHourSlotPress(hour)}>
                  <Row rows={meetingRooms?.length} isLightColumnNumber={isLightColumnNumber} hour={hour} date={currentDate} time={time} />
                </StButtonRowDiv>
              ))}
            </StRowsDiv>
            <BlockTime time={time} />
          </StOccupancyDiv>
        </StOccupancyWrapDiv>
      </StCalendarDiv>
    </StContentDiv>
  );
};

const StContentDiv = styled.div`
  display: flex;
`;
const StCalendarDiv = styled.div`
  width: 95%;
  background-color: ${Colors.LIGHT.white};
  margin-left: 5px;
`;
const StHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;
const StOccupancyWrapDiv = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 10px;
`;
const StOccupancyDiv = styled.div`
  position: relative;
  width: 100%;
`;
const StOccupiedDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 34.5px;
`;
const StRowsDiv = styled.div`
  display: flex;
`;
const StButtonRowDiv = styled.div<{ isPointer: boolean }>`
  cursor: pointer;
  ${({ isPointer }) =>
    isPointer &&
    `
    cursor: default;
 `}
`;
