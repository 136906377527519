import React, { FC, useCallback } from "react";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReactComponent as SvgClose } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { RoundButton } from "@styles/styles";
import { Colors } from "@theme/colors";
import { TextFont, ModalUI } from "@ui";

interface IProps {
  close: () => void;
  deleteSerie: () => void;
  deleteFromSerie: () => void;
  isEdit?: boolean;
  data: string[];
  classNameButton?: Record<string, string>;
  textTitle?: string;
  colorTextButton?: string;
}

export const ModalDelete: FC<IProps> = ({
  close,
  deleteFromSerie,
  deleteSerie,
  isEdit,
  data,
  classNameButton = {},
  textTitle,
  colorTextButton,
}) => {
  const handlePress = useCallback((key: number) => {
    if (key === 0) {
      deleteFromSerie();
    } else {
      deleteSerie();
    }
    close();
  }, []);

  return (
    <ModalUI isVisible={true} onClose={close}>
      <ContentWrap>
        <RoundButton onClick={close}>
          <SvgClose />
        </RoundButton>
        <RenderWithCondition condition={textTitle}>
          <>
            <TextUp>
              <TextFontTitle size={18} align="center">
                <FormattedMessage id={textTitle} />
              </TextFontTitle>
            </TextUp>
            <Line />
          </>
        </RenderWithCondition>
        <div>
          {data.map((element, index) => (
            <div key={element}>
              <TabButton onClick={() => handlePress(index)}>
                <TextFont size={18} color={colorTextButton ?? ""}>
                  <FormattedMessage id={element} />
                </TextFont>
              </TabButton>
              <Line />
            </div>
          ))}
        </div>
        <RenderWithCondition condition={isEdit}>
          <TextWarning size={18} align="center" color={Colors.LIGHT.placeholder.main}>
            <FormattedMessage id="allSeriesWarning" />
          </TextWarning>
        </RenderWithCondition>
      </ContentWrap>
    </ModalUI>
  );
};

const ContentWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
`;
const TextUp = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;
`;
const Line = styled.div`
  height: 1px;
  background-color: ${Colors.LIGHT.lightGrey200};
  margin: 0 12px;
`;
const TabButton = styled.button`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 0 12px;
  width: 100%;
`;
const TextWarning = styled(TextFont)`
  margin-top: 15px;
  padding-left: 8px;
`;
const TextFontTitle = styled(TextFont)`
  max-width: 300px;
`;
