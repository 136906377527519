import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { resetStore } from "@constants/logic";
import { DIRECTOR_ID } from "@constants/settings";
import { InterfaceUser } from "@interfaces";
import { secureStore } from "@utils/secureStore";

import {
  deleteUser,
  getList,
  getUserInfo,
  updateUser,
  delegatedUsers,
  pagingDelegatedUsers,
  participantedUsers,
  pagingParticipantedUsers,
  viewMessage,
  viewAllMessages,
  getListMessages,
  getManagers,
  deleteAllMessages,
  deleteMessage,
  getUnreadCountMessages,
  pagingMessages,
  getListGroups,
  pagingGroups,
  deleteGroup,
  getListPreGroups,
} from "./api";

export interface IState {
  currentUser: InterfaceUser.IStateUser | null;
  users: InterfaceUser.IStateUser[];
  delegationUsers: InterfaceUser.IDelegationUsers;
  participantsUsers: InterfaceUser.IAvailableUsers;
  messages: { content: InterfaceUser.IMessage[]; totalPages: number };
  groups: { content: InterfaceUser.IGroups[]; totalPages: number };
  preGroups: { content: Partial<IUnitedGroupItem>[]; totalPagesPreGroups: number };
  isLoading: boolean;
  isLoadingMessages: boolean;
  isLoadingGroups: boolean;
  errorMsg: null | string;
  managers: Partial<InterfaceUser.IStateUser>[];
  directorId: string;
  unreadMessages: number;
}

const initialState: IState = {
  currentUser: null,
  users: [],
  delegationUsers: {
    availableUsers: {
      content: [],
      total: 0,
    },
    delegatedTo: null,
  },
  isLoading: false,
  isLoadingMessages: false,
  isLoadingGroups: false,
  errorMsg: null,
  messages: { content: [], totalPages: 1 },
  groups: { content: [], totalPages: 1 },
  preGroups: { content: [], totalPagesPreGroups: 1 },
  participantsUsers: {
    content: [],
    total: 0,
  },
  managers: [],
  directorId: "",
  unreadMessages: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeDirectorId: (state, action: PayloadAction<string>) => {
      secureStore.saveValue(DIRECTOR_ID, action.payload);
      state.directorId = action.payload;
    },
    initDirectorId: (state) => {
      const id = localStorage.getItem(DIRECTOR_ID);
      state.directorId = id ?? "";
    },
    clearMessages: (state) => {
      state.messages = { content: [], totalPages: 1 };
      state.unreadMessages = 0;
    },
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        state.users = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.currentUser = payload?.response?.data;
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.currentUser = null;
        state.isLoading = false;
      })
      .addCase(deleteUser.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getUserInfo.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.currentUser = payload?.response?.data ?? null;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(getListMessages.pending, (state) => {
        state.isLoadingMessages = true;
        state.errorMsg = null;
      })
      .addCase(getListMessages.fulfilled, (state, { payload }) => {
        state.isLoadingMessages = false;
        state.messages = {
          content: payload?.response?.data.content ?? [],
          totalPages: payload?.response?.data.totalPages,
        };
      })
      .addCase(getListMessages.rejected, (state, action) => {
        state.isLoadingMessages = false;
        state.errorMsg = action.payload;
      })

      .addCase(pagingMessages.fulfilled, (state, { payload }) => {
        const currentList = state.messages.content;
        const responseList = payload.response?.data.content;
        const totalPages = payload.response?.data.totalPages;

        state.messages = { content: [...currentList, ...responseList], totalPages };
      })
      .addCase(pagingMessages.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(getListGroups.pending, (state) => {
        state.isLoadingGroups = true;
        state.errorMsg = null;
      })
      .addCase(getListGroups.fulfilled, (state, { payload }) => {
        state.isLoadingGroups = false;

        state.groups = {
          content: payload?.response?.data.content ?? [],
          totalPages: payload?.response?.data.totalPages,
        };
      })
      .addCase(getListGroups.rejected, (state, action) => {
        state.isLoadingGroups = false;
        state.errorMsg = action.payload;
      })

      .addCase(getListPreGroups.pending, (state) => {
        state.isLoadingGroups = true;
        state.errorMsg = null;
      })
      .addCase(getListPreGroups.fulfilled, (state, { payload }) => {
        state.isLoadingGroups = false;

        state.preGroups = {
          content: payload?.response?.data.content ?? [],
          totalPagesPreGroups: payload?.response?.data.totalPages,
        };
      })
      .addCase(getListPreGroups.rejected, (state, action) => {
        state.isLoadingGroups = false;
        state.errorMsg = action.payload;
      })

      .addCase(pagingGroups.fulfilled, (state, { payload }) => {
        const currentList = state.groups.content;
        const responseList = payload.response?.data.content;
        const totalPages = payload.response?.data.totalPages;

        state.groups = { content: [...currentList, ...responseList], totalPages };
      })
      .addCase(pagingGroups.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(deleteGroup.fulfilled, (state, { payload }) => {
        state.groups = {
          content: [...state.groups.content].filter((i) => i.id !== payload.id),
          totalPages: state.groups.totalPages - 1,
        };
      })

      .addCase(viewMessage.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(viewMessage.fulfilled, (state, { payload }) => {
        state.messages.content = state.messages.content.map((i) => {
          if (i.id === payload.id) {
            return { ...i, viewed: true };
          }
          return i;
        });
        state.unreadMessages = state.unreadMessages - 1;
      })
      .addCase(viewMessage.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(viewAllMessages.pending, (state) => {
        state.isLoadingMessages = true;
        state.errorMsg = null;
      })
      .addCase(viewAllMessages.fulfilled, (state) => {
        state.isLoadingMessages = false;
        state.messages.content = state.messages.content.map((i) => ({ ...i, viewed: true }));
        state.unreadMessages = 0;
      })
      .addCase(viewAllMessages.rejected, (state, action) => {
        state.isLoadingMessages = false;
        state.errorMsg = action.payload;
      })

      .addCase(getUnreadCountMessages.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(getUnreadCountMessages.fulfilled, (state, { payload }) => {
        state.unreadMessages = payload.response?.data;
      })
      .addCase(getUnreadCountMessages.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(deleteMessage.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(deleteMessage.fulfilled, (state, { payload }) => {
        state.messages.content = [...state.messages.content].filter((i) => i.id !== payload.id);

        if (!payload.viewed) {
          const updatedCount = state.unreadMessages - 1;

          state.unreadMessages = updatedCount >= 0 ? updatedCount : 0;
        }
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(deleteAllMessages.pending, (state) => {
        state.isLoadingMessages = true;
        state.errorMsg = null;
      })
      .addCase(deleteAllMessages.fulfilled, (state) => {
        state.isLoadingMessages = false;
        state.messages = { content: [], totalPages: 0 };
        state.unreadMessages = 0;
      })
      .addCase(deleteAllMessages.rejected, (state, action) => {
        state.isLoadingMessages = false;
        state.errorMsg = action.payload;
      })

      .addCase(getManagers.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(getManagers.fulfilled, (state, { payload }) => {
        state.managers = payload.response.data;
      })
      .addCase(getManagers.rejected, (state, action) => {
        state.errorMsg = action.payload;
      })

      .addCase(delegatedUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(delegatedUsers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const data = payload.response?.data;
        const content = data?.availableUsers?.content ?? data?.content;
        const total = data?.availableUsers?.total ?? data?.total;
        const delegatedTo = data?.delegatedTo ?? null;

        state.delegationUsers = { availableUsers: { content, total }, delegatedTo };
      })
      .addCase(delegatedUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(pagingDelegatedUsers.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(pagingDelegatedUsers.fulfilled, (state, { payload }) => {
        const currentList = state.delegationUsers.availableUsers.content;
        const responseList = payload.response?.data.availableUsers.content;

        state.delegationUsers.availableUsers.content = [...currentList, ...responseList];
      })
      .addCase(pagingDelegatedUsers.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      })

      .addCase(participantedUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(participantedUsers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const data = payload.response?.data;
        const content = data?.content;
        const total = data?.total;

        state.participantsUsers = { content, total };
      })
      .addCase(participantedUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(pagingParticipantedUsers.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(pagingParticipantedUsers.fulfilled, (state, { payload }) => {
        const currentList = state.participantsUsers.content;
        const responseList = payload.response?.data.content;

        state.participantsUsers.content = [...currentList, ...responseList];
        state.participantsUsers.total = payload.response?.data?.total ?? state.participantsUsers.total;
      })
      .addCase(pagingParticipantedUsers.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      });
  },
});

export const { clearUser, changeDirectorId, initDirectorId, clearMessages } = userSlice.actions;

export default userSlice.reducer;
