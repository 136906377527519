import React from "react";

import styled, { css } from "styled-components";

import { ReactComponent as SvgClose } from "@assets/close-bold.svg";
import { ReactComponent as SvgPause } from "@assets/pause_bold.svg";
import { ReactComponent as SvgSuccess } from "@assets/success_task.svg";
import { TTypeStatusTask, TTypePriorityTask } from "@interfaces/businessGoals.interface";
import { Colors } from "@theme/colors";

export type TIcon = "default" | "dealWith" | "delegate" | "delegateFrom" | "notDealWith";

export const colors = (isCompleted?: boolean): Record<TIcon, Record<"bg" | "text", string>> => ({
  default: { bg: isCompleted ? Colors.LIGHT.disabled : Colors.LIGHT.lighGrey, text: Colors.LIGHT.placeholder.main },
  dealWith: { bg: isCompleted ? Colors.LIGHT.background.green200 : Colors.LIGHT.background.green300, text: Colors.LIGHT.white },
  delegate: { bg: isCompleted ? Colors.LIGHT.yellow300 : Colors.LIGHT.yellow400, text: Colors.LIGHT.white },
  delegateFrom: { bg: isCompleted ? Colors.LIGHT.purple100 : Colors.LIGHT.purple100, text: Colors.LIGHT.white },
  notDealWith: { bg: Colors.LIGHT.lightRed, text: Colors.LIGHT.red },
});

export const ExtraIcon = ({ type }: { type: TTypeStatusTask }) => {
  switch (type) {
    case "COMPLETED":
      return <IconSuccess />;
    case "CLOSED":
      return <IconClose />;
    case "PAUSED":
      return <IconPause />;
    default:
      return null;
  }
};

export const objXml: Record<TTypePriorityTask, (bg: string) => JSX.Element> = {
  IMPORTANT: (bg: string) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2243 16.2215H9.17188L9.13951 12.8878H14.221C15.1164 12.8878 15.8501 12.7746 16.4219 12.548C16.9937 12.3106 17.4198 11.9708 17.7003 11.5285C17.9916 11.0753 18.1373 10.5251 18.1373 9.87779C18.1373 9.14416 17.997 8.55078 17.7165 8.09766C17.4468 7.64453 17.0206 7.31548 16.4381 7.11049C15.8663 6.90551 15.1272 6.80301 14.221 6.80301H10.8549V26.5625H6V3H14.221C15.5911 3 16.8157 3.12946 17.8945 3.38839C18.9842 3.64732 19.9066 4.04111 20.6618 4.56975C21.417 5.0984 21.9942 5.7673 22.3934 6.57645C22.7926 7.37481 22.9922 8.32422 22.9922 9.42466C22.9922 10.3956 22.771 11.2911 22.3287 12.111C21.8971 12.931 21.2121 13.5999 20.2734 14.1177C19.3456 14.6356 18.1319 14.9215 16.6323 14.9754L15.2243 16.2215ZM15.014 26.5625H7.84487L9.73828 22.7757H15.014C15.8663 22.7757 16.5621 22.6408 17.1016 22.3711C17.641 22.0906 18.0402 21.713 18.2991 21.2383C18.558 20.7636 18.6875 20.2188 18.6875 19.6038C18.6875 18.9133 18.5688 18.3145 18.3315 17.8075C18.1049 17.3004 17.7381 16.912 17.231 16.6423C16.724 16.3618 16.0551 16.2215 15.2243 16.2215H10.5474L10.5798 12.8878H16.4057L17.5223 14.1987C18.9572 14.1771 20.1116 14.4306 20.9855 14.9593C21.8702 15.4771 22.5121 16.1514 22.9113 16.9821C23.3212 17.8129 23.5262 18.7029 23.5262 19.6523C23.5262 21.1628 23.1972 22.4358 22.5391 23.4715C21.881 24.4965 20.9154 25.2679 19.6423 25.7857C18.38 26.3036 16.8372 26.5625 15.014 26.5625Z"
        fill={`${bg}`}
      />
    </svg>
  ),
  URGENT: (bg: string) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3705 19.0859H24.2093C24.1122 20.6719 23.6752 22.0798 22.8984 23.3097C22.1324 24.5396 21.059 25.4998 19.678 26.1903C18.3078 26.8808 16.6572 27.226 14.726 27.226C13.2156 27.226 11.8616 26.9671 10.6641 26.4492C9.46652 25.9206 8.44159 25.1654 7.58929 24.1836C6.74777 23.2018 6.10584 22.0151 5.6635 20.6233C5.22117 19.2316 5 17.6726 5 15.9464V14.3119C5 12.5858 5.22656 11.0268 5.67969 9.63504C6.1436 8.23251 6.80171 7.04036 7.65402 6.05859C8.51711 5.07682 9.54743 4.32161 10.745 3.79297C11.9425 3.26432 13.2803 3 14.7584 3C16.7219 3 18.378 3.35603 19.7266 4.06808C21.0859 4.78013 22.1378 5.7619 22.8823 7.01339C23.6375 8.26488 24.0906 9.68899 24.2416 11.2857H19.3867C19.3328 10.3363 19.144 9.53255 18.8203 8.87444C18.4967 8.20554 18.0058 7.70387 17.3477 7.36942C16.7003 7.02418 15.8372 6.85156 14.7584 6.85156C13.9492 6.85156 13.2426 7.0026 12.6384 7.30469C12.0342 7.60677 11.5272 8.06529 11.1172 8.68025C10.7072 9.2952 10.3997 10.072 10.1948 11.0106C10.0006 11.9384 9.90346 13.0281 9.90346 14.2796V15.9464C9.90346 17.1656 9.99516 18.239 10.1786 19.1669C10.362 20.0839 10.6425 20.8607 11.0201 21.4972C11.4085 22.123 11.9048 22.5977 12.5089 22.9213C13.1239 23.2342 13.8629 23.3906 14.726 23.3906C15.7401 23.3906 16.5763 23.2288 17.2344 22.9051C17.8925 22.5815 18.3942 22.1014 18.7394 21.4648C19.0954 20.8283 19.3058 20.0353 19.3705 19.0859Z"
        fill={`${bg}`}
      />
    </svg>
  ),
  CONTACT: (bg: string) => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.85491 3V26.5625H5V3H9.85491ZM23.74 3L14.7098 16.8365H8.77065L8.15569 12.5156H12.1205L17.8493 3H23.74ZM18.7394 26.5625L12.1367 16.0273L15.5028 12.5642L24.5006 26.5625H18.7394Z"
        fill={`${bg}`}
      />
    </svg>
  ),
};

const baseIcon = css`
  top: 8%;
  left: 15%;
  position: absolute;
`;

const IconClose = styled(SvgClose)`
  ${baseIcon}
`;
const IconSuccess = styled(SvgSuccess)`
  ${baseIcon}
`;
const IconPause = styled(SvgPause)`
  ${baseIcon}
  left: 10%;
`;
