import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { selectIsModalOpen } from "@store/configure";
import { selectScreenDay } from "@store/screenDay/selectors";
import { setActiveTabId } from "@store/screenDay/slice";
import { Colors } from "@theme/colors";

import { Tab } from "./Tab";
import { ITab, TabIds } from "./types";

const configTabs: Array<Omit<ITab, "onPress">> = [
  { id: "tasks", title: "Задачи" },
  { id: "timetable", title: "Расписание" },
  { id: "notes", title: "Заметки" },
];

const HeaderDecorator = () => (
  <DecorWrap>
    <Decor />
  </DecorWrap>
);

export const Tabs = () => {
  const isModalOpen = useSelector(selectIsModalOpen);
  const { activeTabId } = useSelector(selectScreenDay);
  const dispatch = useDispatch();

  const onPress = useCallback((tabId: TabIds) => {
    dispatch(setActiveTabId(tabId));
  }, []);

  return (
    <>
      {isModalOpen ? (
        <HeaderDecorator />
      ) : (
        <ContentWrap>
          <TabsWrap>
            {configTabs.map((tab) => (
              <Tab key={tab.id} {...tab} isActive={activeTabId === tab.id} onPress={() => onPress(tab.id)} width="33%" />
            ))}
          </TabsWrap>
        </ContentWrap>
      )}
    </>
  );
};

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  height: 50px;
  background-color: ${Colors.LIGHT.background.main};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
const TabsWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 32px;
  width: 100%;
  background-color: ${Colors.LIGHT.white};
  border-radius: 8px;
`;
const DecorWrap = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${Colors.LIGHT.black};
`;
const Decor = styled.div`
  width: 90%;
  height: 130px;
  background-color: ${Colors.LIGHT.background.grey600};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 12.5%;
  align-self: center;
  justify-self: center;
`;
